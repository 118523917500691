import React from 'react'
import { Button } from '@atoms'
import Columns2 from '@components/layouts/Columns2'
import Card from '@layouts/Card'
import CurrentCaptains from './CurrentCaptains'
import RemovedCaptains from './RemovedCaptains'
import { type CurrentCaptainsStoreType } from './stores/currentCaptainsStore'

import { scopedTranslation } from '@utils/I18n'
const t = scopedTranslation('group_edit.manage_captains')

type ManageCurrentCaptainsPropsType = Pick<
  CurrentCaptainsStoreType,
  'captains' | 'removedCaptains' | 'removeCaptain' | 'addCaptains'
> & {
  removeGroupLeaders: () => void
  children?: React.ReactNode
}

export default function ManageCurrentCaptains({
  captains,
  removedCaptains,
  addCaptains,
  removeCaptain,
  removeGroupLeaders,
  children,
}: ManageCurrentCaptainsPropsType) {
  return (
    <div>
      <Columns2>
        <Card dataCompId={'manage-captains_card'} title={t('editor.title')} description={t('editor.description')}>
          <CurrentCaptains captains={captains} removeCaptain={removeCaptain} />
        </Card>
        <Card
          dataCompId={'manage-captains_preview-of-captains-being-managed-card'}
          title={t('preview.title')}
          backgroundFill={true}
        >
          <RemovedCaptains removedCaptains={removedCaptains} addCaptains={addCaptains} />
        </Card>
      </Columns2>

      <div className="tw-flex tw-justify-end tw-w-full tw-pt-4 tw-px-4 tw-gap-2">
        {children}
        <Button
          dataCompId={'manage-captains_form-submit'}
          type="submit"
          size="md"
          trailingIcon="arrow-circle-broken-right"
          onClick={removeGroupLeaders}
        >
          {t('submit')}
        </Button>
      </div>
    </div>
  )
}
