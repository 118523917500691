import gql from 'graphql-tag';

export const editShiftMutation = gql`
  mutation EditShift($input: EditShiftInput!) {
    editShift(input: $input) {
      shift {
        id
        startTime
        endTime
        roleName
        draft
      }
      errors {
        field
        message
        code
      }
    }
  }
`;
