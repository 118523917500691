import React from 'react'
import Link, { type LinkProps } from '@atoms/Link'
import NotificationBubble from '@atoms/NotificationBubble'

type NotificationLinkProps = {
  leadingNotification?: string
  trailingNotification?: string
  children: string
} & Omit<LinkProps, 'leadingIcon' | 'trailingIcon' | 'children' | 'rank' | 'color' | 'size'>

export default function NotificationLink({
  leadingNotification,
  trailingNotification,
  children,
  ...props
}: NotificationLinkProps) {
  const rank = 'primary'
  const color = 'primary'
  const size = 'sm'
  return (
    <Link {...{ rank, color, size, ...props }}>
      {leadingNotification && (
        <NotificationBubble aria-hidden={true} {...{ rank, color, size }}>
          {leadingNotification}
        </NotificationBubble>
      )}
      {children}
      {trailingNotification && (
        <NotificationBubble aria-hidden={true} {...{ rank, color, size }}>
          {trailingNotification}
        </NotificationBubble>
      )}
    </Link>
  )
}
