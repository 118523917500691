import React from 'react'

import { scopedTranslation } from '@utils/I18n'
import { formatTime } from '@utils/formatTime'

import { EditShiftDialog } from '../edit_shifts/EditShiftDialog'
import { DeleteShiftDialog } from '../delete_shifts/DeleteShiftDialog'
import AssignShiftDialog from '../assign_shifts/AssignShiftDialog'
import UnassignShiftDialog from '../unassign_shifts/UnassignShiftDialog'

import type { Shift } from '@frontend/graphql/types.generated'

const t = scopedTranslation('attributes.shift')
const tAssignShift = scopedTranslation('components.assign_shift')

type ShiftCardProps = {
  shift: Shift
  onShiftUpdate: () => void
}

const AssignShiftRow = ({ shift, onShiftUpdate }) => {
  const isShiftAssigned: boolean = Boolean(shift.supporter?.fullName)

  const assignButton = <AssignShiftDialog shift={shift} onShiftUpdate={onShiftUpdate} />
  const unassignButton = <UnassignShiftDialog shift={shift} supporter={shift.supporter} onShiftUpdate={onShiftUpdate} />

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-space-x-4">
      <p className="tw-flex-1 tw-min-h-[2.5rem] tw-flex tw-items-center">
        {isShiftAssigned && shift.supporter ? shift.supporter.fullName : tAssignShift('unassigned')}
      </p>
      <div className="tw-flex tw-items-center tw-min-h-[2.5rem]">{isShiftAssigned ? unassignButton : assignButton}</div>
    </div>
  )
}

export const ShiftCard: React.FC<ShiftCardProps> = ({ shift, onShiftUpdate }) => {
  const shiftStartTime = formatTime(shift.startTime)
  const shiftEndTime = formatTime(shift.endTime)

  return (
    <div data-testid="shift-card" className="tw-space-y-4 tw-text-black">
      <div className="tw-flex tw-items-center tw-justify-between">
        <div>
          <p>
            {shiftStartTime} - {shiftEndTime}
          </p>
          <p>
            {t('role')}: {shift.roleName}
          </p>
        </div>
        <div className="tw-flex">
          <EditShiftDialog shift={shift} onShiftUpdate={onShiftUpdate} />
          <DeleteShiftDialog id={shift.id} onShiftDeleted={onShiftUpdate} />
        </div>
      </div>
      <AssignShiftRow shift={shift} onShiftUpdate={onShiftUpdate} />
    </div>
  )
}
