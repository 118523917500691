import React from 'react'

import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import { scopedTranslation } from '@utils/I18n'

import FirstNameFieldRow from './FirstNameFieldRow'
import LastNameFieldRow from './LastNameFieldRow'
import EmailFieldRow from './EmailFieldRow'
import MobileFieldRow from './MobileFieldRow'
import CallPhoneFieldRow from './CallPhoneFieldRow'
import type { SharedInertiaProps } from '../../pages/SupporterShow'

const t = scopedTranslation('supporter_show')

type ContactDetailsType = {
  supporterId: string | number
  email: string | null
  mobile: string | null
  callPhone: string | null
}

export default function ContactDetailsForm({
  supporterId,
  email,
  mobile,
  callPhone,
  permissions,
}: ContactDetailsType & SharedInertiaProps) {
  return (
    <>
      <AppErrorsAlert errorsPath="supporterContactDetails" />

      <section
        data-testid="contact-details-form"
        className="tw-flex tw-flex-col tw-px-8 tw-pt-8 tw-shadow-sm tw-rounded-xl tw-border tw-border-gray-200"
        data-compid="supporter-show_contact-details-form"
      >
        <header className="tw-flex tw-flex-col tw-pb-5 tw-border-b tw-border-gray-200">
          <h3 className="tw-font-medium">{t('contact_details')}</h3>
        </header>
        <main className="tw-flex tw-flex-col [&>*]:tw-border-b [&>*]:tw-border-gray-200">
          <FirstNameFieldRow supporterId={supporterId} canEdit={permissions.updateDetails} />
          <LastNameFieldRow supporterId={supporterId} canEdit={permissions.updateDetails} />
          <EmailFieldRow supporterId={supporterId} initialValue={email} canEdit={permissions.updateEmail} />
          <MobileFieldRow supporterId={supporterId} initialValue={mobile} canEdit={permissions.updateMobile} />
          <CallPhoneFieldRow supporterId={supporterId} initialValue={callPhone} canEdit={permissions.updatePhone} />
        </main>
      </section>
    </>
  )
}
