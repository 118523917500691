import { useGraphQLClient } from '@utils/GraphQLClientProvider'
import { gql } from 'graphql-tag'

function useSupporterSearchQuery() {
  const client = useGraphQLClient()

  const SUPPORTERS_QUERY = gql`
    query getSupporters($groupId: ID!, $fullNameICont: String!) {
      group(id: $groupId) {
        supporters(fullNameICont: $fullNameICont) {
          totalCount
          nodes {
            id
            fullName
          }
        }
      }
    }
  `

  type SupporterNode = {
    id: string
    fullName: string
  }

  type SupporterNodes = {
    group: {
      supporters: {
        totalCount: number
        nodes: SupporterNode[]
      }
    }
  }

  const querySupporter = async ({ nameInput, groupId }: { nameInput: string; groupId: string }) => {
    const results = await client.request<SupporterNodes>(SUPPORTERS_QUERY, {
      groupId: groupId,
      fullNameICont: nameInput,
      first: 7,
    })

    const supporters = results.group.supporters.nodes

    return supporters.map((supporter) => ({
      id: supporter.id,
      value: supporter.fullName,
      label: supporter.fullName,
    }))
  }

  return { querySupporter }
}

export default useSupporterSearchQuery
