import { scopedTranslation } from '@utils/I18n'
import useSupportersSearch from '../../shared/hooks/useSupportersSearch'
import { StateCreator } from 'zustand/esm/index'
import { StoreState as FilteringParamsStoreState } from './useSupporterFilteringStore'

const tSort = scopedTranslation('shared.sort.supporters')

const sortOptions = [
  { value: 'joined_at desc', label: tSort('joined_at_desc') },
  { value: 'joined_at asc', label: tSort('joined_at_asc') },
  { value: 'first_name asc', label: tSort('first_name_asc') },
  { value: 'first_name desc', label: tSort('first_name_desc') },
  { value: 'last_name asc', label: tSort('last_name_asc') },
  { value: 'last_name desc', label: tSort('last_name_desc') },
  { value: 'last_contacted_at desc', label: tSort('last_contacted_at_desc') },
  { value: 'last_contacted_at asc', label: tSort('last_contacted_at_asc') },
]

export type StoreState = {
  currentSort?: string
  sortOptions: Array<{ value: string; label: string }>
}

export type StoreActions = {
  setCurrentSort: (sort: string) => void
}

const DEFAULT_PROPS: StoreState = {
  sortOptions,
}

type SliceCreator = StateCreator<FilteringParamsStoreState, [], [], StoreState & StoreActions>

export const createSortSlice: SliceCreator = (set, get, store) => ({
  ...DEFAULT_PROPS,
  setCurrentSort(sort: string) {
    useSupportersSearch({
      activeFilters: get().filters,
      currentSort: sort,
      supporterName: get().supporterName,
      setLoading: get().setLoading,
    })
  },
})
