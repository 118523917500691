import React from 'react'
import Icon from '@components/utils/Icon'
import clsx from 'clsx'

type StepIconProps = {
  status: 'incomplete' | 'complete' | 'current'
  label: string
}

const iconPropsByStatus = {
  incomplete: {
    type: 'dot-circle',
    alt: 'upcoming step',
    width: 28,
    height: 28,
  },
  complete: {
    type: 'check',
    alt: 'completed step',
    width: 28,
    height: 28,
  },
  current: {
    type: 'dot-circle',
    alt: 'current step',
    width: 28,
    height: 28,
  },
} as const

const roundedIconClassesByStatus = {
  incomplete: 'tw-rounded-full tw-text-gray-200 tw-bg-white',
  complete: 'tw-rounded-full tw-text-primary-600 tw-bg-primary-100 tw-p-1.5',
  current: 'tw-rounded-full tw-text-primary-600 tw-bg-primary-100 tw-p-1.5',
}

const labelClassesByStatus = {
  incomplete: 'tw-text-gray-700',
  complete: 'tw-text-gray-700',
  current: 'tw-text-primary-700',
} as const

export default function StepIcon({ status, label }: StepIconProps) {
  return (
    <span className="tw-relative tw-inline-flex tw-flex-col tw-items-center tw-w-8 tw-self-center tw-gap-4">
      <div className={roundedIconClassesByStatus[status]} data-testid="step-icon">
        <Icon {...iconPropsByStatus[status]} dataTestId="step-icon" />
      </div>
      <label
        data-testid="step-icon-label"
        className={clsx('tw-w-max tw-font-medium tw-text-base', labelClassesByStatus[status])}
      >
        {label}
      </label>
    </span>
  )
}
