import type { Scope, TranslateOptions } from 'i18n-js'
import i18n from './I18n-locales'

const i18nScopeToString = (scope: Scope) => (Array.isArray(scope) ? scope.join('.') : scope.toString())

export function t(scope: Scope, options?: TranslateOptions) {
  const scopeStr = i18nScopeToString(scope)
  return i18n.t(`v2.${scopeStr}`, options)
}

export function scopedTranslation(baseScope: Scope, options?: TranslateOptions) {
  return function (scope: Scope, opts = options) {
    const baseScopeStr = i18nScopeToString(baseScope)
    const scopeStr = i18nScopeToString(scope)
    return t(`${baseScopeStr}.${scopeStr}`, opts)
  }
}

type ValidationName = 'required' | 'valid' | 'too_short';

export const i18nValidation = (type: ValidationName, count?: number) => {
  return ({label, path}) => {
    if (type === 'too_short' && count !== undefined) {
      return t(`shared.validations.${type}`, {attribute_name: label ?? path, count})
    }
    return t(`shared.validations.${type}`, {attribute_name: label ?? path});
  }
}