import React from 'react'

import type { User } from '@frontend/graphql/types.generated'

import useMutation from '@hooks/useGQLMutation'

import { useErrorsStore } from '@stores/errorsStore'

import BackLink from '@molecules/BackLink'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import type {
  AddGroupLeadersDocument,
  RemoveGroupLeadersDocument,
} from '../features/manage-leaders/mutations.generated'
import { addGroupLeadersMutation, removeGroupLeadersMutation } from '../features/manage-leaders/mutations'

import useCurrentCaptainsStore from '../features/manage-leaders/stores/currentCaptainsStore'
import { useCaptainsStore } from 'app/frontend/contexts/supporters-management/features/manage-leaders/hooks/useCaptainsStore'

import ManageCurrentCaptains from '../features/manage-leaders/ManageCurrentCaptains'
import ManageNewCaptains from '../features/manage-leaders/ManageNewCaptains'

import { scopedTranslation } from '@utils/I18n'
const tShared = scopedTranslation('shared')

type PropsType = {
  group: {
    id: string
    captains: {
      nodes: User[]
    }
  }
}

export default function ManageCaptains({ group }: PropsType) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const groupId = group.id
  const {
    captains: newCaptains,
    removeCaptain: removeNewCaptain,
    addCaptain: addNewCaptain,
    setState: setNewCaptainsStore,
  } = useCaptainsStore()

  const {
    captains,
    removedCaptains,
    removeCaptain,
    addCaptains,
    setState: setCurrentCaptainsStore,
  } = useCurrentCaptainsStore({
    captains: group.captains.nodes,
  })

  const { mutate: addGroupLeadersMutate } = useMutation<typeof AddGroupLeadersDocument>(addGroupLeadersMutation)
  const { mutate: removeGroupLeadersMutate } =
    useMutation<typeof RemoveGroupLeadersDocument>(removeGroupLeadersMutation)

  const addGroupLeaders = () => {
    clearAppErrors('inviteCaptains')
    addGroupLeadersMutate(
      { id: groupId, users: newCaptains },
      {
        onSuccess: (response) => {
          if (!response.addGroupLeaders) return

          if (response.addGroupLeaders.success && response.addGroupLeaders.users) {
            addCaptains(...response.addGroupLeaders.users)
            setNewCaptainsStore({ captains: [] })
          } else {
            setAppErrors({ errorsPath: 'inviteCaptains', errors: response.addGroupLeaders.errors })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'inviteCaptains',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  const removeGroupLeaders = () => {
    clearAppErrors('manageCaptains')
    const removedUserIds = removedCaptains.map((c) => c.id)
    removeGroupLeadersMutate(
      { id: groupId, users: removedUserIds },
      {
        onSuccess: (response) => {
          if (!response.removeGroupLeaders) return

          if (response.removeGroupLeaders.success) {
            setCurrentCaptainsStore({ removedCaptains: [] })
          } else {
            setAppErrors({ errorsPath: 'manageCaptains', errors: response.removeGroupLeaders.errors })
          }
          setCurrentCaptainsStore({ removedCaptains: [] })
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'manageCaptains',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-16 tw-align-center tw-py-16 tw-min-h-screen tw-mx-auto tw-max-w-7xl tw-font-sans">
      <AppErrorsAlert errorsPath="inviteCaptains" />
      <ManageNewCaptains
        {...{
          captains: newCaptains,
          addCaptain: addNewCaptain,
          removeCaptain: removeNewCaptain,
          addGroupLeaders,
        }}
      />

      <AppErrorsAlert errorsPath="manageCaptains" />
      <ManageCurrentCaptains
        {...{
          captains,
          removeCaptain,
          removedCaptains,
          addCaptains,
          removeGroupLeaders,
        }}
      >
        <BackLink to={`/groups/${group.id}`} color="danger" rank="secondary">
          {tShared('buttons.cancel')}
        </BackLink>
      </ManageCurrentCaptains>
    </div>
  )
}
