import React from 'react'
import { render } from 'react-dom'
import { createInertiaApp } from '@inertiajs/react'
import BaseLayout from '../pages/BaseLayout'

const pages = import.meta.glob('../contexts/**/pages/**/!(*.test|*.spec).tsx', { eager: true })

function LoadInertia() {
  void createInertiaApp({
    resolve: (name) => {
      const page = pages[`../contexts/${name}.tsx`]

      if (!page) {
        throw new Error(`Unknown page ${name}. Is it located under /pages with a .tsx extension?`)
      }

      return page.default
    },
    setup({ el, App, props }) {
      el.classList.add('tw-w-full')
      render(
        <BaseLayout>
          <App {...props} />
        </BaseLayout>,
        el
      )
    },
  })
}

document.addEventListener('DOMContentLoaded', () => {
  LoadInertia()
})
