import React from 'react'

import SortBy from '@molecules/SortBy'

import useSupporterFilteringStore from '../filter-supporters/stores/useSupporterFilteringStore'

function SortSupporters() {
  const currentSort = useSupporterFilteringStore((state) => state.currentSort)
  const sortOptions = useSupporterFilteringStore((state) => state.sortOptions)
  const setCurrentSort = useSupporterFilteringStore((state) => state.setCurrentSort)

  return (
    <SortBy
      onChange={(value) => {
        setCurrentSort(value)
      }}
      sortOptions={sortOptions}
      currentSort={currentSort}
    />
  )
}

export default SortSupporters
