import { create } from 'zustand'
import { type StoreApi } from 'zustand/esm/vanilla'

export type ErrorType = {
  field?: string | null
  message: string
  code: string
}

export type ErrorsType = Record<string, ErrorType[] | undefined | null>

type StoreState = {
  serverErrors: ErrorsType
}

type StoreActions = {
  setErrors: (options: { errorsPath: string; errors: ErrorType[] }) => void
  clearErrors: (errorsPath: string) => void
}

function clearErrors(set: StoreApi<StoreState & StoreActions>['setState'], errorsPath: string) {
  set(({ serverErrors }) => {
    // Destructure the serverErrors object so when setting state it updates
    const newServerErrors: ErrorsType = { ...serverErrors }
    delete newServerErrors[errorsPath]

    return { serverErrors: newServerErrors }
  })
}

function setErrors(
  set: StoreApi<StoreState & StoreActions>['setState'],
  errorsPath: string,
  ...newErrors: ErrorType[]
) {
  set(({ serverErrors }) => {
    const newServerErrors: ErrorsType = { ...serverErrors }

    newServerErrors[errorsPath] ||= []
    newServerErrors[errorsPath] = [...(newServerErrors[errorsPath] ?? []), ...newErrors]

    return { serverErrors: newServerErrors }
  })
}

export const useErrorsStore = create<StoreState & StoreActions>()((set) => ({
  serverErrors: {} satisfies ErrorsType,
  setErrors: ({ errorsPath, errors }) => {
    setErrors(set, errorsPath, ...errors)
  },
  clearErrors: (errorsPath: string) => {
    clearErrors(set, errorsPath)
  },
}))
