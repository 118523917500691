import React from 'react'

import { Slot } from '@hooks/useSlots'
import useGQLMutation from '@hooks/useGQLMutation'

import { redirectBackOrTo } from '@utils/redirectBack'
import { formatDate } from '@utils/formatDate'

import { useErrorsStore } from '@stores/errorsStore'

import { Button, Link } from '@atoms'
import InfoCard from '@molecules/InfoCard'

import type { SupporterEdgeType } from '../../pages/SupporterShow'
import type { Group } from '@frontend/graphql/types.generated'

import { removeSupporterFromGroupMutation } from './mutations'

import { t } from '@utils/I18n'
import useSupporterDetailsStore from '@frontend/contexts/supporters-management/features/edit-supporter/stores/useSupporterDetailsStore'

type SupporterShowCardType = {
  group: Pick<Group, 'id' | 'name'>
  supporter: SupporterEdgeType['node']
  membership: SupporterEdgeType['membership']
}

export default function SupporterShowCard({ group, supporter, membership }: SupporterShowCardType) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { id, createdAt, externalCreatedAt, lastContactedAt, engagementLevel } = supporter

  const joinedGroup = formatDate(membership.createdAt)
  const joinedOrganisation = formatDate(externalCreatedAt ?? createdAt)
  const lastContactedDate = lastContactedAt && formatDate(lastContactedAt)
  const { firstName, fullName } = useSupporterDetailsStore(({ firstName, fullName }) => ({ firstName, fullName }))
  const supporterName = firstName ?? fullName

  const { mutate } = useGQLMutation(removeSupporterFromGroupMutation)

  function removeSupporterFromGroup() {
    clearAppErrors('supporterAction')
    mutate(
      { supporterId: id, groupId: group.id },
      {
        onSuccess: (response) => {
          if (!response.removeSupporterFromGroup) return

          if (response.removeSupporterFromGroup.success) {
            redirectBackOrTo(`/groups/${group.id}`)()
          } else {
            setAppErrors({
              errorsPath: 'supporterAction',
              errors: response.removeSupporterFromGroup.errors,
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'supporterAction',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <InfoCard data-testid={`supporter-card-${id}`}>
      <Slot name="heading">
        <h2 className="tw-text-gray-900 tw-font-medium tw-text-lg">{fullName}</h2>
      </Slot>

      <Slot name="main">
        <div className="tw-flex tw-flex-col tw-gap-3 tw-text-gray-900">
          <div className="tw-flex tw-flex-col tw-gap-4">
            <p data-testid="joined-group">
              <span className="tw-text-gray-blue-500 tw-uppercase tw-font-bold tw-text-xs">
                {t('supporter_show.joined_group')}
              </span>{' '}
              <span className="tw-text-sm">{joinedGroup}</span>
            </p>
            <p data-testid="joined-organisation">
              <span className="tw-text-gray-blue-500 tw-uppercase tw-font-bold tw-text-xs">
                {t('supporter_show.joined_organisation')}
              </span>{' '}
              <span className="tw-text-sm">{joinedOrganisation}</span>
            </p>
            {engagementLevel && (
              <p data-testid="engagement-level">
                <span className="tw-text-gray-blue-500 tw-uppercase tw-font-bold tw-text-xs">
                  {t('supporter_show.engagement_level')}
                </span>{' '}
                <span className="tw-text-sm">{engagementLevel}</span>
              </p>
            )}
            {lastContactedDate && (
              <p data-testid="last-contacted">
                <span className="tw-text-gray-blue-500 tw-uppercase tw-font-bold tw-text-xs">
                  {t('supporter_show.last_contacted')}
                </span>{' '}
                <span className="tw-text-sm">{lastContactedDate}</span>
              </p>
            )}
          </div>
        </div>
      </Slot>

      <Slot name="actions">
        <Slot name="primary">
          <Link
            size="sm"
            rank="primary"
            href={`/members/${id}/contact?action_group_id=${group.id}&v2=true`}
            ariaLabel={t('components.supporter_card.actions.log_contact_aria', {
              name: firstName,
            })}
          >
            {t('components.supporter_card.actions.log_contact', { name: supporterName })}
          </Link>
          <Link
            size="sm"
            rank="secondary"
            href={`/members/${id}/send_email?action_group_id=${group.id}&v2=true`}
            leadingIcon="mail-01"
            disabled={!membership?.emailable}
          >
            {t('components.supporter_card.actions.email', { name: supporterName })}
          </Link>
          <Link
            size="sm"
            rank="secondary"
            href={`/members/${id}/send_sms?action_group_id=${group.id}&v2=true`}
            leadingIcon="message-chat-square"
            disabled={!membership?.textable}
          >
            {t('components.supporter_card.actions.sms', { name: supporterName })}
          </Link>
          <Link
            size="sm"
            rank="secondary"
            href={`/members/${id}/call?action_group_id=${group.id}&v2=true`}
            leadingIcon="phone-call-01"
            disabled={!membership?.callable}
          >
            {t('components.supporter_card.actions.call', { name: supporterName })}
          </Link>
        </Slot>

        <Slot name="secondary">
          <Link
            size="sm"
            rank="link"
            href={`/supporter_groups/${group.id}/members/${id}/unsubscribe?v2=true`}
            leadingIcon="user-x"
          >
            {t('components.supporter_card.actions.manage_subscriptions', { name: firstName })}
          </Link>
          <Button
            size="sm"
            rank="secondary"
            color="danger"
            leadingIcon="trash-01"
            ariaLabel={t('shared.aria_labels.request_supporter_removal', {
              name: supporterName,
              groupName: group.name,
            })}
            onClick={removeSupporterFromGroup}
          >
            {t('shared.buttons.request_supporter_removal', {
              name: supporterName,
              groupName: group.name,
            })}
          </Button>
        </Slot>
      </Slot>
    </InfoCard>
  )
}
