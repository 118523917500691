import useMutation from '@hooks/useGQLMutation'
import { useErrorsStore, type ErrorType } from '@stores/errorsStore'

import { scopedTranslation } from '@utils/I18n'

import { assignShiftMutation } from './mutations'
import type { AssignShiftDocument } from '@frontend/graphql/types.generated'

const t = scopedTranslation('components.assign_shift')

type AssignShiftValues = {
  id: string
  supporterId: string
  onSuccess: () => void
  setFormErrors: (errors: ErrorType[]) => void
}

const useAssignShiftMutation = () => {
  const { setErrors: setAppErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { mutate } = useMutation<typeof AssignShiftDocument>(assignShiftMutation)

  const assignShift = async ({ id, supporterId, onSuccess, setFormErrors }: AssignShiftValues) => {
    clearErrors('assignShift')
    mutate(
      {
        input: {
          id: String(id),
          supporterId: supporterId,
        },
      },
      {
        onSuccess: (response) => {
          if (response?.assignShift?.shift) {
            onSuccess()
          } else {
            setAppErrors({
              errorsPath: 'assignShift',
              errors: response?.assignShift?.errors ?? [
                {
                  code: 'ASSIGN_SHIFT_ERROR',
                  message: t('errors.unable_to_submit_shift_error'),
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'assignShift',
            errors: [
              {
                code: 'ASSIGN_SHIFT_ERROR',
                message: t('errors.unable_to_submit_shift_error'),
              },
            ],
          })
        },
      }
    )
  }

  return { assignShift }
}

export default useAssignShiftMutation
