import React from 'react'
import { Button } from '@atoms'
import { type CurrentCaptainsStoreType } from './stores/currentCaptainsStore'

import { scopedTranslation } from '@utils/I18n'
const t = scopedTranslation('group_edit.manage_captains')

type CurrentCaptainsProps = Pick<CurrentCaptainsStoreType, 'captains' | 'removeCaptain'>

export default function CurrentCaptains({ captains, removeCaptain }: CurrentCaptainsProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full" data-testid="current-captains-preview">
      <table
        className="tw-w-full tw-border tw-border-x-0 tw-border-t-0 tw-border-gray-200 tw-text-xs"
        data-testid="captains-invited-preview"
      >
        <thead className="tw-bg-gray-100 tw-font-medium tw-text-gray-500">
          <tr>
            <td className="tw-py-3 tw-px-4">{t('table.name')}</td>
            <td className="tw-py-3 tw-px-4">{t('table.email')}</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {captains.map((captain) => (
            <tr className="tw-border-t tw-border-gray-200 tw-text-gray-600 tw-font-medium tw-text-sm" key={captain.id}>
              <td className="tw-py-4 tw-px-6">
                {captain.firstName} {captain.lastName}
              </td>
              <td className="tw-py-4 tw-px-6">{captain.email}</td>
              <td className="tw-py-4 tw-px-6 tw-flex tw-gap-2 tw-items-center tw-h-full">
                <Button
                  rank="secondary"
                  size="sm"
                  color="danger"
                  onClick={() => {
                    removeCaptain(captain)
                  }}
                  ariaLabel={t('editor.remove_captain_aria', { email: captain.email })}
                >
                  {t('editor.remove_captain')}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
