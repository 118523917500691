import gql from 'graphql-tag'

export const removeGroupLeadersMutation = gql`
  mutation removeGroupLeaders($id: ID!, $users: [ID!]!) {
    removeGroupLeaders(input: { id: $id, users: $users }) {
      users {
        email
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const addGroupLeadersMutation = gql`
  mutation addGroupLeaders($id: ID!, $users: [DraftUserInput!]!) {
    addGroupLeaders(input: { id: $id, users: $users }) {
      users {
        id
        email
        fullName
        firstName
        lastName
        userRoles(actionGroupIdEq: $id) {
          nodes {
            role {
                name
            }
          }
        }
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
