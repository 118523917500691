import React from 'react'

import { i18nValidation, scopedTranslation } from '@utils/I18n'

import * as yup from 'yup'
import useFormWithErrors from '@hooks/useFormWithErrors'
import { yupResolver } from '@hookform/resolvers/yup'
import useMutation from '@hooks/useGQLMutation'
import { createSupporterNoteMutation } from './mutations'
import { CreateSupporterNoteDocument } from './mutations.generated'
import { serverErrorsToFormErrors } from '@adapters/serverErrorsAdapter'
import { router } from '@inertiajs/react'
import { useErrorsStore } from '@stores/errorsStore'
import { Button, TextInput } from '@atoms'

const t = scopedTranslation('components.new_note_form')

type NewNoteFormProps = {
  supporterId: string
}

export default function NewNoteForm({ supporterId }: NewNoteFormProps) {
  const validations = yup.object().shape({
    content: yup.string().label(t('labels.content')).required(i18nValidation('required')),
  })

  const {
    setErrors: setFormErrors,
    formState: { errors },
    setValue,
    register,
    handleSubmit,
  } = useFormWithErrors({
    resolver: yupResolver(validations),
    mode: 'onTouched',
    defaultValues: {
      content: '',
    },
  })

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { mutate } = useMutation<typeof CreateSupporterNoteDocument>(createSupporterNoteMutation)

  const onSubmit = async (values) => {
    clearAppErrors('supporterNotes')

    mutate(
      {
        personId: supporterId,
        ...values,
      },
      {
        onSuccess: (response) => {
          if (response.createSupporterNote?.success) {
            router.reload({ only: ['supporterNotes'] })
            setValue('content', '')
          } else if (response.createSupporterNote) {
            setFormErrors({
              errorsBasePath: 'supporterNotes',
              errors: response.createSupporterNote.errors,
              adapter: serverErrorsToFormErrors,
            })
            setAppErrors({
              errorsPath: 'supporterNotes',
              errors: response.createSupporterNote.errors,
            })
          } else {
            setAppErrors({
              errorsPath: 'supporterNotes',
              errors: [
                {
                  message: '',
                  code: 'unknown',
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'supporterNotes',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <form className="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-items-center" onSubmit={handleSubmit(onSubmit)}>
      <TextInput displayName={t('labels.content')} {...register('content')} errors={errors} />
      <Button leadingIcon="pencil-02" type="submit">
        {t('buttons.save')}
      </Button>
    </form>
  )
}
