import React from 'react';

import { TextInput } from '@atoms';

import { scopedTranslation } from '@frontend/utils/I18n';

const t = scopedTranslation('attributes.shift')

interface EditShiftFieldRowProps {
  register: any
  errors: any
}

export const EditShiftFieldRow: React.FC<EditShiftFieldRowProps> = ({ register, errors }) => {
  return (
    <div className="tw-flex tw-items-start tw-gap-4">
      <TextInput displayName={t('start_time')} {...register(`startTime`)} type="time" errors={errors} />
      <TextInput displayName={t('end_time')} {...register(`endTime`)} type="time" errors={errors} />
      <TextInput displayName={t('role')} {...register(`roleName`)} type="text" errors={errors} />
    </div>
  )
}
