import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import type { Contact } from '@frontend/graphql/types.generated'
import { scopedTranslation } from '@utils/I18n'
import Tag from '@atoms/Tag'
import Icon, { type IconTypes } from '@components/utils/Icon'
import Alert from '@atoms/Alert'

dayjs.extend(relativeTime)

function IconBubble({ type }: { type: IconTypes }) {
  return (
    <div className="tw-w-10 tw-h-10 tw-text-primary-700 tw-bg-white tw-border tw-border-gray-200 tw-shadow-sm tw-flex tw-items-center tw-justify-center tw-rounded-full">
      <Icon type={type} width={20} height={20} />
    </div>
  )
}

const t = scopedTranslation('components.contact_log_card')

const methodIconMapping: Record<string, IconTypes> = {
  email: 'mail-01',
  email_blast: 'mail-01',
  text: 'message-chat-square',
  text_blast: 'message-chat-square',
  phone_call: 'phone-call-01',
  face_to_face: 'user-square',
} as const

type ContactLogCardProps = Pick<Contact, 'id' | 'createdAt' | 'method' | 'status' | 'note' | 'viaActionCentre'> & {
  user?: { fullName: string }
}

export default function ContactLogCard({
  viaActionCentre,
  createdAt,
  method,
  status: contactStatus,
  note,
  user,
}: ContactLogCardProps) {
  // NOTE: Default to 'other' for contacts synced from NB without a status.
  // Use `||` not `??` to set the default if the status is an empty string.
  const status = contactStatus || 'other'

  let statusText: string
  if (['phone_call', 'face_to_face'].includes(method)) {
    statusText = t(`status.${method}.${status}`)
  } else {
    statusText = t(`status.${method}`)
  }

  let tagColor: 'success' | 'error' = 'success'
  if (['bad_info', 'inaccessible', 'not_interested', 'refused'].includes(status)) {
    tagColor = 'error'
  }

  return (
    <div className="tw-flex tw-py-5 tw-px-8 tw-gap-8 tw-text-sm tw-text-gray-700" data-testid="contact-log-card">
      <div className="tw-flex tw-flex-col">
        <IconBubble type={methodIconMapping[method]} />
        <p className="tw-text-md tw-font-semibold tw-py-2 tw-whitespace-nowrap">{dayjs(createdAt).format('D MMM')}</p>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3 tw-w-full">
        <div className="tw-flex tw-justify-between">
          {/* Status tag */}
          <Tag color={tagColor} size="sm">
            {statusText}
          </Tag>
          <span className="tw-text-xs">
            {dayjs(createdAt).format('DD MMM YYYY, HH:MM')} ({dayjs(createdAt).fromNow()})
          </span>
        </div>
        {/* Person who contacted them */}
        {user ? (
          <p>
            <span className="tw-font-semibold">{t(`method.${method}`)}: </span>
            {user?.fullName}
          </p>
        ) : (
          <Alert type="info">{t(`no_user.${viaActionCentre ? 'internal' : 'external'}_contact`)}</Alert>
        )}

        {note && <p>{note}</p>}
      </div>
    </div>
  )
}
