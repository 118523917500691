import gql from 'graphql-tag'

export const addDraftGroupUserMutation = gql`
  mutation addDraftGroupUser($id: ID!, $user: DraftUserInput!) {
    addDraftGroupUser(input: { id: $id, user: $user }) {
      user {
        email
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const createGroupMutation = gql`
  mutation createGroup($id: ID!) {
    createGroup(input: { id: $id }) {
      group {
        id
        name
        description
        publicPublish
        publicDescription
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const removeDraftGroupUserMutation = gql`
  mutation removeDraftGroupUser($id: ID!, $email: String!) {
    removeDraftGroupUser(input: { id: $id, email: $email }) {
      user {
        email
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
