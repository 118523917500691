import React, { useState } from 'react'
import { clsx } from 'clsx'
import * as yup from 'yup'
import { type Control, type FieldErrors, useController, useFormContext, type UseFormRegister } from 'react-hook-form'

import { SelectDropdown, TextInput } from '@atoms'
import CountrySelect from '@atoms/form/CountrySelect'
import RichText from '@molecules/RichText'
import * as CollapsibleCheckbox from '@molecules/CollapsibleCheckbox'

import { i18nValidation, scopedTranslation } from '@utils/I18n'
import AdminsSelect from '@components/molecules/AdminSelect'
const t = scopedTranslation('group_create.group_details_form')
const tAttributes = scopedTranslation('attributes.group')

export const validationSchema = yup.object({
  group: yup.object({
    name: yup
      .string()
      .label(tAttributes('name'))
      .required(i18nValidation('required'))
      .min(3, i18nValidation('too_short', 3)),
    description: yup
      .string()
      .label(tAttributes('description'))
      .notRequired()
      .matches(/.{5,}/, {
        excludeEmptyString: true,
        message: i18nValidation('too_short', 5),
      }),
    publicPublish: yup.boolean(),
    publicDescription: yup.string().when('public_publish', {
      is: true,
      then: () => yup.string().label(tAttributes('public_description')).required(i18nValidation('required')),
    }),
    hasPhysicalAddress: yup.boolean(),
    venueName: yup.string().nullable(),
    streetAddress: yup.string().nullable(),
    city: yup
      .string()
      .nullable()
      .when('hasPhysicalAddress', {
        is: true,
        then: () => yup.string().label(tAttributes('city')).required(i18nValidation('required')),
      }),
    postcode: yup
      .string()
      .nullable()
      .when('hasPhysicalAddress', {
        is: true,
        then: () => yup.string().label(tAttributes('postcode')).required(i18nValidation('required')),
      }),
    state: yup
      .string()
      .nullable()
      .when('hasPhysicalAddress', {
        is: true,
        then: () => yup.string().label(tAttributes('state')).required(i18nValidation('required')),
      }),
    country: yup
      .string()
      .nullable()
      .when('hasPhysicalAddress', {
        is: true,
        then: () => yup.string().label(tAttributes('country')).required(i18nValidation('required')),
      }),
    addPointPeople: yup.boolean(),
    notifyAllAdmins: yup.boolean(),
    pointPeople: yup.array().of(
      yup.object({
        value: yup.number().required(i18nValidation('required')),
        label: yup.string().required(i18nValidation('required')),
      })
    ),
  }),
})

type extractFieldValues<Type> = Type extends yup.ObjectSchema<infer T> ? T : never
export type ValidationSchema = extractFieldValues<typeof validationSchema>

function PublicPageDescription({
  control,
  primarySiteBaseUrl,
}: {
  control: Control<ValidationSchema>
  primarySiteBaseUrl: string
}) {
  const { field } = useController({ name: 'group.publicDescription', control })

  return (
    <CollapsibleCheckbox.Root
      dataCompId={'group-edit_create-a-public-page'}
      control={control}
      checkboxName="group.publicPublish"
    >
      <CollapsibleCheckbox.Checkbox>
        <span className="tw-width-full tw-inline">{t('labels.public_publish')}:&nbsp;</span>
        <span>
          <a
            href={primarySiteBaseUrl}
            className="tw-text-blue-500 focus:tw-border-primary-500 focus:tw-ring-4 focus:tw-ring-primary-300"
          >
            {primarySiteBaseUrl}
          </a>
          <span className="tw-pl">?</span>
        </span>
      </CollapsibleCheckbox.Checkbox>
      <CollapsibleCheckbox.Content>
        <div className="tw-pt-4">
          <RichText
            name={field.name}
            control={control}
            displayName={t('labels.public_description')}
            hint={t('hints.public_description')}
          />
        </div>
      </CollapsibleCheckbox.Content>
    </CollapsibleCheckbox.Root>
  )
}

function PhysicalAddress() {
  const { control, register } = useFormContext()
  const {
    formState: { errors },
  } = useController({ name: 'group.hasPhysicalAddress', control })

  return (
    <CollapsibleCheckbox.Root
      dataCompId={'group-edit_add-physical-address'}
      control={control}
      checkboxName="group.hasPhysicalAddress"
    >
      <CollapsibleCheckbox.Checkbox>{t('labels.physical_address')}</CollapsibleCheckbox.Checkbox>
      <CollapsibleCheckbox.Content>
        <div className="tw-flex tw-flex-col tw-pt-4 tw-gap-2">
          <TextInput displayName={t('labels.venue_name')} errors={errors} {...register('group.venueName')} />
          <TextInput displayName={t('labels.street_address')} errors={errors} {...register('group.streetAddress')} />
          <span className="tw-flex tw-gap-4 [&>*]:tw-flex-1 tw-items-baseline">
            <TextInput displayName={t('labels.city')} errors={errors} {...register('group.city')} />
            <TextInput displayName={t('labels.postcode')} errors={errors} {...register('group.postcode')} />
          </span>
          <span className="tw-flex tw-gap-4 [&>*]:tw-flex-1 tw-items-baseline">
            <TextInput displayName={t('labels.state')} errors={errors} {...register('group.state')} />
            <CountrySelect label={t('labels.country')} name="group.country" control={control} />
          </span>
        </div>
      </CollapsibleCheckbox.Content>
    </CollapsibleCheckbox.Root>
  )
}

function PointPeopleForm({ control }) {
  const { field: notifyAllAdminsField } = useController({ name: 'group.notifyAllAdmins', control })
  const { field: pointPeopleField } = useController({ name: 'group.pointPeople', control })

  return (
    <CollapsibleCheckbox.Root
      dataCompId={'group-edit_add-point-people'}
      control={control}
      checkboxName={'group.addPointPeople'}
    >
      <CollapsibleCheckbox.Checkbox>
        {t('labels.add_point_people')} {t('hints.add_point_people')}
      </CollapsibleCheckbox.Checkbox>
      <CollapsibleCheckbox.Content>
        <div className="tw-flex tw-pt-4 tw-gap-4">
          <div className="tw-w-full tw-h-fit">
            <SelectDropdown.Root
              name="group.addPointPeople"
              value={notifyAllAdminsField.value ? 'true' : 'false'}
              onValueChange={(value) => {
                notifyAllAdminsField.onChange(value === 'true')
              }}
            >
              <SelectDropdown.Trigger aria-label={t('aria_labels.notify_all_admins')}>
                <SelectDropdown.Value />
              </SelectDropdown.Trigger>
              <SelectDropdown.Content>
                <SelectDropdown.Item value={'true'}>{t('point_people_dropdown.notify_all_admins')}</SelectDropdown.Item>
                <SelectDropdown.Item value={'false'}>
                  {t('point_people_dropdown.notify_specific_admins')}
                </SelectDropdown.Item>
              </SelectDropdown.Content>
            </SelectDropdown.Root>
            <div className="tw-text-gray-500 tw-text-sm tw-mt-2">{t('hints.point_people')}</div>
          </div>
          <div className={clsx('tw-w-full tw-h-fit', notifyAllAdminsField.value && 'tw-invisible')}>
            <AdminsSelect
              label={t('labels.point_people')}
              field={pointPeopleField}
              disabled={notifyAllAdminsField.value}
            />
          </div>
        </div>
      </CollapsibleCheckbox.Content>
    </CollapsibleCheckbox.Root>
  )
}

type GroupFormProps = {
  register: UseFormRegister<ValidationSchema>
  errors: FieldErrors<ValidationSchema>
  control: Control<ValidationSchema>
  primarySiteBaseUrl: string
}

function GroupForm({ register, errors, control, primarySiteBaseUrl }: GroupFormProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <TextInput
        dataCompId={'group-name_input'}
        displayName={t('labels.name')}
        required={true}
        errors={errors}
        {...register('group.name')}
      />
      <TextInput
        dataCompId={'group-description_input'}
        displayName={t('labels.description')}
        errors={errors}
        {...register('group.description')}
      />
      <PhysicalAddress />
      <PublicPageDescription control={control} primarySiteBaseUrl={primarySiteBaseUrl} />
      <PointPeopleForm control={control} />
    </div>
  )
}

type GroupFormPageProps = {
  primarySiteBaseUrl: string
}

export default function GroupFormPage({ primarySiteBaseUrl }: GroupFormPageProps) {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ValidationSchema>()

  return (
    <form
      aria-label={t('aria_labels.new_supporter_group')}
      className={clsx('tw-flex tw-flex-col tw-gap-4', 'tw-text-gray-700')}
    >
      <GroupForm register={register} control={control} errors={errors} primarySiteBaseUrl={primarySiteBaseUrl} />
    </form>
  )
}
