import React from 'react'
import type { IconTypes } from "@components/utils/Icon"
import Icon from "@components/utils/Icon"

export type emptyStateProps = {
  iconType: IconTypes,
  headline: string,
  subtext: string
  children: React.ReactNode
}

export default function EmptyState({ iconType, headline, subtext, children }: emptyStateProps) {
  return (
    <div className="tw-flex tw-w-full tw-items-center tw-flex-col tw-gap-4 p-5">
      <div className="tw-bg-primary-50 tw-rounded-full tw-w-[88px] tw-h-[88px] tw-flex tw-justify-center tw-items-center">
        <Icon type={iconType} width={38} height={38} className="tw-text-primary-600" />
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-text-black tw-text-center">
        <h3 className="tw-text-lg tw-font-semibold">{headline}</h3>
        <p className="tw-text-sm">{subtext}</p>
      </div>
      <div className="tw-w-full tw-flex tw-justify-center">
        {children}
      </div>
    </div>
  )
}
