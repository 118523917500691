import React from 'react'
import * as SelectPrimitive from '@radix-ui/react-select'

import cn from '@components/utils/cn'
import Icon from '@components/utils/Icon'

const Root: typeof SelectPrimitive.Root = SelectPrimitive.Root

const Group: typeof SelectPrimitive.Group = SelectPrimitive.Group

const Value: typeof SelectPrimitive.Value = SelectPrimitive.Value

const Trigger: typeof SelectPrimitive.Trigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between tw-py-2 tw-px-4',
      'tw-bg-white tw-border tw-rounded tw-border-gray-400 tw-ring-primary-100 tw-shadow-sm',
      'tw-text-md tw-text-left [&>span]:tw-line-clamp-1',
      'focus:tw-outline-none focus:tw-border-primary-400 focus:tw-ring-4',
      'disabled:tw-cursor-not-allowed disabled:tw-text-gray-500 disabled:tw-bg-gray-100',
      className
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <Icon type="chevron-down" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
))
Trigger.displayName = SelectPrimitive.Trigger.displayName

const Content: typeof SelectPrimitive.Content = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'tw-bg-white tw-relative tw-z-50 tw-max-h-96 tw-min-w-[8rem]',
        'tw-overflow-hidden tw-rounded-md tw-border tw-shadow-md',
        'data-[state=open]:tw-animate-in data-[state=open]:tw-fade-in-0 data-[state=open]:tw-zoom-in-95',
        'data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=closed]:tw-zoom-out-95',
        'data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:tw-translate-y-1 data-[side=left]:tw--translate-x-1 data-[side=right]:tw-translate-x-1 data-[side=top]:tw--translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          'tw-p-1',
          position === 'popper' &&
            'tw-h-[var(--radix-select-trigger-height)] tw-w-full tw-min-w-[var(--radix-select-trigger-width)]'
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
Content.displayName = SelectPrimitive.Content.displayName

const Label: typeof SelectPrimitive.Label = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('tw-py-1.5 tw-pr-2 tw-text-md tw-font-semibold', className)}
    {...props}
  />
))
Label.displayName = SelectPrimitive.Label.displayName

const Item: typeof SelectPrimitive.Item = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'tw-relative tw-flex tw-w-full tw-cursor-default tw-select-none tw-items-center tw-rounded-sm tw-py-1.5 tw-px-2 tw-pr-2 tw-text-md tw-outline-none focus:tw-bg-primary-100 focus:tw-text-primary-900 data-[disabled]:tw-pointer-events-none',
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
Item.displayName = SelectPrimitive.Item.displayName

const SelectSeparator: typeof SelectPrimitive.Separator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('tw--mx-1 tw-my-1 tw-h-px tw-bg-muted', className)} {...props} />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export { Root, Group, Value, Trigger, Content, Label, Item, SelectSeparator }
