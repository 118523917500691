import { DraftGroup, Group } from '@frontend/graphql/types.generated'
import { ValidationSchema } from '../features/edit-group-details/GroupFormPage'

type Fields =
  | 'id'
  | 'name'
  | 'description'
  | 'publicPublish'
  | 'publicDescription'
  | 'hasPhysicalAddress'
  | 'venueName'
  | 'streetAddress'
  | 'city'
  | 'postcode'
  | 'state'
  | 'country'
  | 'notifyAllAdmins'

type ServerGroupType = Pick<Group | DraftGroup, Fields> & {
  pointPeople: {
    nodes: Array<{ id: number; fullName?: string | null }>
  }
}

type ServerInputGroupType<T extends FrontendGroupType> = Omit<T, 'addPointPeople' | 'pointPeople'> & {
  pointPeople: string[]
}

type FrontendGroupType = ValidationSchema['group']

export default class GroupDetailsAdapter<T extends FrontendGroupType> {
  group: T

  constructor(group: T) {
    this.group = group
  }

  static fromServer(group: ServerGroupType) {
    const frontendGroup = {
      ...group,
      hasPhysicalAddress: group.hasPhysicalAddress ?? false,
      publicDescription: group.publicDescription ?? '',
      publicPublish: group.publicPublish ?? false,
      addPointPeople: Boolean(group.notifyAllAdmins || group.pointPeople.nodes?.length),
      notifyAllAdmins: group.notifyAllAdmins ?? false,
      pointPeople: group.pointPeople?.nodes?.map((node) => ({ value: node.id, label: node.fullName || '' })) ?? [],
    }
    return new GroupDetailsAdapter(frontendGroup)
  }

  static fromFrontend<T extends FrontendGroupType>(group: T) {
    return new GroupDetailsAdapter<T>(group)
  }

  toServer() {
    const { addPointPeople, notifyAllAdmins, pointPeople, ...serverType } = this.group

    return {
      ...serverType,
      notifyAllAdmins: Boolean(addPointPeople && notifyAllAdmins),
      pointPeople: addPointPeople && pointPeople ? pointPeople.map(({ value }) => value.toString()) : [],
    } as ServerInputGroupType<T>
  }

  toFrontend(): FrontendGroupType {
    return {
      ...this.group,
    }
  }
}
