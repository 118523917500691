import React from 'react'
import sprite from '../../icons/sprite.svg'

const IconTypesList = [
  'alert-circle',
  'alert-triangle',
  'align-center',
  'align-left',
  'align-right',
  'arrow-circle-broken-right',
  'arrow-narrow-left',
  'arrow-narrow-right',
  'asterisk',
  'bold',
  'book-open-01',
  'check',
  'chevron-down',
  'chevron-right',
  'dot-circle',
  'dot-points',
  'dot',
  'download-cloud',
  'eye',
  'filter-lines',
  'flip-backwards',
  'flip-forwards',
  'funnel',
  'heading',
  'horizontal-rule',
  'info-circle',
  'italic',
  'line-chart-up',
  'location',
  'mail-01',
  'message-chat-square',
  'minus-circle',
  'ordered-list',
  'pencil-02',
  'phone-call-01',
  'plus-circle',
  'plus',
  'refresh',
  'save-disk',
  'search-lg',
  'sort-ascending',
  'sort-descending',
  'spinner-line',
  'trash-01',
  'trash',
  'underline',
  'user-square',
  'user-x',
  'users-01',
  'x-close',
] as const

export type IconTypes = (typeof IconTypesList)[number]
type IconProps = {
  type: IconTypes
  dataTestId?: string
  className?: string
  width?: number
  height?: number
  alt?: string
}

function Icon({ type, dataTestId, alt, ...props }: IconProps) {
  return (
    <svg
      data-testid={dataTestId ? `${dataTestId}-${type}` : type}
      width="24"
      height="24"
      // Remove parent viewBox to use the icon's own viewBox value in sprite.svg
      // viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {alt && <title>{alt}</title>}
      <use xlinkHref={`${sprite.toString()}#${type}`} />
    </svg>
  )
}

export default Icon
