import gql from 'graphql-tag'

export const assignShiftMutation = gql`
  mutation assignShift($input: AssignShiftInput!) {
    assignShift(input: $input) {
      shift {
        id
        roleName
        startTime
        endTime
        supporter {
          id
          fullName
        }
      }
      success
      errors {
        code
        field
        message
        solution
      }
    }
  }
`
