import React from 'react'
import { create } from 'zustand'

import type { Event, Shift, ShiftConnection } from '@frontend/graphql/types.generated'

import { ShiftsTable } from '../features/shifts/ShiftsTable'

type ShiftsStore = {
  groupId: string
  setGroupId: (groupId: string) => void
}

export const useShiftsStore = create<ShiftsStore>((set) => ({
  groupId: '',
  setGroupId: (groupId) => set({ groupId }),
}))

type ShiftsIndexProps = {
  event: Pick<Event, 'id' | 'name' | 'startTime' | 'endTime'> & {
    roster: {
      shifts: Pick<ShiftConnection, 'totalCount'> & {
        nodes: Array<Pick<Shift, 'id' | 'roleName' | 'startTime' | 'endTime' | 'draft'>>
      }
    }
  }
  groupId: string
}

const ShiftsIndex = ({ event, groupId }: ShiftsIndexProps) => {
  const setGroupId = useShiftsStore((state) => state.setGroupId)
  setGroupId(groupId)

  return (
    <div className="tw-p-4">
      <ShiftsTable event={event} />
    </div>
  )
}

export default ShiftsIndex;
