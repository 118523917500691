import React from 'react'

import { scopedTranslation } from '@utils/I18n'

import useSupporterFilteringStore from '../filter-supporters/stores/useSupporterFilteringStore'

const tGroupShow = scopedTranslation('group_show')

type SearchSummaryBarProps = {
  searchLoading: boolean
  matchingCount: number
}

function SearchSummaryBar({ searchLoading, matchingCount }: SearchSummaryBarProps) {
  const activeFilters = useSupporterFilteringStore((state) => state.filters)
  const supporterNameSearch = useSupporterFilteringStore((state) => state.supporterName)

  const hasActiveFilters = activeFilters?.length > 0
  const hasSupporterNameSearch = Boolean(supporterNameSearch)

  const showSearchSummaryBar = hasActiveFilters || hasSupporterNameSearch

  const message = (() => {
    if (hasSupporterNameSearch && !hasActiveFilters) {
      return matchingCount === 0
        ? tGroupShow('empty_state.messages.no_results.search_only')
        : tGroupShow('matching_search', { count: matchingCount })
    } else if (!hasSupporterNameSearch && hasActiveFilters) {
      return matchingCount === 0
        ? tGroupShow('empty_state.messages.no_results.filters_only')
        : tGroupShow('matching_filters', { count: matchingCount })
    } else if (hasSupporterNameSearch && hasActiveFilters) {
      return matchingCount === 0
        ? tGroupShow('empty_state.messages.no_results.search_and_filters')
        : tGroupShow('matching_search_and_filters', { count: matchingCount })
    }
  })()

  return (
    <div role="status" aria-busy={searchLoading}>
      {(showSearchSummaryBar ?? searchLoading) && (
        <div className="tw-flex tw-align-items-center tw-bg-primary-50 tw-py-4 tw-px-6 tw-gap-2.5 ">
          <p className="tw-text-primary-600">{message}</p>
        </div>
      )}
    </div>
  )
}

export default SearchSummaryBar
