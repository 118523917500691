import { I18n } from 'i18n-js'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import en from '../locales/en.json'
import it from '../locales/it.json'
import nb from '../locales/nb.json'

// Import the translations that come with dayjs (only for our locales)
// > Note: These translations come from the dayjs package,
//   and we are not required to do anything to set them
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import 'dayjs/locale/nb'

const i18n = new I18n({
  ...en,
  ...it,
  ...nb,
})

// NOTE: Turkish ('tr') has been removed:
i18n.availableLocales = ['en', 'it', 'nb']
i18n.enableFallback = true

// @ts-expect-error window.locale doesn't exist (according to TypeScript)
const currentLocale = window.locale || 'en'
i18n.locale = currentLocale

// Setup daysjs to handle different locales and set the default locale of dayjs
dayjs.extend(localizedFormat)
// @ts-expect-error window.locale doesn't exist (according to TypeScript)
dayjs.locale(window.locale || 'en')

// @ts-expect-error window.isDevelopment doesn't exist (according to TypeScript)
if (window.isDevelopment) {
  i18n.missingBehavior = 'error'
}

export default i18n
