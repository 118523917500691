import React from 'react'

import { TextInput } from '@atoms'
import debounce from '@utils/debounce'
import usePartialReload from '@hooks/usePartialReload'

export const searchFn = (
  event: React.ChangeEvent<HTMLInputElement>,
  partialReloadKeys: string[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { name, value } = event.target
  const queryString = `${name}=${value}`
  usePartialReload(queryString, partialReloadKeys, setLoading)
}

type SearchFieldProps = {
  displayName: string
  fieldName: string
  placeholder?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  dataCompId?: string
  defaultValue?: string
}

const SearchField = React.forwardRef<HTMLInputElement, SearchFieldProps>(function SearchField({ 
    fieldName,
    displayName,
    placeholder,
    onChange,
    dataCompId,
    defaultValue 
  }, ref) { 

  defaultValue = defaultValue ?? new URLSearchParams(window.location.search).get(fieldName) ?? ''
  const onChangeFn = debounce(onChange, 500)

  return (
    <TextInput
      name={fieldName}
      type="search"
      displayName={displayName}
      placeholder={placeholder ?? displayName}
      defaultValue={defaultValue}
      onChange={onChangeFn}
      hideLabel={true}
      leadingIcon="search-lg"
      errors={{}}
      ref={ref}
      {...(dataCompId && { 'data-compid': `${dataCompId}` })}
    />
  )
})

export default SearchField
