import React, { useEffect } from 'react'

import AddLeadersForm from '../features/manage-leaders/AddLeadersForm'
import { Card } from '@layouts'
import RemoveLeadersForm from '../features/manage-leaders/RemoveLeadersForm'
import useLeadersStore from '../features/manage-leaders/stores/leadersStore'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import BackLink from '@molecules/BackLink'
import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('group_edit.manage_leaders')

type PropsType = {
  group: {
    id: string
    leaders: {
      nodes: any[]
    }
  }
}

export default function ManageLeaders({ group }: PropsType) {
  const { leaders, addLeader, removeLeader } = useLeadersStore((store) => store)

  useEffect(() => {
    const initialLeaders = group.leaders.nodes.map((leader) => ({
      id: leader.id,
      fullName: leader.fullName,
      email: leader.email,
      role: leader.userRoles.nodes[0]?.role?.name,
    }))

    initialLeaders.forEach((leader) => {
      addLeader(leader)
    })
  }, [])

  return (
    <div className="tw-px-16 tw-py-16 tw-min-h-screen">
      <Card title={'Manage Leaders'} backgroundFill={true} className={'tw-px-12 tw-py-5'}>
        <AppErrorsAlert errorsPath="manageLeaders" />
        <RemoveLeadersForm groupID={group.id} removeLeader={removeLeader} leaders={leaders} />
        <AddLeadersForm groupID={group.id} addLeader={addLeader} />
      </Card>

      <div className="tw-w-full tw-flex tw-justify-center tw-pt-10">
        <BackLink to={`/groups/${group.id}`} color="primary" rank="secondary" leadingIcon="arrow-narrow-left">
          {t('back_to_group')}
        </BackLink>
      </div>
    </div>
  )
}
