import clsx from 'clsx'

export const buttonVariants = ({ variant = 'ghost' }: { variant: 'ghost' }) => {
  return clsx(
    'tw-whitespace-nowrap tw-rounded-md',
    'focus:tw-border-primary-500 focus:tw-ring-4 focus:tw-ring-primary-300',
    {
      ghost: 'tw-bg-transparent hover:tw-bg-primary-50',
    }[variant]
  )
}

