import { useState } from 'react'

import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { useErrorsStore } from '@stores/errorsStore'

import useMutation from '@hooks/useGQLMutation'

import type { UpdateSupporterDocument, UpdateSupporterMutation } from '../mutations.generated'
import { updateSupporterMutation } from '../mutations'

type UseSupporterUpdateMutationType = {
  onSuccess?: (response: UpdateSupporterMutation) => void
  onError?: () => void
  setFormErrors: any
  supporterId: string | number
}

export default function useSupporterUpdateMutation({
  onSuccess,
  onError,
  setFormErrors,
  supporterId,
}: UseSupporterUpdateMutationType) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const [saving, setSaving] = useState(false)

  const { mutate } = useMutation<typeof UpdateSupporterDocument>(updateSupporterMutation)

  const onSubmit = async (values) => {
    clearAppErrors('supporterContactDetails')

    setSaving(true)
    mutate(
      { id: supporterId, ...values.supporter },
      {
        onSuccess: (response) => {
          setSaving(false)
          if (response.updateSupporter?.success) {
            onSuccess && onSuccess(response)
            clearAppErrors('supporterContactDetails')
          } else if (response.updateSupporter) {
            setFormErrors({
              errorsBasePath: 'supporter',
              errors: response.updateSupporter.errors,
              adapter: serverErrorsToFormErrors,
            })
            setAppErrors({
              errorsPath: 'supporterContactDetails',
              errors: response.updateSupporter.errors,
            })
          } else {
            onError && onError()
            setAppErrors({
              errorsPath: 'supporterContactDetails',
              errors: [
                {
                  message: '',
                  code: 'unknown',
                },
              ],
            })
          }
        },
        onError: () => {
          setSaving(false)
          onError && onError()
          setAppErrors({
            errorsPath: 'updateSupporter',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return {
    onSubmit,
    saving,
  }
}
