import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useMutation from '@hooks/useGQLMutation'
import useFormWithErrors from '@hooks/useFormWithErrors'

import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { scopedTranslation } from '@utils/I18n'
import { useGraphQLClient } from '@utils/GraphQLClientProvider'

import { useErrorsStore } from '@stores/errorsStore'

import { Button } from '@atoms'
import Tag from '@atoms/Tag'

import { getSupporterTagFilters } from './queries'
import type { GetGroupSupporterQuery } from './queries.generated'
import type { UpdateSupporterTagsDocument, UpdateSupporterTagsMutation } from './mutations.generated'
import { updateSupporterTagsMutation } from './mutations'
import useSupporterDetailsStore from './stores/useSupporterDetailsStore'
import EditableTagsField from './EditableTagsField'

const t = scopedTranslation('shared')
const tAttributes = scopedTranslation('attributes')

type TagsFieldRowType = {
  supporterId: string | number
  groupId: string | number
  canEdit?: boolean
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export default function TagsFieldRow({ groupId, supporterId, canEdit }: TagsFieldRowType) {
  const {
    tags: value,
    setTags: setValue,
    setTagFilters,
  } = useSupporterDetailsStore((state) => ({
    tags: state.tags,
    setTags: state.setTags,
    setTagFilters: state.setTagFilters,
  }))

  const [open, setOpen] = React.useState(false)

  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const editButtonRef = React.useRef<HTMLButtonElement | null>(null)

  const validations = yup.object().shape({
    supporter: yup.object({
      tags: yup
        .array()
        .of(
          yup.object({
            value: yup.string(),
            label: yup.string(),
          })
        )
        .label(tAttributes('supporter.tags')),
    }),
  })

  const client = useGraphQLClient()

  const {
    setErrors: setFormErrors,
    register,
    handleSubmit,
    control,
    setValue: setFormValue,
  } = useFormWithErrors({
    resolver: yupResolver(validations),
    mode: 'onTouched',
    defaultValues: {
      supporter: {
        tags: value ?? [],
      },
    },
  })

  const inputProps = register('supporter.tags')

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const getTagFilters = async () => {
    const { group } = await client.request<GetGroupSupporterQuery>(getSupporterTagFilters, {
      groupId,
      supporterId,
    })

    return group?.supporters?.edges?.[0]?.tagFilters ?? []
  }

  const onSuccess = async (response: UpdateSupporterTagsMutation) => {
    setOpen(false)
    const tags = response.updateSupporterTags?.supporter?.tags?.nodes ?? []
    // Make a GQL query to get a supporter's tag filters
    const tagFilters = await getTagFilters()
    setValue(tags.filter(notEmpty).map((tag) => ({ value: tag.name, label: tag.name })))
    setTagFilters(tagFilters.filter(notEmpty).map((tagFilter) => ({ value: tagFilter.id, label: tagFilter.name })))
    editButtonRef.current?.focus()
  }

  useEffect(() => {
    setFormValue('supporter.tags', value)
  }, [value, setFormValue])

  const { mutate } = useMutation<typeof UpdateSupporterTagsDocument>(updateSupporterTagsMutation)

  const onSubmit = async (values) => {
    clearAppErrors('supporterTags')

    mutate(
      {
        id: supporterId,
        ...values.supporter,
        tags: values.supporter.tags.map((tag) => tag.value),
      },
      {
        onSuccess: async (response) => {
          if (response.updateSupporterTags?.success) {
            await onSuccess(response)
            clearAppErrors('supporterTags')
          } else if (response.updateSupporterTags) {
            setFormErrors({
              errorsBasePath: 'supporterTags',
              errors: response.updateSupporterTags.errors,
              adapter: serverErrorsToFormErrors,
            })
            setAppErrors({
              errorsPath: 'supporterTags',
              errors: response.updateSupporterTags.errors,
            })
          } else {
            setAppErrors({
              errorsPath: 'supporterTags',
              errors: [
                {
                  message: '',
                  code: 'unknown',
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'supporterTags',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  const onCancel = () => {
    setOpen(false)
    setTimeout(() => {
      editButtonRef.current?.focus()
    })
    setFormValue('supporter.tags', value ?? [])
  }

  const onEdit = () => {
    setOpen(true)
    setTimeout(() => {
      inputRef.current?.focus()
    })
  }

  return (
    <div
      data-testid="tags-form"
      className="tw-grid tw-grid-cols-5 tw-grid-rows-1 tw-gap-4 tw-items-center tw-px-8 tw-py-5 tw-shadow-sm tw-rounded-xl tw-border tw-border-gray-200"
    >
      <div>
        <h3 className="tw-font-medium">{tAttributes('supporter.tags')}</h3>
      </div>
      {open ? (
        <div className="tw-col-span-3">
          <EditableTagsField
            {...inputProps}
            onSubmit={handleSubmit(onSubmit)}
            label={tAttributes('supporter.tags')}
            control={control}
            ref={(el) => {
              inputRef.current = el
              inputProps.ref(el)
            }}
            onCancel={onCancel}
          />
        </div>
      ) : (
        <div className="tw-col-span-3">
          <p className="tw-flex tw-gap-2 tw-flex-wrap">
            {value?.map((tag) => (
              <Tag key={tag.value} size="sm">
                {tag.label}
              </Tag>
            ))}
          </p>
        </div>
      )}

      <div className="tw-col-start-5 tw-flex tw-justify-end">
        <Button
          rank="secondary"
          leadingIcon="pencil-02"
          onClick={onEdit}
          ref={editButtonRef}
          disabled={canEdit ? open : true}
          ariaLabel={t('aria_labels.edit_object', { name: tAttributes('supporter.tags') })}
          dataCompId="supporter-show_tags-field-row_edit"
        >
          {t('buttons.edit')}
        </Button>
      </div>
    </div>
  )
}
