import React from 'react'
import { type Control, useController } from 'react-hook-form'
import * as RCollapsible from '@radix-ui/react-collapsible'
import clsx from 'clsx'
import { Checkbox } from '@atoms'

type CollapsibleCheckboxContextType = {
  control: Control
  checkboxName: string
  checkboxValue: boolean
}
const CollapsibleCheckboxContext = React.createContext<object | CollapsibleCheckboxContextType>({})

// ---- CollapsibleCheckbox.Root ----
type CollapsibleCheckboxProps = {
  control: Control<any>
  checkboxName: string
  children: React.ReactNode
  dataCompId?: string
}
function CollapsibleCheckboxRoot({ control, checkboxName, children, dataCompId }: CollapsibleCheckboxProps) {
  const {
    field: { value: checkboxValue },
  } = useController({ name: checkboxName, control })

  return (
    <CollapsibleCheckboxContext.Provider value={{ control, checkboxName, checkboxValue }}>
      <RCollapsible.Root open={checkboxValue} asChild>
        <div
          className={clsx(
            'tw-flex tw-flex-col tw-justify-center tw-p-4',
            'tw-bg-white tw-border tw-border-gray-400 tw-rounded-lg',
            'data-[state=open]:tw-bg-primary-50 data-[state=open]:tw-border-primary-200'
          )}
          data-testid={checkboxName}
          {...(dataCompId && { 'data-compid': dataCompId })}
        >
          {children}
        </div>
      </RCollapsible.Root>
    </CollapsibleCheckboxContext.Provider>
  )
}
// ----------------------------------

// ---- CollapsibleCheckbox.Checkbox ----
type CollapsibleCheckboxCheckboxProps = {
  children: React.ReactNode
}
function CollapsibleCheckboxCheckbox({ children }: CollapsibleCheckboxCheckboxProps) {
  const { control, checkboxName, checkboxValue } = React.useContext(
    CollapsibleCheckboxContext
  ) as CollapsibleCheckboxContextType

  return (
    <div className="tw-flex tw-items-center lg:tw-items-start tw-gap-x-2.5">
      <RCollapsible.Trigger asChild>
        <Checkbox control={control} name={checkboxName} defaultChecked={checkboxValue}>
          {children}
        </Checkbox>
      </RCollapsible.Trigger>
    </div>
  )
}
// ----------------------------------

// ---- CollapsibleCheckbox.Content ----
type CollapsibleCheckboxContentProps = {
  children: React.ReactNode
}
function CollapsibleCheckboxContent({ children }: CollapsibleCheckboxContentProps) {
  return <RCollapsible.Content>{children}</RCollapsible.Content>
}
// ----------------------------------

export {
  CollapsibleCheckboxRoot as Root,
  CollapsibleCheckboxCheckbox as Checkbox,
  CollapsibleCheckboxContent as Content,
}
