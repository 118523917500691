import React, { useEffect } from 'react'
import { gql } from 'graphql-tag'
import { useWatch, type Control } from 'react-hook-form'

import CriteriaAdapter from '../../adapters/CriteriaAdapter'

import useQuery from '@hooks/useQuery'

import { scopedTranslation } from '@utils/I18n'

import { Divider } from '@atoms'
import Tag from '@atoms/Tag'

import { type Criteria } from './CriteriaEdit'

const t = scopedTranslation('components')

type ruleAdapterType = Criteria['criteria']['rules']['nodes'] & {
  key: string
}
function ruleAdapter({ key, predicate, values }: ruleAdapterType) {
  return {
    property: key,
    predicate,
    values,
  }
}

type criteriaAdapterType = Criteria['criteria']
function criteriaAdapter(criteria: criteriaAdapterType) {
  const enabledRules = Object.entries(criteria.rules).filter(([_key, rule]) => rule.enabled)
  const rulesWithValues = enabledRules.filter(([_key, rule]) => rule.values.length > 0)

  return {
    combinator: criteria.combinator,
    rules: rulesWithValues.map(([key, rule]) => ruleAdapter({ key, ...rule })),
  }
}

type RuleComponentProps = { rule: ReturnType<typeof ruleAdapter> }
const TagRule = ({ rule }: RuleComponentProps) => {
  return (
    <p className="tw-grid tw-grid-cols-[max-content_1fr] tw-text-gray-900">
      <span className="tw-pt-[3px] tw-pr-1">
        <strong className="tw-font-bold">{t(`group_criteria.properties.${rule.property}`)}: </strong>
        <span>{t(`group_criteria.predicates.${rule.predicate}`)}&nbsp;</span>
      </span>
      <span className="tw-relative tw-top-[2px] tw-items-end tw-flex-wrap tw-gap-2">
        {rule.values.map((tag) => (
          <Tag key={tag.value} size="xs">
            {tag.value}
          </Tag>
        ))}
      </span>
    </p>
  )
}

const GenericRule = ({ rule }: RuleComponentProps) => {
  return (
    <p className="tw-grid tw-grid-cols-[max-content_1fr] tw-text-gray-900">
      <span>
        <strong className="tw-font-bold">{t(`group_criteria.properties.${rule.property}`)}: </strong>
        <span>{t(`group_criteria.predicates.${rule.predicate}`)}&nbsp;</span>
      </span>
      <span className="tw-flex tw-flex-wrap items-center tw-gap-1">
        {rule.values.map((value, index) => (
          <span key={value.value}>
            {value.value}
            {index !== rule.values.length - 1 && ','}
          </span>
        ))}
      </span>
    </p>
  )
}

const RuleComponent = ({ rule }: RuleComponentProps) => {
  switch (rule.property) {
    case 'tags':
    case 'exclusionTags':
      return <TagRule rule={rule} />
    default:
      return <GenericRule rule={rule} />
  }
}

type CriteriaPreviewProps = {
  control: Control<{ group: Criteria }>
  groupId?: string
}

function CriteriaPreview({ control, groupId }: CriteriaPreviewProps) {
  const INCLUDED_SUPPORTERS = gql`
    query SupportersForCriteria($criteria: CriteriaInput!, $groupId: ID) {
      supportersForCriteria(criteria: $criteria, groupId: $groupId) {
        totalCount
      }
    }
  `
  const groupValues = useWatch({ control, name: 'group' })

  type SupportersData = {
    supportersForCriteria: {
      totalCount: number
    }
  }

  const { data: { supportersForCriteria } = {}, refetch } = useQuery<SupportersData>({
    key: 'included_supporters',
    query: INCLUDED_SUPPORTERS,
    variables: { criteria: CriteriaAdapter.fromFrontend(groupValues.criteria).toServer(), groupId },
  })
  const totalCount = supportersForCriteria?.totalCount ?? 0

  useEffect(() => {
    void refetch()
  }, [groupValues])

  // v2.shared.group_criteria.combinator_explanation.and
  const { combinator, rules } = criteriaAdapter(groupValues.criteria)

  return (
    <div>
      <div className="tw-flex tw-items-center tw-gap-3 tw-text-gray-900 tw-text-lg tw-font-medium tw-py-4">
        {t('group_criteria.included_supporters_count')}
        <div className="tw-text-3xl tw-font-semibold tw-text-primary-700 tw-bg-primary-50 tw-p-2 tw-rounded">
          {totalCount}
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3">
        <p className="tw-font-semibold">{t('group_criteria.criteria')}</p>
        {rules.length === 0 ? (
          <p className="tw-flex tw-gap-1 flextw-mb-1 tw-text-gray-900">
            <span className="tw-font">{t('group_criteria.no_criteria')} </span>
            <span>({t('group_criteria.all_supporters_included')})</span>
          </p>
        ) : (
          <>
            <p className="tw-text-gray-600">
              ({t(`group_criteria.combinator_explanation.${combinator.toLowerCase()}`)})
            </p>
            <div className="tw-flex tw-flex-col tw-gap-3">
              {rules.map((rule) => (
                <RuleComponent key={rule.property} rule={rule} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="tw-py-9">
        <Divider />
      </div>
    </div>
  )
}

export default CriteriaPreview
