import React, { useState } from 'react'
import Icon from '@components/utils/Icon'
import { Button } from '@atoms'
import useMutation from '@hooks/useGQLMutation'
import { RemoveGroupLeadersDocument } from './mutations.generated'
import { removeGroupLeadersMutation } from './mutations'
import { type User } from '@frontend/graphql/types.generated'
import * as SuccessModal from '@molecules/SuccessModal'
import { useErrorsStore } from '@stores/errorsStore'
import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('group_edit.manage_leaders.remove_leaders')

export default function RemoveLeadersForm({ groupID, removeLeader, leaders }) {
  const [leadersToRemove, setLeadersToRemove] = useState<User[]>([])
  const [successfullyRemovedLeaders, setSuccessfullyRemovedLeaders] = useState<User[]>([])
  const [SuccessModalOpen, setSuccessModalOpen] = React.useState<boolean>(false)
  const { mutate: removeGroupLeadersMutate } =
    useMutation<typeof RemoveGroupLeadersDocument>(removeGroupLeadersMutation)

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const removeGroupLeaders = () => {
    clearAppErrors('manageLeaders')
    removeGroupLeadersMutate(
      { id: groupID, users: leadersToRemove.map((leader) => leader.id) },
      {
        onSuccess: (response) => {
          if (!response.removeGroupLeaders) return

          if (response.removeGroupLeaders.success) {
            setSuccessfullyRemovedLeaders(leadersToRemove)
            leadersToRemove.forEach((leader) => {
              removeLeader(leader)
            })
          }

          setSuccessModalOpen(true)
          setLeadersToRemove([])
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'manageLeaders',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  function updateLeadersToRemove(leader) {
    setLeadersToRemove((prev) => (prev.includes(leader) ? prev.filter((item) => item !== leader) : [...prev, leader]))
  }

  return (
    <section className="tw-py-2">
      <div className="tw-rounded-lg tw-overflow-hidden tw-border tw-border-gray-200 ">
        <div className="overflow-auto">
          <table className="tw-min-w-full tw-divide-y tw-divide-gray-200">
            <thead>
              <tr>
                <th colSpan={4} className="tw-px-6 tw-py-5 tw-text-xl tw-text-left tw-font-medium tw-text-gray-900">
                  {t('title')}
                </th>
              </tr>
              <tr className="tw-border-t tw-border-b tw-border-gray-20">
                <th className="tw-px-6 tw-py-3 tw-bg-gray-50 tw-text-sm tw-text-left tw-font-medium tw-text-gray-500">
                  {t('name')}
                </th>
                <th className="tw-px-6 tw-py-3 tw-bg-gray-50 tw-text-sm tw-text-left tw-font-medium tw-text-gray-500">
                  {t('email')}
                </th>
                <th
                  colSpan={2}
                  className="tw-px-6 tw-py-3 tw-bg-gray-50 tw-text-sm tw-text-left tw-font-medium tw-text-gray-500 tw-gap-1"
                >
                  {t('role')}
                </th>
              </tr>
            </thead>
            <tbody>
              {leaders.map((leader) => (
                <tr key={leader.id} className="tw-border-t tw-border-b tw-border-gray-20 even:tw-bg-gray-100 ">
                  <td className="tw-px-6 tw-text-gray-900 tw-py-4">{leader.fullName || t('no_name')}</td>
                  <td className="tw-px-6 tw-py-4">{leader.email}</td>
                  <td className="tw-px-6 tw-py-4">{leader.role}</td>
                  <td className="tw-px-6 tw-py-4 tw-flex tw-justify-center tw-min-w-[175px]">
                    <Button
                      ariaLabel={t(`aria_labels.${leadersToRemove.includes(leader) ? 'undo_removal' : 'remove'}`, {
                        email: leader.email,
                        role: leader.role,
                      })}
                      color={leadersToRemove.includes(leader) ? 'primary' : 'danger'}
                      onClick={() => {
                        updateLeadersToRemove(leader)
                      }}
                      rank={leadersToRemove.includes(leader) ? 'secondary' : 'primary'}
                    >
                      {t(`labels.${leadersToRemove.includes(leader) ? 'undo_removal' : 'remove'}`)}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="tw-py-6">
        <Button type="submit" size="md" trailingIcon="arrow-circle-broken-right" onClick={removeGroupLeaders}>
          {t('labels.submit_changes')}
        </Button>
      </div>

      <SuccessModal.Root open={SuccessModalOpen} setOpen={setSuccessModalOpen}>
        <SuccessModal.Header>{t('dialog.title')}</SuccessModal.Header>
        <ul className="tw-list-disc tw-flex tw-flex-col tw-items-center">
          {successfullyRemovedLeaders?.map((leader) => {
            return (
              <li key={leader.id} aria-label={leader.fullName || leader.email}>
                {leader.fullName || leader.email}
              </li>
            )
          })}
        </ul>
      </SuccessModal.Root>
    </section>
  )
}
