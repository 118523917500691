import React, { useEffect, useCallback } from 'react'
import { useFieldArray } from 'react-hook-form'

import { Button, Dialog } from '@atoms'
import Icon from '@components/utils/Icon'
import useFormWithErrors from '@hooks/useFormWithErrors'
import { scopedTranslation } from '@utils/I18n'

import { usesArrayPredicate, type BaseActiveFilter, type ActiveFilterWithSelectOption } from './stores/filterStoreSlice'
import FilterRow from './FilterRow'
import useSupporterFilteringStore from './stores/useSupporterFilteringStore'

const t = scopedTranslation('components.filter_dialog')
const tSharedButtons = scopedTranslation('shared.buttons')

type FilterRule = Partial<ActiveFilterWithSelectOption>

export type FilterFormValues = {
  rules?: FilterRule[]
}

const isCompleteRule = (rule: FilterRule): rule is ActiveFilterWithSelectOption => {
  return rule.property !== undefined && rule.predicate !== undefined && rule.values !== null
}

type FilterDialogProps = {
  open?: boolean
}

const convertToOptionsObject = (value: string) => ({ label: value, value })

const filterRuleAdapter = {
  toOptionsFilter: (filters: BaseActiveFilter[]): ActiveFilterWithSelectOption[] => {
    return filters.map((filter) => {
      if (usesArrayPredicate(filter)) {
        return {
          ...filter,
          values: filter.values.map(convertToOptionsObject),
        }
      } else {
        return filter
      }
    })
  },
  fromOptionsFilter: (filters: ActiveFilterWithSelectOption[]): BaseActiveFilter[] => {
    return filters.map((filter) => {
      const values = usesArrayPredicate(filter) ? filter.values.map(({ value }) => value) : filter.values
      return { ...filter, values }
    })
  },
}

const FilterDialog = ({ open: defaultOpen = false }: FilterDialogProps) => {
  const [open, setOpen] = React.useState(defaultOpen)

  const activeFilters = useSupporterFilteringStore((state) => state.filters)
  const formattedActiveFilters = filterRuleAdapter.toOptionsFilter(activeFilters)
  const replaceFilters = useSupporterFilteringStore((state) => state.replaceFilters)

  const { control, register, handleSubmit, reset } = useFormWithErrors<FilterFormValues>({
    mode: 'onTouched',
    defaultValues: {
      rules: formattedActiveFilters || [],
    },
  })

  const updateFormWithActiveFilters = useCallback(() => {
    reset({ rules: formattedActiveFilters || [] })
  }, [activeFilters])

  useEffect(() => {
    updateFormWithActiveFilters()
  }, [updateFormWithActiveFilters])

  const {
    fields: filterRules,
    update,
    append,
    remove,
  } = useFieldArray<FilterFormValues>({
    control,
    name: 'rules',
  })

  const onSubmit = async (values: FilterFormValues) => {
    const rules = values.rules ?? []
    const newFilters = filterRuleAdapter.fromOptionsFilter(rules.filter(isCompleteRule))

    replaceFilters(newFilters)
    setOpen(false)
  }

  const addFilterRule = () => {
    append({
      property: undefined,
      predicate: undefined,
      values: null,
    } as any)
  }

  const removeFilterRule = (index: number) => {
    remove(index)
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild={true}>
        <Button rank="secondary" leadingIcon="filter-lines">
          {t('add_filters')}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content size="3xl">
        <Dialog.Header>
          <Dialog.Title className="pb-2">{t('search_filters')}</Dialog.Title>
          <Dialog.Description className="tw-font-medium">{t('filter_matching_all_of')}:</Dialog.Description>
        </Dialog.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-flex tw-flex-col tw-gap-3">
            {filterRules?.map((field, index) => {
              return (
                <div
                  key={(field as any).id}
                  className="tw-flex tw-flex-row tw-space-x-2.5 tw-items-center tw-justify-stretch"
                >
                  <div className="tw-flex-1">
                    <FilterRow index={index} register={register} control={control} update={update} />
                  </div>
                  <div className="tw-flex-shrink">
                    <Button
                      size="md"
                      color="danger"
                      onClick={() => {
                        removeFilterRule(index)
                      }}
                    >
                      <Icon type="trash" height={18} width={18} />
                    </Button>
                  </div>
                </div>
              )
            })}

            <div className="tw-inline">
              <Button
                type="button"
                rank="secondary"
                size="sm"
                leadingIcon="plus"
                onClick={addFilterRule}
                ariaLabel={filterRules.length === 0 ? t('add_first_filter') : t('add_filter')}
              >
                {filterRules.length === 0 && t('add_first_filter')}
              </Button>
            </div>
          </div>
          <Dialog.Footer>
            <Dialog.Close asChild>
              <Button type="button" size="xl" rank="tertiary">
                {t('close_without_applying')}
              </Button>
            </Dialog.Close>
            <Button type="submit" size="xl" rank="primary">
              {tSharedButtons('apply')}
            </Button>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default FilterDialog
