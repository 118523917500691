import React from 'react'
import { useController } from 'react-hook-form'

import { Button } from '@atoms'
import TagsSelect from '@molecules/TagsSelect'

import { scopedTranslation } from '@utils/I18n'
const t = scopedTranslation('shared')

type EditableTagsFieldProps = {
  name: string
  label: string
  onSubmit: (data: any) => any
  onCancel: () => void
  control: any
}

const EditableTagsField = React.forwardRef<HTMLInputElement | null, EditableTagsFieldProps>(function EditableTagsField(
  { name, label, onCancel, onSubmit, control },
  _ref
) {
  const { field } = useController({ name, control })

  function cancel() {
    onCancel()
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <div className="tw-flex-1 tw-w-0">
          <TagsSelect field={field} hideLabel={true} />
        </div>
        <span className="tw-flex tw-gap-2">
          <Button
            size="sm"
            rank="primary"
            type="submit"
            onClick={onSubmit}
            ariaLabel={t('aria_labels.save_object', { name: label })}
          >
            {t('buttons.save', { name: label })}
          </Button>
          <Button
            size="sm"
            rank="primary"
            color="danger"
            onClick={cancel}
            ariaLabel={t('aria_labels.cancel_editing', { name: label })}
          >
            {t('buttons.cancel', { name: label })}
          </Button>
        </span>
      </div>
    </form>
  )
})
export default EditableTagsField
