import React from 'react'
import { LoadingSpinner } from '@atoms'

type LoadingWrapperProps = {
  loading: boolean
  children: React.ReactNode
}

const LoadingWrapper = ({ loading, children }: LoadingWrapperProps) => {
  return (
    <div className="tw-relative tw-group" data-loading={loading || null}>
      <div className="tw-absolute tw-z-50	tw-hidden group-data-[loading]:tw-flex inset-0 tw-w-full tw-h-full tw-p-4 tw-justify-center">
        <LoadingSpinner />
      </div>
      <div className="tw-transition-opacity group-data-[loading]:tw-opacity-30 group-data-[loading]:tw-select-none">
        {children}
      </div>
    </div>
  )
}

export default LoadingWrapper
