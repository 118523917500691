import React from 'react'
import { useWatch } from 'react-hook-form'

import PropertyField from './PropertyField'
import PredicateField from './PredicateField'
import ValueField from './ValueField'
import { type AvailableFilterPredicate } from './stores/filterStoreSlice'
import useSupporterFilteringStore from './stores/useSupporterFilteringStore'

type FilterFieldsProps = {
  index: number
  register: any
  control: any
  update: any
}

const FilterRow = ({ index, register, control, update }: FilterFieldsProps) => {
  const availableFilters = useSupporterFilteringStore((state) => state.availableFilters)
  const { property } = useWatch({ control, name: `rules.${index}` })
  const availablePredicates: AvailableFilterPredicate[] = property ? availableFilters[property].predicates : []

  return (
    <div className="tw-grid tw-grid-rows-1 tw-grid-cols-3 tw-gap-3">
      <div className="tw-col-span-1">
        <PropertyField index={index} filteringOptions={availableFilters} control={control} update={update} />
      </div>
      {availablePredicates.length > 0 && (
        <>
          <div className="tw-flex-1">
            <PredicateField filteringOptions={availableFilters} index={index} control={control} update={update} />
          </div>
          <div className="tw-flex-1 tw-h-full">
            <ValueField index={index} register={register} control={control} filteringOptions={availableFilters} />
          </div>
        </>
      )}
    </div>
  )
}

export default FilterRow
