import gql from 'graphql-tag'

export const getSupporterTagFilters = gql`
  query getGroupSupporter($supporterId: ID!, $groupId: ID!) {
    group(id: $groupId) {
      supporters(idEq: $supporterId) {
        edges {
          tagFilters {
            id
            name
          }
        }
      }
    }
  }
`
