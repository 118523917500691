import gql from 'graphql-tag'

export const updateSupporterMutation = gql`
  mutation updateSupporter(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $mobile: String
    $phone: String
    $address1: String
    $address2: String
    $city: String
    $zip: String
    $state: String
    $countryCode: String
  ) {
    updateSupporter(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        mobile: $mobile
        phone: $phone
        address1: $address1
        address2: $address2
        city: $city
        zip: $zip
        state: $state
        countryCode: $countryCode
      }
    ) {
      supporter {
        id
        firstName
        lastName
        fullName
        email
        primaryMobile
        primaryPhone
        address1
        address2
        city
        zip
        state
        countryCode
        country
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const updateSupporterTagsMutation = gql`
  mutation updateSupporterTags($id: ID!, $tags: [String!]!) {
    updateSupporterTags(input: { id: $id, tags: $tags }) {
      supporter {
        tags {
          nodes {
            name
          }
        }
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const removeSupporterFromGroupMutation = gql`
  mutation removeSupporterFromGroup($groupId: ID!, $supporterId: ID!) {
    removeSupporterFromGroup(input: { groupId: $groupId, supporterId: $supporterId }) {
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const updateSupporterEngagementLevelMutation = gql`
  mutation updateSupporterEngagementLevel($id: ID!, $engagementLevelId: String!) {
    updateSupporterEngagementLevel(input: { id: $id, engagementLevelId: $engagementLevelId }) {
      supporter {
        engagementLevel
      }
      success
      errors {
        code
        message
        field
      }
    }
  }
`

export const updateSupporterTagFiltersMutation = gql`
  mutation updateSupporterTagFilters($id: ID!, $tagFilterIds: [ID!]!, $groupId: ID!) {
    updateSupporterTagFilters(input: { id: $id, tagFilterIds: $tagFilterIds, groupId: $groupId }) {
      supporter {
        id
        tags {
          nodes {
            name
          }
        }
      }
      success
      errors {
        code
        message
        field
      }
    }
  }
`
