import React from 'react'
import Icon from '@components/utils/Icon'
import clsx from 'clsx'

type AlertProps = {
  type: 'error' | 'info' | 'warning'
  size?: 'sm' | 'md'
  title?: string
  children?: React.ReactNode
  className?: string
  onClose?: () => void
  'data-testid'?: string
}

const containerClassesByType = {
  info: 'tw-border tw-border-primary-300 tw-bg-primary-50/50 tw-text-primary-600',
  warning: 'tw-border tw-border-warning-300 tw-bg-warning-50/50 tw-text-warning-600',
  error: 'tw-border tw-border-error-300 tw-bg-error-50/50 tw-text-error-600',
} as const

const sizeClasses = {
  sm: 'tw-p-2',
  md: 'tw-p-4',
} as const

const titleClassesByType = {
  info: 'tw-text-primary-700',
  warning: 'tw-text-warning-700',
  error: 'tw-text-error-700',
} as const

const closeIconClassesByType = {
  info: 'tw-text-primary-500 hover:tw-text-primary-800',
  warning: 'tw-text-warning-500 hover:tw-text-warning-800',
  error: 'tw-text-error-500 hover:tw-text-error-800',
} as const

const iconTypes = {
  info: 'info-circle',
  warning: 'alert-triangle',
  error: 'alert-circle',
} as const

export default function Alert({ type, title, className, children, onClose, size = 'md', ...props }: AlertProps) {
  return (
    <div
      className={clsx(
        'tw-flex tw-items-start tw-gap-3 tw-rounded-lg',
        className,
        containerClassesByType[type],
        sizeClasses[size]
      )}
      {...props}
    >
      <span>
        <Icon width={20} height={20} type={iconTypes[type]} />
      </span>
      <span className="tw-flex tw-flex-col tw-gap-1">
        {title && <h3 className={clsx('tw-text-sm tw-font-medium', titleClassesByType[type])}>{title}</h3>}
        {children}
      </span>
      {typeof onClose === 'function' && (
        <button
          type="button"
          className={clsx('tw-cursor-pointer tw-p-1 tw-ml-auto', closeIconClassesByType[type])}
          onClick={onClose}
        >
          <Icon width={12} height={12} type="x-close" />
        </button>
      )}
    </div>
  )
}
