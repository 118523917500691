import React, { useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useMutation from '@hooks/useGQLMutation'
import useFormWithErrors from '@hooks/useFormWithErrors'

import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { scopedTranslation } from '@utils/I18n'

import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import { useErrorsStore } from '@stores/errorsStore'

import { Button } from '@atoms'
import Tag from '@atoms/Tag'

import type { UpdateSupporterTagFiltersDocument } from './mutations.generated'
import { updateSupporterTagFiltersMutation } from './mutations'
import useSupporterDetailsStore from './stores/useSupporterDetailsStore'
import EditableTagFiltersField from './EditableTagFiltersField'

const t = scopedTranslation('shared')
const tAttributes = scopedTranslation('attributes')
const tSupporterShow = scopedTranslation('supporter_show')

type TagsFieldRowType = {
  supporterId: string
  groupId: string
  canEdit?: boolean
  tagFilters: Array<{
    id: string
    name: string
  }>
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export default function TagFiltersForm({ groupId, supporterId, tagFilters, canEdit }: TagsFieldRowType) {
  canEdit = canEdit && tagFilters.length > 0

  const [open, setOpen] = React.useState(false)

  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const editButtonRef = React.useRef<HTMLButtonElement | null>(null)

  const validations = yup.object().shape({
    supporter: yup.object({
      tagFilters: yup
        .array()
        .of(
          yup.object({
            value: yup.string(),
            label: yup.string(),
          })
        )
        .label(tAttributes('supporter.tag_filters')),
    }),
  })

  const {
    tagFilters: value,
    setTagFilters: setValue,
    setTags,
  } = useSupporterDetailsStore((state) => ({
    tagFilters: state.tagFilters,
    setTags: state.setTags,
    setTagFilters: state.setTagFilters,
  }))

  const {
    setErrors: setFormErrors,
    register,
    handleSubmit,
    control,
    setValue: setFormValue,
  } = useFormWithErrors({
    resolver: yupResolver(validations),
    mode: 'onTouched',
    defaultValues: {
      supporter: {
        tagFilters: value ?? [],
      },
    },
  })

  useEffect(() => {
    setFormValue('supporter.tagFilters', value)
  }, [value, setFormValue])

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const onSuccess = (response, values) => {
    setOpen(false)
    setValue(values.supporter.tagFilters.filter(notEmpty))
    const tags = response.updateSupporterTagFilters?.supporter?.tags?.nodes ?? []
    setTags(tags.filter(notEmpty).map((tag) => ({ value: tag.name, label: tag.name })))
    editButtonRef.current?.focus()
  }

  const { mutate } = useMutation<typeof UpdateSupporterTagFiltersDocument>(updateSupporterTagFiltersMutation)

  const onSubmit = async (values) => {
    clearAppErrors('supporterTagFilters')

    mutate(
      {
        id: supporterId,
        groupId,
        tagFilterIds: values.supporter.tagFilters.map((tagFilter) => tagFilter.value),
      },
      {
        onSuccess: (response) => {
          if (response.updateSupporterTagFilters?.success) {
            onSuccess(response, values)
            clearAppErrors('supporterTagFilters')
          } else if (response.updateSupporterTagFilters) {
            setFormErrors({
              errorsBasePath: 'supporterTagFilters',
              errors: response.updateSupporterTagFilters.errors,
              adapter: serverErrorsToFormErrors,
            })
            setAppErrors({
              errorsPath: 'supporterTagFilters',
              errors: response.updateSupporterTagFilters.errors,
            })
          } else {
            setAppErrors({
              errorsPath: 'supporterTagFilters',
              errors: [
                {
                  message: '',
                  code: 'unknown',
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'supporterTagFilters',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  const cancel = () => {
    setOpen(false)
    setTimeout(() => {
      editButtonRef.current?.focus()
    })
    setFormValue('supporter.tagFilters', value ?? [])
  }

  const edit = () => {
    setOpen(true)
    setTimeout(() => {
      inputRef.current?.focus()
    })
  }

  return (
    <>
      <AppErrorsAlert errorsPath={'supporterTagFilters'} />

      <div
        data-testid="tag-filters-form"
        className="tw-grid tw-grid-cols-5 tw-grid-rows-1 tw-gap-4 tw-items-center tw-px-8 tw-py-5 tw-shadow-sm tw-rounded-xl tw-border tw-border-gray-200"
      >
        <div>
          <h3 className="tw-font-medium">{tAttributes('supporter.tag_filters')}</h3>
        </div>
        {open ? (
          <div className="tw-col-span-3">
            <EditableTagFiltersField
              {...register('supporter.tagFilters')}
              label={tAttributes('supporter.tag_filters')}
              tagFilters={tagFilters}
              onSubmit={handleSubmit(onSubmit)}
              onCancel={cancel}
              control={control}
            />
          </div>
        ) : (
          <div className="tw-col-span-3">
            <p className="tw-flex tw-gap-2 tw-flex-wrap">
              {value && value.length !== 0
                ? tagFilters
                    .filter((tagFilter) => value?.map((val) => val.value)?.includes(tagFilter.id))
                    .map((tag) => (
                      <Tag key={tag.id} size="sm">
                        {tag.name}
                      </Tag>
                    ))
                : tSupporterShow('no_tag_filters')}
            </p>
          </div>
        )}

        <div className="tw-col-start-5 tw-flex tw-justify-end tw-gap-2">
          <Button
            rank="secondary"
            leadingIcon="pencil-02"
            onClick={edit}
            ref={editButtonRef}
            disabled={canEdit ? open : true}
            ariaLabel={t('aria_labels.edit_object', { name: tAttributes('supporter.tag_filters') })}
            dataCompId="supporter-show_tags-field-row_edit"
          >
            {t('buttons.edit')}
          </Button>
        </div>
      </div>
    </>
  )
}
