import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import type { SupporterNote } from '@frontend/graphql/types.generated'
import { scopedTranslation } from '@utils/I18n'
import Icon, { type IconTypes } from '@components/utils/Icon'
import Alert from '@atoms/Alert'

dayjs.extend(relativeTime)

function IconBubble({ type }: { type: IconTypes }) {
  return (
    <div className="tw-w-10 tw-h-10 tw-text-primary-700 tw-bg-white tw-border tw-border-gray-200 tw-shadow-sm tw-flex tw-items-center tw-justify-center tw-rounded-full">
      <Icon type={type} width={20} height={20} />
    </div>
  )
}

const t = scopedTranslation('components.note_log_card')

type NoteLogCardProps = Pick<SupporterNote, 'id' | 'createdAt' | 'content' | 'author'>

export default function NoteLogCard({ createdAt, content, author }: NoteLogCardProps) {
  return (
    <div className="tw-flex tw-py-5 tw-px-8 tw-gap-8 tw-text-sm tw-text-gray-700" data-testid="note-log-card">
      <div className="tw-flex tw-flex-col">
        <IconBubble type="pencil-02" />
        <p className="tw-text-md tw-font-semibold tw-py-2 tw-whitespace-nowrap">{dayjs(createdAt).format('D MMM')}</p>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-3 tw-w-full">
        <div className="tw-flex tw-justify-between">
          {/* Person who authored the note */}
          {author ? (
            <p>
              <span className="tw-font-semibold">{t('note_by')}: </span>
              {author}
            </p>
          ) : (
            <Alert type="info" size="sm">
              {t('no_author')}
            </Alert>
          )}

          <span className="tw-text-xs">
            {dayjs(createdAt).format('DD MMM YYYY, HH:MM')} ({dayjs(createdAt).fromNow()})
          </span>
        </div>

        {content && <p>{content}</p>}
      </div>
    </div>
  )
}
