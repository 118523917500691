import React from 'react'

import { scopedTranslation } from '@utils/I18n'
import type { User } from '@frontend/graphql/types.generated'

const t = scopedTranslation('shared')

type LeaderNamesProps = {
  leaderType: 'captains' | 'vice-captains'
  leaders: Array<Pick<User, 'firstName' | 'lastName'>>
}

const LeaderNames = ({ leaderType, leaders }: LeaderNamesProps) => {
  const leadersWithNames = leaders.filter(({ firstName, lastName }) => firstName && lastName)
  const leadersFullNames = leadersWithNames.map(({ firstName, lastName }) => [firstName, lastName].join(' ').trim())

  const leaderNamesText = (names: string[]) => (names.length === 0 ? t('labels.none') : names.join(', '))

  return (
    <p className="tw-text-sm">
      <strong className="tw-text-xs tw-text-gray-blue-500 tw-uppercase tw-font-bold">
        {t(`labels.${leaderType}`)}:{' '}
      </strong>
      <span className="tw-text-gray-900">{leaderNamesText(leadersFullNames)}</span>
    </p>
  )
}

export default LeaderNames
