import gql from 'graphql-tag';

export const addDraftGroupCriteriaMutation = gql`
  mutation addDraftGroupCriteria($id: ID!, $criteria: CriteriasMutationInput!) {
    addDraftGroupCriteria(input: { id: $id, criteria: $criteria }) {
      draftGroup {
        id
        name
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`