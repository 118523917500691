import React from 'react'

import { Shift, Supporter } from '@frontend/graphql/types.generated'

import { scopedTranslation } from '@utils/I18n'
import { Button, Dialog } from '@atoms'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import useUnassignShiftMuation from './useUnassignShiftMutation'

const t = scopedTranslation('components.unassign_shift')

type UnassignShiftDialogProps = {
  shift: Pick<Shift, 'id'>
  supporter: Pick<Supporter, 'firstName'>
  onShiftUpdate: () => void
}

const UnassignShiftDialog = ({ shift, supporter, onShiftUpdate }: UnassignShiftDialogProps) => {
  const { unassignShift } = useUnassignShiftMuation()

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild={true}>
        <Button
          rank="link"
          leadingIcon="minus-circle"
          color="danger"
          ariaLabel={t('unassign', { name: supporter.firstName })}
        >
          {t('unassign', { name: supporter.firstName })}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <AppErrorsAlert errorsPath="unassignShift" />
        <Dialog.Header>
          <Dialog.Title>{t('unassign', { name: supporter.firstName })}</Dialog.Title>
        </Dialog.Header>
        <p>{t('confirmation', { name: supporter.firstName })}</p>
        <Dialog.Footer>
          <Dialog.Close>{t('cancel')}</Dialog.Close>
          <Button
            onClick={() => {
              unassignShift({ id: shift.id, onSuccess: onShiftUpdate })
            }}
            color="danger"
          >
            {t('unassign', { name: supporter.firstName })}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default UnassignShiftDialog
