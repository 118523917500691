import React, { useState } from 'react'
import { type Control, useController } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { type FieldPath } from 'react-hook-form/dist/types/path'

import type { UpdateSupporterDocument } from './mutations.generated'
import { updateSupporterMutation } from './mutations'

import useMutation from '@hooks/useGQLMutation'
import useFormWithErrors from '@hooks/useFormWithErrors'

import { useErrorsStore } from '@stores/errorsStore'

import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { Button, TextInput } from '@atoms'
import CountrySelect from '@atoms/form/CountrySelect'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import type { SharedInertiaProps } from '../../pages/SupporterShow'

import { scopedTranslation, i18nValidation } from '@utils/I18n'
const t = scopedTranslation('supporter_show.address_form')
const tShared = scopedTranslation('shared')
const tSupporterAttributes = scopedTranslation('attributes.supporter')

function requiredIfFilled(
  fields: any[],
  schema: yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''>,
  message: string
): yup.StringSchema {
  if (fields.some((field) => !!field)) {
    return schema.required(message)
  }
  return schema
}

const validationSchema = yup.object().shape({
  supporter: yup.object().shape({
    address1: yup
      .string()
      .when('address2', (fields, schema) =>
        requiredIfFilled(
          fields,
          schema,
          i18nValidation('required')({ label: tSupporterAttributes('address1'), path: 'attributes.supporter.address1' })
        )
      ),
    address2: yup.string(),
    city: yup
      .string()
      .when(['address1'], (fields, schema) =>
        requiredIfFilled(
          fields,
          schema,
          i18nValidation('required')({ label: tSupporterAttributes('city'), path: 'attributes.supporter.city' })
        )
      ),
    state: yup
      .string()
      .when(['city'], (fields, schema) =>
        requiredIfFilled(
          fields,
          schema,
          i18nValidation('required')({ label: tSupporterAttributes('state'), path: 'attributes.supporter.state' })
        )
      ),
    zip: yup.string(),
    countryCode: yup.string(),
  }),
})

type extractFieldValues<Type> = Type extends yup.ObjectSchema<infer T> ? T : never
type ValidationSchema = extractFieldValues<typeof validationSchema>

type AddressFormType = {
  supporterId: string | number
  address1: string
  address2: string
  city: string
  zip: string
  state: string
  countryCode: string
  country: string
}

type RowFieldType = {
  name: Exclude<FieldPath<ValidationSchema>, 'supporter'>
  label: string
  open: boolean
  value?: string
  children?: React.ReactNode
}

function RowField({ open, value, label, children, name }: RowFieldType) {
  return (
    <div
      className="tw-grid tw-grid-cols-5 tw-gap-4 tw-items-center tw-py-4 tw-h-28"
      data-compid={`supporter-show_row-field-${name}`}
    >
      <div>
        <p className="tw-text-gray-900 tw-font-medium tw-text-sm tw-mt-3">{label}</p>
      </div>

      <div className="tw-col-start-2 tw-col-span-2 tw-items-start tw-w-full">{open ? children : value}</div>
    </div>
  )
}

type RowInputFieldType = {
  defaultValue?: string
  open: boolean
  label: string
  name: Exclude<FieldPath<ValidationSchema>, 'supporter'>
  control: Control<ValidationSchema>
  value?: string
  children?: React.ReactNode
  onChange?: (e: any) => void
}

function RowInputField({
  defaultValue = '',
  open,
  label,
  value,
  name,
  control,
  onChange: propsOnChange,
}: RowInputFieldType) {
  const {
    field: { onChange: fieldOnChange, ...field },
    formState: { errors },
  } = useController({
    name,
    defaultValue,
    control,
  })

  const onChange = (e) => {
    propsOnChange && propsOnChange(e)
    fieldOnChange(e)
  }

  return (
    <RowField open={open} label={label} name={name} value={value}>
      <TextInput displayName={label} onChange={onChange} errors={errors} hideLabel={true} {...field} />
    </RowField>
  )
}

type EditButtonsType = {
  setOpen: (open: boolean) => void
  isValid: boolean
}
function EditButtons({ setOpen, isValid }: EditButtonsType) {
  return (
    <div className="tw-flex tw-gap-2">
      <Button type="submit" disabled={!isValid} ariaLabel={t('aria_labels.save_changes')}>
        {t('buttons.save_changes')}
      </Button>
      <Button
        color="danger"
        onClick={() => {
          setOpen(false)
        }}
        ariaLabel={t('aria_labels.cancel_changes')}
      >
        {t('buttons.cancel_changes')}
      </Button>
    </div>
  )
}

export default function AddressForm({
  supporterId,
  address1,
  address2,
  city,
  zip,
  state,
  countryCode,
  country,
  permissions,
}: AddressFormType & SharedInertiaProps) {
  const [open, setOpen] = useState(false)
  const [displayValues, setDisplayValues] = useState<Omit<AddressFormType, 'supporterId'>>({
    address1,
    address2,
    city,
    zip,
    state,
    countryCode,
    country,
  })

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const {
    trigger,
    control,
    handleSubmit,
    setErrors: setFormErrors,
    formState: { isValid },
  } = useFormWithErrors({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })

  const onChange = () => {
    trigger().catch(() => {})
  }

  const { mutate } = useMutation<typeof UpdateSupporterDocument>(updateSupporterMutation)

  const onSubmit = async (values) => {
    mutate(
      { id: supporterId, ...values.supporter },
      {
        onSuccess: (response) => {
          if (response.updateSupporter?.success) {
            setDisplayValues({
              address1: response.updateSupporter.supporter?.address1 ?? '',
              address2: response.updateSupporter.supporter?.address2 ?? '',
              city: response.updateSupporter.supporter?.city ?? '',
              zip: response.updateSupporter.supporter?.zip ?? '',
              state: response.updateSupporter.supporter?.state ?? '',
              countryCode: response.updateSupporter.supporter?.countryCode ?? '',
              country: response.updateSupporter.supporter?.country ?? '',
            })
            setOpen(false)
            clearAppErrors('supporterAddress')
          } else if (response.updateSupporter) {
            setFormErrors({
              errorsBasePath: 'supporter',
              errors: response.updateSupporter.errors,
              adapter: serverErrorsToFormErrors,
            })
            setAppErrors({
              errorsPath: 'supporterAddress',
              errors: response.updateSupporter.errors,
            })
          } else {
            setAppErrors({
              errorsPath: 'supporterAddress',
              errors: [
                {
                  message: '',
                  code: 'unknown',
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'supporterAddress',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  const disableAddressEditing = !permissions.updateAddress

  return (
    <>
      <AppErrorsAlert errorsPath="supporterAddress" />

      <form onSubmit={handleSubmit(onSubmit)} data-testid="address-form" data-compid="supporter-show_address-form">
        <section className="tw-flex tw-flex-col tw-px-8 tw-py-8 tw-gap-4 tw-shadow-sm tw-rounded-xl tw-border tw-border-gray-200">
          <header className="tw-flex tw-items-center tw-pb-5 tw-border-b tw-border-gray-200">
            <h3 className="tw-font-medium">{t('title')}</h3>
            <div className="tw-flex tw-items tw-justify-end tw-w-full">
              {open ? (
                <EditButtons setOpen={setOpen} isValid={isValid} />
              ) : (
                <Button
                  rank="secondary"
                  leadingIcon="pencil-02"
                  onClick={() => {
                    setOpen(true)
                  }}
                  ariaLabel={tShared('aria_labels.edit_object', { name: t('title') })}
                  disabled={disableAddressEditing}
                >
                  {tShared('buttons.edit')}
                </Button>
              )}
            </div>
          </header>
          <main className="tw-flex tw-flex-col [&>*]:tw-border-b [&>*]:tw-border-gray-200">
            <RowInputField
              label={tSupporterAttributes('address2')}
              value={displayValues.address2}
              name={'supporter.address2'}
              open={open}
              control={control}
              onChange={onChange}
            />
            <RowInputField
              label={tSupporterAttributes('address1')}
              value={displayValues.address1}
              name={'supporter.address1'}
              open={open}
              control={control}
              onChange={onChange}
            />
            <RowInputField
              label={tSupporterAttributes('city')}
              value={displayValues.city}
              name={'supporter.city'}
              open={open}
              control={control}
              onChange={onChange}
            />
            <RowInputField
              label={tSupporterAttributes('state')}
              value={displayValues.state}
              name={'supporter.state'}
              open={open}
              control={control}
              defaultValue={state}
              onChange={onChange}
            />
            <RowInputField
              label={tSupporterAttributes('zip')}
              value={displayValues.zip}
              name={'supporter.zip'}
              open={open}
              control={control}
              onChange={onChange}
              defaultValue={zip}
            />
            <RowField
              open={open}
              value={displayValues.country}
              name="supporter.countryCode"
              label={tSupporterAttributes('country')}
            >
              <CountrySelect
                rank="secondary"
                name={'supporter.countryCode'}
                label={tSupporterAttributes('country')}
                control={control}
                defaultValue={countryCode}
              />
            </RowField>
          </main>
        </section>
      </form>
    </>
  )
}
