import { type User } from '@frontend/graphql/types.generated'
import { create } from 'zustand'

export type UserType = Pick<User, 'id' | 'email' | 'fullName'> & {
  role: string
}

type StoreState = {
  leaders: UserType[]
}

type StoreActions = {
  removeLeader: (leader: UserType) => void
  addLeader: (...leader: UserType[]) => void
  setState: (state: Partial<StoreState>) => void
}

const useLeadersStore = create<StoreState & StoreActions>((set) => ({
  leaders: [],
  addLeader: (newLeader: UserType) => {
    set(({ leaders }) => ({
      leaders: [...leaders, newLeader]
    }))
  },
  removeLeader: (removedLeader: UserType) => {
    set(({ leaders }) => ({
      leaders:  leaders.filter((l) => l.email !== removedLeader.email)
    }))
  },
  setState: (initialState) => {
    set(() => initialState)
  },
}))

export default useLeadersStore