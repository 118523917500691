import type React from 'react'
import { router } from '@inertiajs/react'

const ensureMinWaitTime = async (startTime: number, minWaitTime = 1000) => {
  const timeElapsed = Date.now() - startTime
  const remainingDelay = Math.max(0, minWaitTime - timeElapsed)

  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true)
    }, remainingDelay)
  })
}

export default function usePartialReload(
  queryString: string,
  partialReloadKeys: string[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) {
  const urlPath = window.location.pathname
  const url = queryString ? `${urlPath}?${queryString}` : urlPath
  const startTime = Date.now()

  // Work around for an issue with using partial reload inside of a useEffect
  // Inertia was loading once with undefined for a page name, breaking the render
  // https://github.com/inertiajs/inertia/issues/1547
  setTimeout(() => {
    router.get(
      url,
      {},
      {
        preserveState: true,
        preserveScroll: true,
        only: partialReloadKeys,
        onStart: () => {
          setLoading(true)
        },
        onFinish: async () => {
          await ensureMinWaitTime(startTime)
          setLoading(false)
        },
      }
    )
  }, 0)
}
