import React from 'react'
import { clsx } from 'clsx'
import { ButtonStyleProps } from '@atoms/ButtonStyles'

const colorClasses = {
  gray: 'tw-bg-gray-50 tw-text-black',
  success: 'tw-bg-success-50 tw-text-success-700',
}

const sizeClasses = {
  md: 'tw-font-medium tw-text-xs tw-px-2 tw-py-1',
}

type BadgeProps = {
  size?: keyof typeof sizeClasses
  color?: keyof typeof colorClasses
  children: string
  'data-testid'?: string
}

export default function Badge({ children, color = 'gray', size = 'md', ...props }: BadgeProps) {
  return (
    <span {...props} className={clsx('tw-rounded-full', sizeClasses[size], colorClasses[color])}>
      {children}
    </span>
  )
}
