import React from 'react';
import {SelectDropdown} from '@atoms/form'
import {scopedTranslation} from "@utils/I18n";
import {useController} from "react-hook-form";
import gql from "graphql-tag";
import useQuery from '@hooks/useQuery'

const tSupporterShow = scopedTranslation('supporter_show')

type EngagementLevelsData = {
  engagementLevels: {
    nodes: [{
      id: string
      name: string
    }]
  }
}

export default function EngagementLevelSelect({control}) {
  const AVAILABLE_ENGAGEMENT_LEVELS = gql`
    query getEngagementLevels {
        engagementLevels(isActive: true) {
          nodes {
            id
            name
          }
        }
    }
  `

  const response = useQuery<EngagementLevelsData>(
    {
      key: 'engagementLevels',
      query: AVAILABLE_ENGAGEMENT_LEVELS,
      variables: {}
    }
  )

  const engagementLevels = response.data?.engagementLevels
  const engagementLevelsOptions = engagementLevels?.nodes ?? [];

  const { field } = useController({
    name: 'supporter.engagementLevel',
    defaultValue: '0',
    control,
  })

  return (
    <>
      <label
        htmlFor={'engagement_level'}
        className={'tw-mb-0 tw-pb-1 tw-text-gray-700 tw-font-medium'}
      >
        {tSupporterShow('engagement_level')}
      </label>
      <SelectDropdown.Root
        name="engagement_level"
        defaultValue={field.value}
        onValueChange={(value) => {
          field.onChange(value)
        }}
      >
        <SelectDropdown.Trigger aria-label={tSupporterShow('engagement_level')}>
          <SelectDropdown.Value placeholder={tSupporterShow('no_engagement_level')} />
        </SelectDropdown.Trigger>
        <SelectDropdown.Content id="engagement_level">
          <SelectDropdown.Item value="0">{tSupporterShow('no_engagement_level')}</SelectDropdown.Item>
          {engagementLevelsOptions.map((level) => (
            <SelectDropdown.Item key={level.id} value={level.id}>
              {level.name}
            </SelectDropdown.Item>
          ))}
        </SelectDropdown.Content>
      </SelectDropdown.Root>
    </>
  )
}