import useSlots, { Slot } from '@hooks/useSlots'
import React from 'react'
import Badge from '@atoms/Badge'
import { scopedTranslation } from '@utils/I18n'
const t = scopedTranslation('components.group_card')

type StatsCardType = {
  children: React.ReactNode
  'data-testid'?: string
  dataCompId?: string
}
function StatsCard({ children, dataCompId, ...props }: StatsCardType) {
  const slots = useSlots(children)

  return (
    <span
      {...props}
      className="tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-p-6 tw-shadow-sm tw-rounded-lg tw-border tw-border-gray-200"
      {...(dataCompId && { 'data-compid': dataCompId })}
    >
      <div className="tw-flex tw-gap-2">{slots.header}</div>
      <div className="tw-flex tw-gap-4 tw-text-gray-600 tw-font-semibold tw-text-sm">{slots.content}</div>
    </span>
  )
}

export function SupportersStats({ total, last30Days }) {
  const color: 'gray' | 'success' = last30Days < 0 ? 'gray' : 'success'
  const sign = last30Days < 0 ? '-' : '+'

  return (
    <StatsCard dataCompId={'supporters-stats'} data-testid="stats-supporters">
      <Slot name="header">
        <span className="tw-flex tw-items-center tw-gap-1 tw-text-gray-blue-500 tw-text-md tw-font-bold">
          {t('group_supporter_stats.title')}
        </span>
        {last30Days !== 0 && (
          <Badge data-testid="change-over-30-days" color={color}>
            {t('group_supporter_stats.badge', { count: last30Days, sign })}
          </Badge>
        )}
      </Slot>

      <span>
        <div>{t('group_supporter_stats.total')}</div>
        <div data-testid="supporters-count" className="tw-text-gray-900 tw-text-2xl tw-font-semibold">
          {total}
        </div>
      </span>
    </StatsCard>
  )
}

export function ContactStats({ emails, sms, calls }) {
  return (
    <StatsCard dataCompId={'contacts-stats'} data-testid="stats-contacts">
      <Slot name="header">
        <span className="tw-flex tw-items-center tw-gap-1 tw-text-gray-blue-500 tw-text-md tw-font-bold">
          {t('group_contacts_stats.title')}
          <span className="tw-text-sm tw-font-medium">{t('group_contacts_stats.description')}</span>
        </span>
      </Slot>

      <span>
        <div>{t('group_contacts_stats.emails_title')}</div>
        <div data-testid="emails" className="tw-text-gray-900 tw-text-2xl tw-font-semibold">
          {emails}
        </div>
      </span>
      <span>
        <div>{t('group_contacts_stats.sms_title')}</div>
        <div data-testid="sms" className="tw-text-gray-900 tw-text-2xl tw-font-semibold">
          {sms}
        </div>
      </span>
      <span>
        <div>{t('group_contacts_stats.calls_title')}</div>
        <div data-testid="calls" className="tw-text-gray-900 tw-text-2xl tw-font-semibold">
          {calls}
        </div>
      </span>
    </StatsCard>
  )
}
