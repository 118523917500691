import React from 'react'
import StepIcon from '@atoms/StepIcon'
import clsx from 'clsx'

type StepsProps = {
  steps: string[]
  currentStep: number
}

export default function Steps({ steps, currentStep }: StepsProps) {
  return (
    <div className="tw-flex tw-justify-center" data-testid="steps">
      {/* Screen Reader Announcement */}
      <span className="tw-sr-only" role="alert">
        Step {currentStep + 1} of {steps.length} - {steps[currentStep]}
      </span>

      {/* Progress Bar */}
      <div className="tw-relative tw-h-full tw-w-full md:tw-w-2/3">
        <span className="tw-absolute tw-flex tw-items-center tw-w-full tw-px-4 tw-top-[0.50rem] tw-py-3">
          {[...Array(steps.length - 1)].map((_, index) => (
            <span
              data-testid={`steps-progress-bar-${index}`}
              className={clsx('tw-h-0.5 tw-w-full', index < currentStep ? 'tw-bg-primary-700' : 'tw-bg-gray-200')}
              key={index}
            ></span>
          ))}
        </span>

        {/* Step Icons */}
        <ul className="tw-flex tw-items-center tw-justify-between tw-w-full">
          {steps.map((step, index) => (
            <li key={step} data-testid={`steps-${index}`} aria-current={index === currentStep}>
              <StepIcon
                status={index < currentStep ? 'complete' : index === currentStep ? 'current' : 'incomplete'}
                label={step}
                key={step}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
