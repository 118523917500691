import usePartialReload from '@hooks/usePartialReload'

type useSearchParamsProps<QueryData> = {
  queryData: QueryData
  partialReloadKeys: string[]
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

type QueryItem = string | string[] | object | QueryItem[]
type QueryDataShape = {
  [key: string]: QueryItem | QueryDataShape | QueryDataShape[]
}

const isArrayOfStrings = (maybeArray: unknown): maybeArray is string[] => {
  if (!Array.isArray(maybeArray)) return false
  return maybeArray.every((val) => typeof val === 'string')
}

const buildParamsTuple = (keys: string[], value: string): [string, string] => {
  const [prefix, ...remainingKeys] = keys
  const suffix = remainingKeys.map((key) => `[${key}]`).join('')
  return [`${prefix}${suffix}`, value]
}

export const buildSearchParamsArray = (value: QueryDataShape | QueryItem, nestedKeys: string[] = []) => {
  if (typeof value === 'string' || typeof value === 'boolean') {
    return [buildParamsTuple(nestedKeys, value)]
  }

  if (isArrayOfStrings(value)) {
    return value.map((val) => buildParamsTuple([...nestedKeys, ''], val))
  }

  if (Array.isArray(value)) {
    return value.flatMap((item, index: number) => buildSearchParamsArray(item, [...nestedKeys, `${index}`]))
  }

  return Object.entries(value).flatMap(([key, val]) => {
    return buildSearchParamsArray(val, [...nestedKeys, key])
  })
}

const buildQueryString = <QueryData extends QueryDataShape>(queryData: QueryData): any => {
  const searchParams = new URLSearchParams(buildSearchParamsArray(queryData))
  return searchParams.toString()
}

export const useSearchParams = <QueryData extends QueryDataShape>({
  queryData,
  partialReloadKeys,
  setLoading,
}: useSearchParamsProps<QueryData>) => {
  const queryString = buildQueryString<QueryData>(queryData)
  usePartialReload(queryString, partialReloadKeys, setLoading)
}
