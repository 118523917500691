import React from 'react'

import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { Button } from '@atoms'
import { Card, Columns2 } from '@layouts'
import DisplayCaptains from 'app/frontend/contexts/supporters-management/features/manage-leaders/DisplayCaptains'

import { type CaptainsStoreType } from 'app/frontend/contexts/supporters-management/features/manage-leaders/hooks/useCaptainsStore'
import AddCaptain, {
  validationSchema,
} from 'app/frontend/contexts/supporters-management/features/manage-leaders/AddCaptain'

import { scopedTranslation } from '@utils/I18n'

const groupCreateT = scopedTranslation('group_create')
const groupT = scopedTranslation('group_edit.invite_captains')

type ManageNewCaptainsProps = Pick<CaptainsStoreType, 'addCaptain' | 'captains' | 'removeCaptain'> & {
  addGroupLeaders: () => void
}

export default function ManageNewCaptains({
  captains,
  addCaptain,
  removeCaptain,
  addGroupLeaders,
}: ManageNewCaptainsProps) {
  const { ...formMethods } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })

  const addCaptainAndResetForm = (captain) => {
    addCaptain(captain)
    formMethods.reset()
  }

  return (
    <div>
      <Columns2>
        <Card
          dataCompId={'invite-captains_card'}
          className="tw-p-4"
          title={groupCreateT('manage_captains_form.title')}
          description={groupCreateT('manage_captains_form.description')}
        >
          <FormProvider {...formMethods}>
            <AddCaptain addCaptain={addCaptainAndResetForm} />
          </FormProvider>
        </Card>
        <Card
          dataCompId={'invite-captains_preview-of-captains-to-invite-card'}
          title={groupCreateT('manage_captains_form.preview_title')}
          backgroundFill={true}
        >
          <DisplayCaptains captains={captains} removeCaptain={removeCaptain} />
        </Card>
      </Columns2>

      <div className="tw-flex tw-justify-between tw-w-full tw-pt-4 tw-px-4">
        <div></div>
        <Button
          dataCompId={'invite-captains_form-submit'}
          type="submit"
          size="md"
          trailingIcon="arrow-circle-broken-right"
          onClick={addGroupLeaders}
        >
          {groupT('submit')}
        </Button>
      </div>
    </div>
  )
}
