import type React from 'react'

import { useSearchParams } from '@frontend/hooks/useSearchParams'
import { BaseActiveFilter } from '../../filter-supporters/stores/filterStoreSlice'

type FilterName = `${string}.${string}`
type FilterQuery = Record<FilterName, string | string[] | boolean>
type FilterQueryData = {
  filters: FilterQuery[]
}

// TODO: Do we need to snake_case the properties and predicates?
export const activeFiltersAdapter = (filters: BaseActiveFilter[] = []): FilterQueryData => {
  const adaptedFilters = filters.reduce<FilterQuery[]>((acc, { property, predicate, values }) => {
    const paramKey: FilterName = `${property}.${predicate}` as const
    const filterQuery: FilterQuery = { [paramKey]: values ?? '' }

    return [...acc, filterQuery]
  }, [])

  return {
    filters: adaptedFilters,
  }
}

type SortQueryData =
  | Record<string, unknown>
  | {
      sort_by: string
    }

export const sortAdapter = (sort?: string): SortQueryData => {
  if (!sort) return {}

  return {
    sort_by: sort,
  }
}

type QueryData = {
  filters?: FilterQuery[]
  sort_by?: string
  supporter_name?: string
}

type UseSupportersSearchProps = {
  activeFilters: Array<BaseActiveFilter>
  currentSort?: string
  supporterName?: string
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

function useSupportersSearch({ activeFilters, currentSort, supporterName, setLoading }: UseSupportersSearchProps) {
  const filtersQuery = activeFiltersAdapter(activeFilters)
  const sortQuery = sortAdapter(currentSort)
  const nameQuery = supporterName ? { supporter_name: supporterName } : {}

  const queryData: QueryData = {
    ...filtersQuery,
    ...sortQuery,
    ...nameQuery,
  }

  useSearchParams<QueryData>({ queryData, partialReloadKeys: ['supporters', 'activeSearch'], setLoading })
}

export default useSupportersSearch
