import gql from 'graphql-tag'

export const unassignShiftMutation = gql`
  mutation unassignShift($input: UnassignShiftInput!) {
    unassignShift(input: $input) {
      shift {
        id
        supporter {
          id
        }
      }
      success
      errors {
        field
        message
        code
      }
    }
  }
`
