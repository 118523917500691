import React from 'react'
import Button, { type ButtonProps } from '@atoms/Button'
import { redirectBackOrTo } from '@utils/redirectBack'

type BackLinkProps = {
  to: string
  children: string
  redirectBackFn?: (to: string) => (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
} & Pick<ButtonProps, 'trailingIcon' | 'leadingIcon' | 'rank' | 'color' | 'size'>

export default function BackLink({ children, to, redirectBackFn, ...props }: BackLinkProps) {
  const redirectFunction = redirectBackFn ?? redirectBackOrTo

  return (
    <Button role="link" onClick={redirectFunction(to)} {...props}>
      {children}
    </Button>
  )
}
