import { Button, Dialog } from '@atoms'
import React from 'react'
import { scopedTranslation } from '@utils/I18n'
import { router } from '@inertiajs/react'
import { useErrorsStore } from '@stores/errorsStore'
import useGQLMutation from '@hooks/useGQLMutation'
import { archiveGroupMutation } from '@frontend/contexts/supporters-management/features/supporters/mutations'
import { GroupsShowType } from '@frontend/contexts/supporters-management/pages/GroupShow'

const tSharedButtons = scopedTranslation('shared.buttons')
const t = scopedTranslation('components')

type GroupDeleteDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
} & Pick<GroupsShowType, 'group'>

export default function GroupDeleteDialog({ open, onOpenChange, group }: GroupDeleteDialogProps) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const { mutate } = useGQLMutation(archiveGroupMutation)
  const name = group.name
  const groupId = group.id

  function archiveGroup() {
    clearAppErrors('showGroupCardErrors')
    mutate(
      { groupId: groupId },
      {
        onSuccess: (response) => {
          if (!response.archiveGroup) return

          if (response.archiveGroup.success) {
            router.get(`/groups`)
          } else {
            setAppErrors({
              errorsPath: 'showGroupCardErrors',
              errors: response.archiveGroup.errors,
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'showGroupCardErrors',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content size="lg">
        <Dialog.Header>
          <Dialog.Title>{t('group_card.confirmations.archive_group_title')}</Dialog.Title>
          <Dialog.Description>{t('group_card.confirmations.archive_group_description')}</Dialog.Description>
        </Dialog.Header>

        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button type="button" size="xl" rank="secondary">
              {tSharedButtons('cancel')}
            </Button>
          </Dialog.Close>
          <Button
            type="button"
            size="xl"
            color="danger"
            leadingIcon="trash-01"
            ariaLabel={t('group_card.aria_labels.archive_group_confirmation', { name })}
            onClick={() => {
              onOpenChange(false)
              archiveGroup()
            }}
          >
            {tSharedButtons('delete')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
