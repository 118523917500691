import gql from 'graphql-tag'

export const createDraftGroupMutation = gql`
  mutation createDraftGroup(
    $name: String!
    $description: String
    $publicPublish: Boolean
    $publicDescription: String
    $hasPhysicalAddress: Boolean
    $streetAddress: String
    $venueName: String
    $city: String
    $state: String
    $postcode: String
    $country: String
    $notifyAllAdmins: Boolean
    $pointPeople: [ID!]
  ) {
    createDraftGroup(
      input: {
        name: $name
        description: $description
        publicPublish: $publicPublish
        publicDescription: $publicDescription
        hasPhysicalAddress: $hasPhysicalAddress
        streetAddress: $streetAddress
        venueName: $venueName
        city: $city
        state: $state
        postcode: $postcode
        country: $country
        notifyAllAdmins: $notifyAllAdmins
        pointPeople: $pointPeople
      }
    ) {
      draftGroup {
        id
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const updateGroupMutation = gql`
  mutation updateGroup(
    $id: ID!
    $name: String
    $description: String
    $publicPublish: Boolean
    $publicDescription: String
    $hasPhysicalAddress: Boolean
    $streetAddress: String
    $venueName: String
    $city: String
    $state: String
    $postcode: String
    $country: String
    $mailerSender: PartEmail
    $notifyAllAdmins: Boolean
    $pointPeople: [ID!]
  ) {
    updateGroup(
      input: {
        id: $id
        name: $name
        description: $description
        publicPublish: $publicPublish
        publicDescription: $publicDescription
        hasPhysicalAddress: $hasPhysicalAddress
        streetAddress: $streetAddress
        venueName: $venueName
        city: $city
        state: $state
        postcode: $postcode
        country: $country
        mailerSender: $mailerSender
        notifyAllAdmins: $notifyAllAdmins
        pointPeople: $pointPeople
      }
    ) {
      group {
        id
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
