import React from 'react'

import { SelectDropdown } from '@atoms/form'
// import usePartialReload from '@hooks/usePartialReload'
import Icon from '@components/utils/Icon'
import { scopedTranslation } from '@utils/I18n'

const tShared = scopedTranslation('shared')

type SortOption = {
  value: string
  label: string
}

// export const sortFn = (
//   value: string,
//   partialReloadKeys: string[],
//   setLoading: React.Dispatch<React.SetStateAction<boolean>>
// ) => {
//   const queryString = `sort_by=${value}`
//   usePartialReload(queryString, partialReloadKeys, setLoading)
// }

type SortByProps = {
  sortOptions: SortOption[]
  onChange: (value: string) => void
  currentSort?: string
}

function SortBy({ sortOptions, onChange, currentSort }: SortByProps) {
  const findSelectedOption = (value) => {
    return sortOptions.find((option) => option.value === value)
  }

  const selectedOption = findSelectedOption(currentSort)

  const iconType = selectedOption?.value.endsWith('asc') ? ('sort-ascending' as const) : ('sort-descending' as const)
  const iconAndText = (label: string) => (
    <span className="tw-flex tw-flex-row tw-flex-nowrap tw-items-center tw-justify-center tw-gap-1.5 tw-pr-1">
      <Icon width={20} height={20} type={iconType} />
      <span>{label}</span>
    </span>
  )

  return (
    <SelectDropdown.Root
      name="sort_by"
      value={currentSort}
      onValueChange={(value) => {
        onChange(value)
      }}
    >
      <SelectDropdown.Trigger aria-label={tShared('labels.sort_by')}>
        <SelectDropdown.Value placeholder={iconAndText(tShared('labels.sort_by'))}>
          {selectedOption && iconAndText(selectedOption.label)}
        </SelectDropdown.Value>
      </SelectDropdown.Trigger>
      <SelectDropdown.Content>
        {sortOptions.map((option) => (
          <SelectDropdown.Item key={option.value} value={option.value}>
            {option.label}
          </SelectDropdown.Item>
        ))}
      </SelectDropdown.Content>
    </SelectDropdown.Root>
  )
}

export default SortBy
