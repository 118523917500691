import { useState } from 'react'
import { format } from 'date-fns'
import Icon from '@components/utils/Icon'

import clsx from 'clsx'

import { Popover, PopoverContent, PopoverTrigger } from '@atoms/Popover'
import { Calendar } from '@atoms/Calendar'
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import { scopedTranslation } from '@frontend/utils/I18n'

type DatePickerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  field: ControllerRenderProps<TFieldValues, TName>
}

const tDatePicker = scopedTranslation('components.date_picker')

export function DatePicker({ field }: DatePickerProps) {
  const date = field.value ? new Date(field.value) : undefined
  const [isOpen, setIsOpen] = useState(false)

  const handleDayPickerSelect = (selectedDate: Date | undefined) => {
    if (!selectedDate) {
      field.onChange(null)
    } else {
      field.onChange(format(selectedDate, 'yyyy-MM-dd'))
      setIsOpen(false)
    }
  }

  return (
    <Popover modal={true} open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <button
          className={clsx(
            'tw-border tw-rounded tw-w-full tw-border-gray-400 focus-within:tw-border-primary-400 focus-within:tw-ring-4 tw-ring-primary-100'
          )}
        >
          <span className={clsx('tw-px-3 tw-py-2 tw-flex', date ? 'text-gray-500' : '')}>
            <Icon type="mail-01" className={clsx('mr-2 h-4 w-4')} />
            {date ? format(date, 'dd/MM/yyyy') : <span>{tDatePicker('placeholder')}</span>}
          </span>
        </button>
      </PopoverTrigger>
      <PopoverContent>
        <Calendar
          mode="single"
          today={new Date()}
          defaultMonth={date}
          selected={date}
          onSelect={handleDayPickerSelect}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
