import React from 'react'
import Alert from '@atoms/Alert'
import { scopedTranslation } from '../../../utils/I18n'
const t = scopedTranslation('shared.errors.401')

type Alert401Props = {
  onClose: () => void
}

export default function Alert401({ onClose }: Alert401Props) {
  return (
    <Alert data-testid="alert-401" onClose={onClose} type="error" title={t('title')}>
      {t('description')}
    </Alert>
  )
}
