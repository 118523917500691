import * as React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'

import cn from '@components/utils/cn'
import Icon from '@components/utils/Icon'

const Root: typeof DialogPrimitive.Root = DialogPrimitive.Root

const Trigger: typeof DialogPrimitive.Trigger = DialogPrimitive.Trigger

const Portal: typeof DialogPrimitive.Portal = ({ children, ...props }) => (
  <DialogPrimitive.Portal {...props}>
    {/*
      The `tailwind` class applies the Tailwind reset to elements
      within the Portal (e.g. to the close button). Otherwise the
      default browser styles are applied given the portal appears
      at the root of the document (near the closing </body> tag),
      rather than displaying within the main root #app element
    */}
    <div className="tailwind">{children}</div>
  </DialogPrimitive.Portal>
)
Portal.displayName = DialogPrimitive.Portal.displayName

const Close: typeof DialogPrimitive.Close = DialogPrimitive.Close

const Overlay: typeof DialogPrimitive.Overlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'tw-fixed tw-inset-0 tw-z-50 tw-bg-black/60 tw-backdrop-blur-sm data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0',
      className
    )}
    {...props}
  />
))
Overlay.displayName = DialogPrimitive.Overlay.displayName

const Content: React.FC<DialogPrimitive.DialogContentProps & { size?: 'lg' | 'xl' | '2xl' | '3xl' }> = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & { size?: 'lg' | 'xl' | '2xl' | '3xl' }
>(({ className, children, size = 'lg', ...props }, ref) => (
  <Portal>
    <Overlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        size === 'lg' && 'tw-max-w-lg',
        size === 'xl' && 'tw-max-w-xl',
        size === '2xl' && 'tw-max-w-2xl',
        size === '3xl' && 'tw-max-w-3xl',
        'tw-bg-white tw-fixed tw-left-1/2 tw-top-1/2 tw-z-50 tw-grid tw-w-full -tw-translate-x-1/2 -tw-translate-y-1/2 tw-gap-4 tw-border tw-p-6 tw-shadow-lg tw-duration-200 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=closed]:tw-slide-out-to-top-[48%] data-[state=open]:tw-slide-in-from-left-1/2 data-[state=open]:tw-slide-in-from-top-[48%] sm:tw-rounded-lg',
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="tw-p-0.5 tw-absolute tw-right-4 tw-top-4 tw-rounded-sm tw-opacity-70 tw-transition-opacity hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 disabled:tw-pointer-events-none">
        <Icon type="x-close" height={10} width={10} className="tw-h-2.5 tw-w-2.5" />
        <span className="tw-sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </Portal>
))
Content.displayName = DialogPrimitive.Content.displayName

const Header = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('tw-flex tw-flex-col tw-space-y-1.5 tw-text-center sm:tw-text-left', className)} {...props} />
)
Header.displayName = 'DialogHeader'

const Footer = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2', className)}
    {...props}
  />
)
Footer.displayName = 'DialogFooter'

const Title: typeof DialogPrimitive.Title = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('tw-text-lg tw-font-semibold tw-leading-none tw-tracking-tight', className)}
    {...props}
  />
))
Title.displayName = DialogPrimitive.Title.displayName

const Description: typeof DialogPrimitive.Description = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description ref={ref} className={cn('tw-text-sm', className)} {...props} />
))
Description.displayName = DialogPrimitive.Description.displayName

export { Root, Portal, Overlay, Close, Trigger, Content, Header, Footer, Title, Description }
