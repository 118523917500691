import React from 'react'
import { Button, Dialog } from '@atoms'
import { scopedTranslation } from '@frontend/utils/I18n';

const tLabels = scopedTranslation('components.success_modal.labels')

function Header({ children }) {
  return (
    <Dialog.Header>
      <div className="tw-flex tw-justify-center tw-text-center">
        <h1 className="tw-text-gray-900 tw-font-bold tw-text-3xl">{children}</h1>
      </div>
    </Dialog.Header>
  )
}

interface RootProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
}

function Root({ children, open, setOpen }: RootProps) {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Content className="tw-p-16">
        {children}
        <div className="tw-flex tw-justify-center tw-items-center">
          <Button
            color="success"
            leadingIcon="check"
            size="xl"
            onClick={() => { setOpen(false) }}
          >
            <span className="tw-font-bold">
              {tLabels("default")}
            </span>
          </Button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export { Root, Header }
