import React from 'react'

import Pagination from '@molecules/Pagination'
import { type SupporterShowType } from '@frontend/contexts/supporters-management/pages/SupporterShow'
import Icon from '@components/utils/Icon'
import { scopedTranslation } from '@utils/I18n'

import ContactLogCard from './ContactLogCard'

const t = scopedTranslation('components.contacts_log')

type ContactsLogProps = {
  contacts: SupporterShowType['contacts']['nodes']
  totalPages: number
}

const ContactsEmptyState = () => (
  <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col tw-gap-4 tw-py-6">
    <div className="tw-flex tw-items-center tw-justify-center tw-bg-primary-50 tw-w-[5.5rem] tw-h-[5.5rem] tw-rounded-full">
      <Icon type="search-lg" className="tw-text-primary-600" width={38} height={38} />
    </div>
    <p className="tw-text-lg tw-font-bold tw-text-gray-900">{t('empty_state.title')}</p>
    <p className="tw-text-base tw-font-base tw-text-gray-900">{t('empty_state.description')}</p>
  </div>
)

export default function ContactsLog({ contacts, totalPages }: ContactsLogProps) {
  const hasContacts = contacts.length > 0

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <div className="tw-shadow-md tw-rounded-xl tw-border tw-border-gray-200 [&>*]:tw-border-b [&>*]:tw-border-gray-200 [&>*:last-child]:tw-border-b-0">
        {hasContacts ? (
          contacts.map((contact) => <ContactLogCard key={contact.id} {...contact} />)
        ) : (
          <ContactsEmptyState />
        )}
      </div>
      {hasContacts && <Pagination totalPages={totalPages} only={['contacts']} />}
    </div>
  )
}
