import React from 'react'

import { Divider } from '@atoms'

import GroupAddress from '../../list-groups/GroupAddress'
import PointPeople from '../../list-groups/PointPeople'

type Props = {
  name: string
  description: string | null
  streetAddress: string | null
  city: string | null
  state: string | null
  postcode: string | null
  notifyAllAdmins: boolean
  pointPeople: string[]
}
function GroupDetailsPreview({
  name,
  description,
  streetAddress,
  city,
  state,
  postcode,
  notifyAllAdmins,
  pointPeople,
}: Props) {
  const hasAddress = streetAddress ?? city ?? state ?? postcode
  return (
    <div className="tw-flex tw-flex-col tw-gap-3 tw-pt-2">
      {description && <p>{description}</p>}
      {hasAddress && <GroupAddress streetAddress={streetAddress} city={city} postcode={postcode} state={state} />}
      <PointPeople groupName={name} notifyAllAdmins={notifyAllAdmins} pointPersonEmails={pointPeople} />
      <div className="tw-py-3">
        <Divider />
      </div>
    </div>
  )
}

export default GroupDetailsPreview
