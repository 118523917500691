import gql from 'graphql-tag'

export const exportSupportersCsv = gql`
  mutation exportSupportersCsv {
    exportSupportersCsv(input: {}) {
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`

export const exportGroupSupporters = gql`
  mutation exportGroupSupporters(
    $exportFormat: String!
    $groupId: ID!
    $sortBy: String
    $filters: [SupporterSearchFiltersInputType!]
  ) {
    exportGroupSupporters(
      input: { exportFormat: $exportFormat, groupId: $groupId, sortBy: $sortBy, filters: $filters }
    ) {
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
