import React, { useState } from 'react'

import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { Button, SelectDropdown, TextInput } from '@atoms'
import { scopedTranslation, i18nValidation } from '@utils/I18n'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import useMutation from '@hooks/useGQLMutation'
import { addGroupLeadersMutation } from '@frontend/contexts/supporters-management/features/manage-leaders/mutations'
import * as SuccessModal from '@molecules/SuccessModal'
import { AddGroupLeadersDocument } from '@frontend/graphql/types.generated'
import { useErrorsStore } from '@stores/errorsStore'

const t = scopedTranslation('group_edit.manage_leaders.add_leaders')
const tAttributes = scopedTranslation('attributes')

type Leader = {
  email: string
  role: string
}

type InvitedUser = {
  id: string
  email: string
  fullName?: string | null
  role: string
}

type SuccessfullyInvitedLeaders = InvitedUser[]

export type AddLeaderFormValues = {
  leadersToAdd: Leader[]
}

export const validationSchema = yup.object().shape({
  leadersToAdd: yup.array().of(
    yup.object().shape({
      email: yup
        .string()
        .label(tAttributes('user.email'))
        .email(i18nValidation('valid'))
        .required(i18nValidation('required')),
      role: yup.string().label('Role').required('Role is required'),
    })
  ),
})

export default function AddLeadersForm({ groupID, addLeader }) {
  const roleOptions = ['captain', 'vice-captain']
  const defaultFieldValues = { email: '', role: roleOptions[0] }
  const [SuccessModalOpen, setSuccessModalOpen] = React.useState(false)
  const [leadersInvited, setLeadersInvited] = useState<SuccessfullyInvitedLeaders>([])
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
    defaultValues: { leadersToAdd: [] },
  })

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: 'leadersToAdd',
  })

  const { mutate: addGroupLeadersMutate } = useMutation<typeof AddGroupLeadersDocument>(addGroupLeadersMutation)
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const addGroupLeaders = (users) => {
    clearAppErrors('manageLeaders')
    addGroupLeadersMutate(
      { id: groupID, users },
      {
        onSuccess: (response) => {
          const successfullyInvitedLeaders = (response?.addGroupLeaders?.users || []).map((user) => ({
            id: user.id,
            email: user.email,
            fullName: user.fullName,
            role: user.userRoles.nodes![0]!.role.name!,
          }))

          successfullyInvitedLeaders.forEach((leader) => {
            addLeader(leader)
          })
          setLeadersInvited(successfullyInvitedLeaders)
          setSuccessModalOpen(true)
          replace([])
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'manageLeaders',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  const onSubmit = async (values: yup.InferType<typeof validationSchema>) => {
    addGroupLeaders(values.leadersToAdd)
  }

  return (
    <div className="tw-flex tw-flex-col">
      <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-4">
        <h3 className="tw-text-gray-900 tw-font-bold tw-text-3xl">{t('title')}</h3>
        <p>{t('description')}</p>

        {fields.map((leader, index) => (
          <div
            key={leader.id}
            className="tw-flex md:tw-flex-row tw-flex-col xl:tw-max-w-3xl tw-content-center tw-items-start tw-gap-4"
          >
            <div className="tw-w-full md:tw-min-w-[20ch]">
              <TextInput
                leadingIcon="mail-01"
                displayName={t('labels.email')}
                hideLabel={true}
                placeholder="email@example.com"
                errors={errors}
                {...register(`leadersToAdd.${index}.email`)}
              />
            </div>

            <div className="tw-h-fit tw-w-full md:tw-min-w-[180px] md:tw-w-fit">
              <SelectDropdown.Root
                name="roleOptions"
                onValueChange={(value) => {
                  setValue(`leadersToAdd.${index}.role`, value)
                }}
              >
                <SelectDropdown.Trigger aria-label={t('labels.role')}>
                  <SelectDropdown.Value placeholder="captain" />
                </SelectDropdown.Trigger>
                <SelectDropdown.Content id="roleOptionsContent">
                  {roleOptions.map((roleName) => (
                    <SelectDropdown.Item key={roleName} value={roleName}>
                      {roleName}
                    </SelectDropdown.Item>
                  ))}
                </SelectDropdown.Content>
              </SelectDropdown.Root>
            </div>
            <div className="tw-ml-auto md:tw-ml-0">
              <Button
                type="button"
                ariaLabel={t('aria_labels.remove', { email: watch(`leadersToAdd.${index}.email`) })}
                size="md"
                leadingIcon="trash-01"
                rank="primary"
                color="danger"
                onClick={() => {
                  remove(index)
                }}
              >
                {t('labels.remove')}
              </Button>
            </div>
          </div>
        ))}

        <div>
          <Button
            type="button"
            size="md"
            leadingIcon="plus"
            rank="link"
            onClick={() => {
              append(defaultFieldValues)
            }}
          >
            {t('labels.add_new_leader')}
          </Button>
        </div>

        <div>
          <Button type="submit" size="md" trailingIcon="arrow-circle-broken-right">
            {t('labels.submit')}
          </Button>
        </div>
      </form>
      <SuccessModal.Root open={SuccessModalOpen} setOpen={setSuccessModalOpen}>
        <SuccessModal.Header>{t('dialog.title')}</SuccessModal.Header>
        <ul className="tw-list-disc tw-flex tw-flex-col tw-items-center">
          {leadersInvited?.map((user) => {
            return <li key={user.id}>{user.fullName || user.email}</li>
          })}
        </ul>
      </SuccessModal.Root>
    </div>
  )
}
