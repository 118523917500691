import React from 'react';

import { TextInput } from '@atoms';

import { scopedTranslation } from '@frontend/utils/I18n';

type AddShiftFieldRowProps = {
  register: any;
  errors: any;
}

const tAttributes = scopedTranslation('attributes.shift')

export const AddShiftFieldRow: React.FC<AddShiftFieldRowProps> = ({ register, errors }) => {
  return (
    <div className="tw-flex tw-items-start tw-gap-4">
      <TextInput displayName={tAttributes('start_time')} {...register(`startTime`)} type="time" errors={errors} />
      <TextInput displayName={tAttributes('end_time')} {...register(`endTime`)} type="time" errors={errors} />
      <TextInput
        displayName={tAttributes('volunteers_required')}
        {...register(`volunteersRequiredCount`)}
        type="number"
        errors={errors}
      />
      <TextInput displayName={tAttributes('role')} {...register(`roleName`)} type="text" errors={errors} />
    </div>
  )
}
