import useSupportersSearch from '../../shared/hooks/useSupportersSearch'
import { type StateCreator } from 'zustand/esm'
import { type StoreState as FilteringParamsStoreState } from './useSupporterFilteringStore'

export type StoreState = {
  supporterName?: string
}
export type StoreActions = {
  updateName: (name: string) => void
}

const DEFAULT_PROPS: StoreState = {}

type SliceCreator = StateCreator<FilteringParamsStoreState, [], [], StoreState & StoreActions>

export const createNameSearchSlice: SliceCreator = (set, get, store) => ({
  ...DEFAULT_PROPS,
  updateName(supporterName: string) {
    useSupportersSearch({
      activeFilters: get().filters,
      currentSort: get().currentSort,
      supporterName,
      setLoading: get().setLoading,
    })
  },
})
