import { Slot } from '@hooks/useSlots'
import InfoCard from '@molecules/InfoCard'
import React from 'react'
import type { GroupSupporterEdge, Supporter } from '../../../../graphql/types.generated'
import { Link } from '@atoms'
import { scopedTranslation } from '@utils/I18n'
import { formatDate } from '@utils/formatDate'

const t = scopedTranslation('components')
const tSupporterShow = scopedTranslation('supporter_show')

type SupporterEdgeType = Pick<GroupSupporterEdge, 'membership'> & {
  groupId: number | string
  node: Pick<
    Supporter,
    'id' | 'firstName' | 'lastName' | 'engagementLevel' | 'createdAt' | 'externalCreatedAt' | 'lastContactedAt'
  >
  dataCompId?: string
}

export function SupporterCard({
  groupId,
  node: { id, firstName, lastName, engagementLevel, externalCreatedAt, createdAt, lastContactedAt },
  membership,
  dataCompId,
}: SupporterEdgeType) {
  const joinedGroup = membership?.createdAt && formatDate(membership.createdAt)
  const joinedOrganisation = formatDate(externalCreatedAt ?? createdAt)
  const lastContactedDate = lastContactedAt && formatDate(lastContactedAt)

  return (
    <InfoCard listItem data-testid={`supporter-card-${id}`} dataCompId={dataCompId}>
      <Slot name="heading">
        <h2 className="tw-text-gray-900 tw-font-medium tw-text-lg tw-w-fit hover:tw-text-primary-700">
          <a
            href={`/groups/${groupId}/supporters/${id}?ref=${window.location.pathname + window.location.search}`}
            aria-describedby={`supporter-open-${id}`}
          >
            {firstName} {lastName}
          </a>
        </h2>
        <div className="tw-flex tw-gap-4 tw-pt-2">
          <p data-testid="joined-group">
            <span className="tw-text-gray-blue-500 tw-font-bold tw-text-xs tw-uppercase">
              {tSupporterShow('joined_group')}{' '}
            </span>
            <span className="tw-text-gray-900 tw-text-sm">{joinedGroup}</span>
          </p>
          <p>
            <span className="tw-text-gray-blue-500 tw-font-bold tw-text-xs tw-uppercase">
              {tSupporterShow('joined_organisation')}{' '}
            </span>
            <span className="tw-text-gray-900 tw-text-sm">{joinedOrganisation}</span>
          </p>
          {engagementLevel && (
            <p data-testid="engagement-level">
              <span className="tw-text-gray-blue-500 tw-font-bold tw-text-xs tw-uppercase">
                {tSupporterShow('engagement_level')}{' '}
              </span>
              <span className="tw-text-gray-900 tw-text-sm">{engagementLevel}</span>
            </p>
          )}
          {lastContactedDate && (
            <p data-testid="last-contacted">
              <span className="tw-text-gray-blue-500 tw-font-bold tw-text-xs tw-uppercase">
                {tSupporterShow('last_contacted')}{' '}
              </span>
              <span className="tw-text-gray-900 tw-text-sm">{lastContactedDate}</span>
            </p>
          )}
        </div>
      </Slot>

      {/* <Slot name="main">
          <div className="tw-flex tw-flex-col tw-gap-3 tw-uppercase tw-font-bold tw-text-xs tw-text-gray-900">
            <div id="supporter-criteria" className="tw-flex tw-flex-col tw-gap-1">
              <p className="tw-text-gray-blue-500">
                <span>{t('supporter_card.main.why', { name: firstName })}</span>
              </p>
              <div className="tw-pl-1 tw-flex tw-flex-col tw-gap-2.5">
                <p>
                  {t('group_criteria.properties.tags')}
                  <span className="tw-normal-case tw-font-normal"></span>
                </p>
                <p>
                  {t('group_criteria.properties.postcode')}
                  <span className="tw-normal-case tw-font-normal"></span>
                </p>
              </div>
            </div>
          </div>
        </Slot> */}

      <Slot name="actions">
        <Slot name="primary">
          <Link
            id={`supporter-open-${id}`}
            size="sm"
            href={`/groups/${groupId}/supporters/${id}?ref=${window.location.pathname + window.location.search}`}
            trailingIcon="arrow-circle-broken-right"
          >
            {t('supporter_card.actions.show', { name: firstName })}
          </Link>
          <Link
            size="sm"
            rank="primary"
            href={`/members/${id}/contact?action_group_id=${groupId}&v2=true`}
            ariaLabel={t('supporter_card.actions.log_contact_aria', {
              name: firstName,
            })}
          >
            {t('supporter_card.actions.log_contact', { name: firstName })}
          </Link>
          <Link
            size="sm"
            rank="secondary"
            href={`/members/${id}/send_email?action_group_id=${groupId}&v2=true`}
            leadingIcon="mail-01"
            disabled={!membership?.emailable}
          >
            {t('supporter_card.actions.email', { name: firstName })}
          </Link>
          <Link
            size="sm"
            rank="secondary"
            href={`/members/${id}/send_sms?action_group_id=${groupId}&v2=true`}
            leadingIcon="message-chat-square"
            disabled={!membership?.textable}
          >
            {t('supporter_card.actions.sms', { name: firstName })}
          </Link>
          <Link
            size="sm"
            rank="secondary"
            href={`/members/${id}/call?action_group_id=${groupId}&v2=true`}
            leadingIcon="phone-call-01"
            disabled={!membership?.callable}
          >
            {t('supporter_card.actions.call', { name: firstName })}
          </Link>
        </Slot>

        <Slot name="secondary">
          <Link
            size="sm"
            rank="link"
            href={`/groups/${groupId}/supporters/${id}?ref=${window.location.pathname + window.location.search}`}
            leadingIcon="pencil-02"
            inertiaLink={true}
          >
            {t('supporter_card.actions.edit', { name: firstName })}
          </Link>
        </Slot>
      </Slot>
    </InfoCard>
  )
}
