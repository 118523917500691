import gql from 'graphql-tag'

export const updateDraftGroupMutation = gql`
  mutation updateDraftGroup(
    $id: ID!
    $name: String
    $description: String
    $publicPublish: Boolean
    $publicDescription: String
    $hasPhysicalAddress: Boolean
    $streetAddress: String
    $city: String
    $state: String
    $postcode: String
    $country: String
  ) {
    updateDraftGroup(
      input: {
        id: $id
        name: $name
        description: $description
        publicPublish: $publicPublish
        publicDescription: $publicDescription
        hasPhysicalAddress: $hasPhysicalAddress
        streetAddress: $streetAddress
        city: $city
        state: $state
        postcode: $postcode
        country: $country
      }
    ) {
      draftGroup {
        id
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
