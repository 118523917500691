import React from 'react'

import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Button, TextInput } from '@atoms'
import { type CaptainsStoreType } from 'app/frontend/contexts/supporters-management/features/manage-leaders/hooks/useCaptainsStore'

import { scopedTranslation, i18nValidation } from '@utils/I18n'
import type * as Yup from 'yup'
const t = scopedTranslation('group_create.manage_captains_form')
const tAttributes = scopedTranslation('attributes')

export const validationSchema = yup.object({
  captain: yup.object({
    email: yup
      .string()
      .label(tAttributes('user.email'))
      .email(i18nValidation('valid'))
      .required(i18nValidation('required')),
  }),
})

type extractFieldValues<Type> = Type extends Yup.ObjectSchema<infer T> ? T : never
type ValidationSchema = extractFieldValues<typeof validationSchema>

type PropsType = {
  addCaptain: CaptainsStoreType['addCaptain']
}

export default function AddCaptain({ addCaptain }: PropsType) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ValidationSchema>()

  const onSubmit = async (values) => {
    addCaptain(values.captain)
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-self-stretch">
      <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full tw-flex tw-gap-4 tw-items-start tw-justify-between">
        <div className="tw-w-full">
          <TextInput
            displayName={t('labels.invite_captain_by_email')}
            placeholder="email@example.com"
            errors={errors}
            {...register('captain.email')}
          />
        </div>
        <div className="tw-self-start tw-mt-[1.4rem]">
          <Button type="submit" size="md" leadingIcon="plus-circle">
            {t('buttons.submit')}
          </Button>
        </div>
      </form>
    </div>
  )
}
