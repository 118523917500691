import React from 'react'
import { clsx } from 'clsx'
import { useController, type Control } from 'react-hook-form'

import { Button, TextInput } from '@atoms'

import { scopedTranslation } from '@utils/I18n'
const t = scopedTranslation('shared')

type EditableTextFieldRowProps = {
  name: string
  label: string
  onSubmit: (data: any) => any
  onCancel: () => void
  control: Control<any>
  open: boolean
}

const EditableTextFieldRow = React.forwardRef<HTMLInputElement | null, EditableTextFieldRowProps>(
  function EditableTextFieldRow({ name, label, onCancel, onSubmit, control }, ref) {
    const {
      field,
      fieldState: { error: errors },
    } = useController({ name, control })

    function cancel() {
      onCancel()
    }

    return (
      <>
        <form onSubmit={onSubmit}>
          <div className="tw-flex tw-gap-2 tw-items-center">
            <div className={clsx('tw-flex-1', errors && 'tw-relative tw-top-3')}>
              <TextInput
                displayName={label}
                errors={{
                  [name]: errors,
                }}
                hideLabel={true}
                placeholder={t('placeholders.placeholder_edit', { name: label.toLowerCase() })}
                {...field}
                ref={ref}
              />
            </div>
            <span className="tw-flex tw-gap-2">
              <Button
                size="sm"
                rank="primary"
                type="submit"
                disabled={!!errors}
                ariaLabel={t('aria_labels.save_object', { name: label })}
              >
                {t('buttons.save', { name: label })}
              </Button>
              <Button
                size="sm"
                rank="primary"
                color="danger"
                onClick={cancel}
                ariaLabel={t('aria_labels.cancel_editing', { name: label })}
              >
                {t('buttons.cancel', { name: label })}
              </Button>
            </span>
          </div>
        </form>
      </>
    )
  }
)

export default EditableTextFieldRow
