import React from 'react'
import Alert from '@atoms/Alert'

import { scopedTranslation } from '../../../utils/I18n'
import { type ErrorType } from '@stores/errorsStore'
const t = scopedTranslation('shared.errors.422')

type Alert422Props = {
  errors: ErrorType[]
  onClose: () => void
}

export default function Alert422({ errors, onClose }: Alert422Props) {
  return (
    <Alert data-testid="alert-422" onClose={onClose} type="error" title={t('title')}>
      <ul className="tw-list-disc tw-list-inside">
        {errors.map((error) => (
          <li key={error.message}>{error.message}</li>
        ))}
      </ul>
    </Alert>
  )
}
