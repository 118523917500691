import React, { useState } from 'react'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import useMutation from '@hooks/useGQLMutation'
import useFormWithErrors from '@hooks/useFormWithErrors'

import type { AddSupporterToGroupDocument, Group, GroupCriteriaRule } from '@frontend/graphql/types.generated'

import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { useErrorsStore } from '@stores/errorsStore'

import { Button, Alert } from '@atoms'
import { TextInput, Checkbox } from '@atoms/form'
import { SheetHeader, SheetTitle } from '@atoms/Sheet'
import CountrySelect from '@atoms/form/CountrySelect'
import TagsSelect from '@molecules/TagsSelect'

import { addSupporterToGroupMutation } from './mutations'

import { RuleComponent } from '../list-groups/GroupCriteria'
import { SharedInertiaProps } from '../../pages/GroupShow'

import { scopedTranslation, i18nValidation } from '@utils/I18n'
import EngagementLevelSelect from '@frontend/contexts/supporters-management/features/add-supporter/EngagementLevelSelect'

const t = scopedTranslation('supporter_create')
const tAttributes = scopedTranslation('attributes')

type CriteriaRuleProps = Pick<GroupCriteriaRule, 'id' | 'predicate' | 'property' | 'values'>
type AddSupporterProps = {
  group: Pick<Group, 'id' | 'name'>
  criteria: {
    combinator: Group['criteria']['combinator']
    rules: {
      nodes: CriteriaRuleProps[]
    }
  }
  permissions: SharedInertiaProps['permissions']
}

type SuccessConfirmationProps = {
  firstName: string
}

const SuccessConfirmation = ({ firstName }: SuccessConfirmationProps) => {
  return (
    <div>
      <SheetHeader>
        <SheetTitle>{t(`success_confirmation.title`)}</SheetTitle>
      </SheetHeader>
      <div className="tw-p-8 tw-text-lg tw-space-y-2">
        <p>{t(`success_confirmation.body.summary`, { first_name: firstName })}</p>
        <p>{t(`success_confirmation.body.notification`)}</p>
      </div>
    </div>
  )
}

function AddSupporter({ group, criteria, permissions }: AddSupporterProps) {
  const validationSchema = yup.object({
    supporter: yup.object({
      firstName: yup.string().label(tAttributes('supporter.first_name')).required(i18nValidation('required')),
      lastName: yup.string().label(tAttributes('supporter.last_name')).required(i18nValidation('required')),
      email: yup
        .string()
        .label(tAttributes('supporter.email'))
        .email(i18nValidation('valid'))
        .required(i18nValidation('required')),
      mobile: yup.string().label(tAttributes('supporter.mobile')),
      address1: yup.string().label(tAttributes('supporter.address1')),
      address2: yup.string().label(tAttributes('supporter.address2')),
      city: yup.string().label(tAttributes('supporter.city')),
      state: yup.string().label(tAttributes('supporter.state')),
      zip: yup.string().label(tAttributes('supporter.zip')),
      countryCode: yup.string().label(tAttributes('supporter.country')),
      tags: yup
        .array()
        .of(
          yup.object({
            value: yup.string().required(),
            label: yup.string().required(),
          })
        )
        .notRequired(),
      consent: yup
        .boolean()
        .label(tAttributes('supporter.consent'))
        .required(i18nValidation('required'))
        .oneOf([true], i18nValidation('required')),
      engagementLevel: yup.string().label('Engagement Level').notRequired(),
    }),
  })

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)
  const {
    setErrors: setFormErrors,
    formState: { errors },
    register,
    control,
    handleSubmit,
    watch,
  } = useFormWithErrors({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })

  const { mutate } = useMutation<typeof AddSupporterToGroupDocument>(addSupporterToGroupMutation)

  const onSubmit = async (values: yup.InferType<typeof validationSchema>) => {
    const supporterData = {
      groupId: group.id,
      ...values.supporter,
      tags: values.supporter.tags?.map((tag) => tag.value),
    }
    clearAppErrors('addSupporterToGroup')
    mutate(supporterData, {
      onSuccess: (response) => {
        if (!response.addSupporterToGroup) return

        if (response.addSupporterToGroup.success) {
          // graphql always responds with a 200 response so react-hook-form isSubmitSuccessful cannot be used
          setIsSubmitSuccessful(true)
        } else {
          setAppErrors({ errorsPath: 'addSupporterToGroup', errors: response.addSupporterToGroup.errors })
          setFormErrors({
            errorsBasePath: 'supporter',
            errors: response.addSupporterToGroup?.errors,
            adapter: serverErrorsToFormErrors,
          })
        }
      },
      onError: () => {
        setAppErrors({
          errorsPath: 'addSupporterToGroup',
          errors: [
            {
              message: '',
              code: 'unknown',
            },
          ],
        })
      },
    })
  }

  const {
    rules: { nodes: rules },
  } = criteria

  const meetsTagRule = (rule: CriteriaRuleProps) => {
    const currentTags = watch('supporter.tags')?.map((tag) => tag.value) ?? []

    switch (rule.predicate) {
      case 'has_any':
        return rule.values.some((groupTag) => currentTags.includes(groupTag))
      case 'has_all':
        return rule.values.every((groupTag) => currentTags.includes(groupTag))
      case 'not_any':
        return rule.values.every((exclusionTag) => !currentTags.includes(exclusionTag))
      default:
        return false
    }
  }

  const meetsPostcodeRule = (rule: CriteriaRuleProps) => {
    const postcode = watch('supporter.zip')
    return postcode && rule.values.includes(postcode)
  }

  const rulesStillToMeet = rules.filter((rule) => {
    switch (rule.property) {
      case 'tags':
        return !meetsTagRule(rule)
      case 'zip':
        return !meetsPostcodeRule(rule)
      default:
        return false
    }
  })

  if (isSubmitSuccessful) return <SuccessConfirmation firstName={watch('supporter.firstName')} />

  return (
    <div>
      <SheetHeader>
        <SheetTitle>{t(`title`, { name: group.name })}</SheetTitle>
      </SheetHeader>
      <div className="tw-px-8">
        <form onSubmit={handleSubmit(onSubmit)} className="tw-flex tw-flex-col tw-gap-6 tw-pt-8 tw-pb-2">
          {rules.length > 0 && (
            <Alert type="info" title={t('membership_criteria.title')}>
              <p className="tw-text-sm">{t(`membership_criteria.inclusion_explanation`, { group_name: group.name })}</p>
              <div className="tw-flex tw-flex-col tw-pt-2 tw-pb-1 tw-gap-2 tw-text-sm">
                {rules.map((rule) => (
                  <RuleComponent key={rule.id} rule={rule} tagSize="xs" />
                ))}
              </div>
            </Alert>
          )}
          <section className="tw-flex tw-flex-col tw-gap-4">
            <h3 className="tw-text-lg tw-font-bold tw-text-gray-900">{t('sections.contact_details')}</h3>
            <div className="tw-flex tw-gap-6 tw-justify-start tw-items-start">
              <TextInput
                displayName={tAttributes('supporter.first_name')}
                {...register('supporter.firstName')}
                errors={errors}
              />
              <TextInput
                displayName={tAttributes('supporter.last_name')}
                {...register('supporter.lastName')}
                errors={errors}
              />
            </div>
            <TextInput displayName={tAttributes('supporter.email')} {...register('supporter.email')} errors={errors} />
            <TextInput
              displayName={tAttributes('supporter.contact_number')}
              {...register('supporter.mobile')}
              errors={errors}
            />
            <div className="tw-flex tw-flex-col tw-justify-center tw-min-w-[0] tw-w-full">
              <EngagementLevelSelect control={control} />
            </div>
          </section>
          <section className="tw-flex tw-flex-col tw-gap-4">
            <h3 className="tw-text-lg tw-font-bold tw-text-gray-900">{t('sections.address')}</h3>
            <TextInput
              displayName={tAttributes('supporter.address2')}
              {...register('supporter.address2')}
              errors={errors}
            />
            <TextInput
              displayName={tAttributes('supporter.address1')}
              {...register('supporter.address1')}
              errors={errors}
            />
            <div className="tw-flex tw-gap-6 tw-justify-start tw-items-start">
              <TextInput displayName={tAttributes('supporter.city')} {...register('supporter.city')} errors={errors} />
              <TextInput
                displayName={tAttributes('supporter.state')}
                {...register('supporter.state')}
                errors={errors}
              />
            </div>
            <div className="tw-flex tw-gap-6 tw-justify-start tw-items-start">
              <div className="tw-flex-1">
                <TextInput displayName={tAttributes('supporter.zip')} {...register('supporter.zip')} errors={errors} />
              </div>
              <div className="tw-flex-1">
                <CountrySelect
                  name="supporter.countryCode"
                  label={tAttributes('supporter.country')}
                  control={control}
                />
              </div>
            </div>
          </section>
          <section className="tw-flex tw-flex-col tw-gap-4">
            <h3 className="tw-text-lg tw-font-bold tw-text-gray-900">{t('sections.other_criteria')}</h3>
            <div className="tw-flex tw-flex-col tw-justify-center tw-min-w-[0] tw-w-full">
              <TagsSelect name="supporter.tags" control={control} />
            </div>
            <div className="tw-flex tw-flex-wrap tw-items-center tw-w-full tw-pt-2">
              <Checkbox name="supporter.consent" control={control} required={true} errors={errors}>
                <div className="tw-pl-2">{tAttributes('supporter.consent_label')}</div>
              </Checkbox>
            </div>
            {permissions.sendNewSupporterAutoresponse && (
              <div className="tw-flex tw-flex-wrap tw-items-center tw-w-full tw-pt-2">
                <Checkbox name="supporter.sendAutoresponse" control={control} errors={errors}>
                  <div className="tw-pl-2">{tAttributes('supporter.send_autoresponse_label')}</div>
                </Checkbox>
              </div>
            )}
          </section>
          {/* role="status" sets aria-live="polite" and aria-atomic="true" */}
          <div role="status">
            {rulesStillToMeet.length === 0 ? (
              <Alert type="info" title={t('membership_criteria.rules_are_met')} />
            ) : (
              <Alert type="warning" title={t('membership_criteria.rules_not_met')}>
                <p className="tw-text-sm">{t('membership_criteria.missing')}:</p>
                <div className="tw-flex tw-flex-col tw-pt-2 tw-pb-1 tw-gap-2 tw-text-sm">
                  {rulesStillToMeet.map((rule) => (
                    <RuleComponent key={rule.id} rule={rule} tagSize="xs" />
                  ))}
                </div>
              </Alert>
            )}
          </div>
          <div className="tw-w-full tw-flex tw-justify-center">
            <Button type="submit" rank="primary" size="2xl" trailingIcon="arrow-circle-broken-right">
              {t('submit_button')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddSupporter
