import type { Control, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form'
import React, { useState } from 'react'
import AsyncSelect from './AsyncSelect'
import { scopedTranslation } from '@utils/I18n'
import { useGraphQLClient } from '@utils/GraphQLClientProvider'
import type { GraphQLClient } from 'graphql-request'
import { gql } from 'graphql-tag'
import { useController } from 'react-hook-form'

const tShared = scopedTranslation('shared')

const loadTagOptions = async (
  inputValue: string,
  setTotalCount: React.Dispatch<React.SetStateAction<number>>,
  client: GraphQLClient
) => {
  const TAGS_QUERY = gql`
    query Tags($nameICont: String, $first: Int) {
      tags(nameICont: $nameICont, first: $first) {
        totalCount
        nodes {
          id
          name
        }
      }
    }
  `

  type TagNode = {
    id: string
    name: string
  }
  interface TagsData {
    tags: {
      totalCount: number
      nodes: TagNode[]
    }
  }

  const { tags } = await client.request<TagsData>(TAGS_QUERY, {
    nameICont: inputValue,
    first: 7,
  })

  setTotalCount(tags.totalCount)

  return tags.nodes.map((tag) => ({
    value: tag.name,
    label: tag.name,
  }))
}

type FieldType<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> =
  | {
      field: ControllerRenderProps<TFieldValues, TName>
    }
  | {
      control: Control<TFieldValues>
      name: TName
    }

type TagsSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  displayName?: string
  hideLabel?: boolean
  searchPlaceholder?: string
  createOptionPrefix?: string
} & FieldType<TFieldValues, TName>

export default function TagsSelect({
  displayName = tShared('resource_names.tags'),
  hideLabel,
  searchPlaceholder,
  createOptionPrefix,
  ...props
}: TagsSelectProps<any, any>) {
  let field: ControllerRenderProps<any, any>
  if ('field' in props) {
    field = props.field
  } else {
    field = useController({ name: props.name, control: props.control }).field
  }

  const [totalCount, setTotalCount] = useState<number>(0)
  const client = useGraphQLClient()

  const loadOptions = async (inputValue: string) => {
    return await loadTagOptions(inputValue, setTotalCount, client)
  }

  return (
    <AsyncSelect
      dataTestId={'tags-select'}
      totalCount={totalCount}
      displayName={displayName}
      hideLabel={hideLabel}
      loadOptions={loadOptions}
      field={field}
      noOptionsMessage={searchPlaceholder}
      createOptionPrefix={createOptionPrefix}
    />
  )
}
