import gql from 'graphql-tag'

export const updateGroupCriteria = gql`
  mutation updateGroupCriteria($id: ID!, $rules: CriteriaRulesInput, $combinator: String) {
    updateGroupCriteria(input: { id: $id, rules: $rules, combinator: $combinator }) {
      groupCriteria {
        combinator
        rules {
          nodes {
            property
            predicate
            values
          }
        }
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
