import type { ComponentProps } from 'react'
import Icon from '@components/utils/Icon'
import { DayPicker } from 'react-day-picker'
import { buttonVariants } from '@utils/buttonVariants'

import clsx from 'clsx'

export type CalendarProps = ComponentProps<typeof DayPicker>

export const Calendar = ({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) => {
  return (
    <div data-testid="date-picker">
      <DayPicker
        showOutsideDays={showOutsideDays}
        className={clsx('tw-p-3', className)}
        classNames={{
          months: 'tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-x-4 sm:tw-space-y-0',
          month: 'tw-space-y-4',
          caption: 'tw-flex tw-justify-center tw-pt-1 tw-relative tw-items-center',
          caption_label: 'tw-text-sm tw-font-medium',
          nav: 'tw-space-x-1 tw-flex tw-items-center',
          nav_button: clsx(
            'tw-flex tw-justify-center tw-items-center tw-h-7 tw-w-7 tw-bg-transparent tw-p-0 tw-opacity-50',
            buttonVariants({ variant: 'ghost' })
          ),
          nav_button_previous: 'tw-absolute tw-left-1',
          nav_button_next: 'tw-absolute tw-right-1',
          table: 'tw-w-full tw-border-collapse tw-space-y-1',
          head_row: 'tw-flex',
          head_cell: 'tw-text-gray-500 tw-text-center tw-rounded-md tw-w-9 tw-font-normal tw-text-[0.8rem]',
          row: 'tw-flex tw-w-full tw-mt-2',
          cell: 'tw-h-9 tw-w-9 tw-text-center tw-text-sm tw-p-0 tw-relative [&:has([aria-selected].day-range-end)]:tw-rounded-r-md first:[&:has([aria-selected])]:tw-rounded-l-md last:[&:has([aria-selected])]:tw-rounded-r-md focus-within:tw-relative focus-within:tw-z-20',
          day: clsx(
            buttonVariants({ variant: 'ghost' }),
            'tw-h-9 tw-w-9 tw-p-0 tw-font-normal aria-selected:tw-opacity-100'
          ),
          day_range_end: 'day-range-end',
          day_selected:
            'tw-bg-primary-600 tw-text-white hover:tw-bg-primary-700 hover:tw-text-white focus:tw-bg-primary-700 focus:tw-text-white',
          day_today: 'tw-bg-gray-300 tw-text-gray-900',
          day_outside:
            'day-outside tw-text-gray-500 tw-opacity-50 aria-selected:tw-bg-gray-300/50 aria-selected:tw-text-gray-600 aria-selected:tw-opacity-30',
          day_disabled: 'tw-text-gray-500 tw-opacity-50',
          day_range_middle: 'aria-selected:tw-bg-primary-600 aria-selected:tw-text-gray-900',
          day_hidden: 'tw-invisible',
          ...classNames,
        }}
        components={{
          IconLeft: () => <Icon type="arrow-narrow-left" className="tw-h-4 tw-w-4" />,
          IconRight: () => <Icon type="arrow-narrow-right" className="tw-h-4 tw-w-4" />,
        }}
        {...props}
      />
    </div>
  )
}
