// Fancy TS debounce function, that takes a function and returns a debounced version of it.
// for more information on the function see: https://github.com/chodorowicz/ts-debounce

export interface DebouncerFunction<
  Args extends any[],
  F extends (...args: Args) => any
> {
  (this: ThisParameterType<F>, ...args: Args & Parameters<F>): void
  cancel: (reason?: any) => void
}

export default function debounce<Args extends any[], F extends (...args: Args) => any>(
  func: F,
  waitMilliseconds = 50,
): DebouncerFunction<Args, F> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined

  const debouncedFunction = function (
    this: ThisParameterType<F>,
    ...args: Parameters<F>
  ) {
    const context = this

    const invokeFunction = function () {
      timeoutId = undefined
      func.apply(context, args)
    }

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(invokeFunction, waitMilliseconds)
  }

  debouncedFunction.cancel = function (reason?: any) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId)
    }
  }

  return debouncedFunction
}