import React from 'react'
import { useController, useWatch } from 'react-hook-form'

import { scopedTranslation } from '@utils/I18n'

import { Select } from '@atoms'

import { type AvailableFilterPredicate } from './stores/filterStoreSlice'
import { type FieldProps } from './PropertyField'

const t = scopedTranslation('components.filter_dialog')

const PredicateField = ({ control, index, filteringOptions, update }: FieldProps) => {
  const filter = useWatch({ control, name: `rules.${index}` })
  const { property, predicate } = filter
  const fieldName = `rules.${index}.predicate` as const
  const labelName = `${fieldName}.label` as const
  const availablePredicates: AvailableFilterPredicate[] = property ? filteringOptions[property].predicates : []

  const {
    field: { ref: _ref, value: _value, ...field },
    formState: { errors: _errors },
  } = useController({
    control,
    name: fieldName,
  })

  return (
    <>
      <label className="tw-sr-only" id={labelName}>
        {t('labels.predicate')}
      </label>
      <Select
        // Use dynamic `key` to re-render predicate Select when property changes.
        // Otherwise the previously-selected predicate still appears as the value
        // even if that predicate isn't an available option for the new property.
        // key={`predicate-${index}-${property}`}

        options={availablePredicates.map((predicate) => ({
          value: predicate.value,
          label: predicate.label,
        }))}
        {...field}
        onChange={(predicate: string) => {
          const currentPredicate = availablePredicates.find(
            (availablePredicate) => availablePredicate.value === predicate
          )

          update(index, { property, predicate, values: currentPredicate?.defaultValue ?? null })
        }}
        errors={[]}
        value={predicate}
      />
    </>
  )
}

export default PredicateField
