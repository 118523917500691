import gql from 'graphql-tag';

export const addShiftMutation = gql`
  mutation AddShift($input: AddShiftInput!) {
    addShift(input: $input) {
      roster {
        id
        shifts {
          nodes {
            id
            startTime
            endTime
            roleName
            draft
          }
        }
      }
      errors
    }
  }
`;
