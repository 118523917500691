import React, { createContext, useContext } from 'react'
import { createStore } from 'zustand'
import { useStoreWithEqualityFn } from 'zustand/traditional'
import { shallow } from 'zustand/shallow'

interface StoreState {
  firstName: string | null
  lastName: string | null
  fullName: string | null
  tags: Array<{ value: string; label: string }>
  tagFilters: Array<{ value: string; label: string }>
}

interface StoreActions {
  setFirstName: (firstName: string) => void
  setLastName: (lastName: string) => void
  setFullName: (fullName: string) => void
  setTags: (tags: Array<{ value: string; label: string }>) => void
  setTagFilters: (tagFilters: Array<{ value: string; label: string }>) => void
}

type SupporterDetailsStore = ReturnType<typeof createSupporterDetailsStore>

const DEFAULT_PROPS: StoreState = {
  firstName: '',
  lastName: '',
  fullName: '',
  tags: [],
  tagFilters: [],
}

export const createSupporterDetailsStore = (initProps?: Partial<StoreState>) => {
  return createStore<StoreState & StoreActions>()((set) => ({
    ...DEFAULT_PROPS,
    ...initProps,
    setFirstName: (firstName: string) => {
      set({ firstName })
    },
    setLastName: (lastName: string) => {
      set({ lastName })
    },
    setFullName: (fullName: string) => {
      set({ fullName })
    },
    setTags: (tags: Array<{ value: string; label: string }>) => {
      set({ tags })
    },
    setTagFilters: (tagFilters: Array<{ value: string; label: string }>) => {
      set({ tagFilters })
    },
  }))
}

const SupporterDetailsContext = createContext<SupporterDetailsStore | null>(null)

export function SupporterDetailsProvider({
  children,
  store,
}: {
  children: React.ReactNode
  store: ReturnType<typeof createSupporterDetailsStore>
}) {
  return <SupporterDetailsContext.Provider value={store}>{children}</SupporterDetailsContext.Provider>
}

export default function useSupporterDetailsStore<U>(selector: (state: StoreState & StoreActions) => U): U {
  const store = useContext(SupporterDetailsContext)
  if (!store) {
    throw new Error('Missing SupporterDetailsProvider in the tree')
  }
  return useStoreWithEqualityFn<SupporterDetailsStore, U>(store, selector, shallow)
}
