import React from 'react'
import { scopedTranslation } from '@frontend/utils/I18n'
import { Button } from '@atoms'
import EmptyState from '@components/molecules/EmptyState'
import useSupporterFilteringStore from '../filter-supporters/stores/useSupporterFilteringStore'

const tEmptyState = scopedTranslation('group_show.empty_state')

type SupporterSearchEmptyStateProps = {
  handleDefaultButtonPress: () => any,
  totalCount: number
}

export default function SupporterSearchEmptyState({ handleDefaultButtonPress, totalCount }: SupporterSearchEmptyStateProps) {
  const activeFilters = useSupporterFilteringStore((state) => state.filters)
  const nameSearchValue = useSupporterFilteringStore((state) => state.supporterName)

  const clearNameSearchAndFilters = useSupporterFilteringStore((state) => state.clearNameSearchAndFilters)

  const groupHasNoSupporters = totalCount === 0

  const hasSearchTerm = nameSearchValue && nameSearchValue.length > 0
  const hasActiveFilters = activeFilters && activeFilters.length > 0
  
  const isSearchAndFilterActive = hasSearchTerm && hasActiveFilters;
  const isSearchOnlyActive = hasSearchTerm && !hasActiveFilters;
  const isFilterOnlyActive = !hasSearchTerm && hasActiveFilters;

  let subtext
  if (groupHasNoSupporters) {
    subtext = tEmptyState('messages.no_supporters.subtext')
  } else if (isSearchAndFilterActive) {
    subtext = tEmptyState('messages.no_results.search_and_filters')
  } else if (isSearchOnlyActive) {
    subtext = tEmptyState('messages.no_results.search_only')
  } else if (isFilterOnlyActive) {
    subtext = tEmptyState('messages.no_results.filters_only')
  }

  let buttonLabel
  if (groupHasNoSupporters) {
    buttonLabel = tEmptyState('actions.add_supporter.button')
  } else if (isSearchAndFilterActive) {
    buttonLabel = tEmptyState('actions.clear_buttons.search_and_filters')
  } else if (isSearchOnlyActive) {
    buttonLabel = tEmptyState('actions.clear_buttons.search_only')
  } else if (isFilterOnlyActive) {
    buttonLabel = tEmptyState('actions.clear_buttons.filters_only')
  }

  function handleEmptyStateButtonPress() {
    if (groupHasNoSupporters) {
      handleDefaultButtonPress()
    } else if(hasSearchTerm ?? hasActiveFilters) {
      clearNameSearchAndFilters()
    } else {
      handleDefaultButtonPress()
    }
  }

  return (
    <EmptyState
      headline={tEmptyState('messages.headline')}
      subtext={subtext}
      iconType={'users-01'}
    >
      <Button
        size="xl"
        rank="primary"
        {...(groupHasNoSupporters ? { trailingIcon: 'plus-circle' } : {})}
        onClick={handleEmptyStateButtonPress}
        ariaLabel={buttonLabel}
      >
        {buttonLabel}
      </Button>
    </EmptyState>
  )
}