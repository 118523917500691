import React from 'react'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import useFormWithErrors from '@hooks/useFormWithErrors'

import { Button } from '@atoms'

import EditableTextFieldRow from './EditableTextFieldRow'
import useSupporterUpdateMutation from './hooks/useSupporterUpdateMutation'

import { scopedTranslation } from '@utils/I18n'
const t = scopedTranslation('shared')
const tAttributes = scopedTranslation('attributes')

type SMSPhoneFieldRowType = {
  supporterId: string | number
  initialValue: string | null
  canEdit?: boolean
}

export default function SmsPhoneFieldRow({ supporterId, initialValue, canEdit }: SMSPhoneFieldRowType) {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState(initialValue)

  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const editButtonRef = React.useRef<HTMLButtonElement | null>(null)

  const validations = yup.object().shape({
    supporter: yup.object({
      phone: yup.string().label(tAttributes('supporter.phone')),
    }),
  })

  const {
    setErrors: setFormErrors,
    register,
    handleSubmit,
    control,
  } = useFormWithErrors({
    resolver: yupResolver(validations),
    mode: 'onTouched',
    defaultValues: {
      supporter: { phone: '' },
    },
  })

  const inputProps = register('supporter.phone')

  const { onSubmit } = useSupporterUpdateMutation({
    setFormErrors,
    supporterId,
    onSuccess: (response) => {
      setOpen(false)
      setValue(response.updateSupporter?.supporter?.primaryPhone ?? '')
      editButtonRef.current?.focus()
    },
  })

  const onCancel = () => {
    setOpen(false)
    setTimeout(() => {
      editButtonRef.current?.focus()
    })
  }

  const onEdit = () => {
    setOpen(true)
    setTimeout(() => {
      inputRef.current?.focus()
    })
  }

  return (
    <div
      className="tw-grid tw-grid-cols-5 tw-grid-rows-1 tw-gap-4 tw-w-full tw-py-9 align-items-center tw-h-28"
      data-compid="supporter-show_phone-field-row"
    >
      <div>
        <p className="tw-m-0 tw-text-gray-900 tw-font-medium tw-text-sm">{tAttributes('supporter.phone')}</p>
      </div>
      {open ? (
        <div className="tw-col-span-2">
          <EditableTextFieldRow
            {...inputProps}
            onSubmit={handleSubmit(onSubmit)}
            label={tAttributes('supporter.phone')}
            open={open}
            control={control}
            ref={(el) => {
              inputRef.current = el
              inputProps.ref(el)
            }}
            onCancel={onCancel}
          />
        </div>
      ) : (
        <div className="tw-col-span-3">
          <p>{value}</p>
        </div>
      )}

      <div className="tw-col-start-5 tw-flex tw-justify-end">
        <Button
          rank="secondary"
          leadingIcon="pencil-02"
          onClick={onEdit}
          ref={editButtonRef}
          ariaLabel={t('aria_labels.edit_object', { name: tAttributes('supporter.phone') })}
          disabled={canEdit ? open : true}
          dataCompId="supporter-show_phone-field-row_edit"
        >
          {t('buttons.edit')}
        </Button>
      </div>
    </div>
  )
}
