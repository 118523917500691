import React from 'react'
import { router } from '@inertiajs/react'
import { FormProvider } from 'react-hook-form'

import type { Group } from '@frontend/graphql/types.generated'

import type { UpdateGroupDocument } from '../features/edit-group-details/mutations.generated'
import { updateGroupMutation } from '../features/edit-group-details/mutations'

import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { yupResolver } from '@hookform/resolvers/yup'
import useFormWithErrors from '@hooks/useFormWithErrors'
import useMutation from '@hooks/useGQLMutation'

import { useErrorsStore } from '@stores/errorsStore'

import FormFullPageLayout from '@layouts/FormFullPageLayout'

import { Button } from '@atoms'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import * as FormContainer from '@molecules/FormContainer'
import BackLink from '@molecules/BackLink'

import GroupFormPage, { type ValidationSchema, validationSchema } from '../features/edit-group-details/GroupFormPage'

import { scopedTranslation } from '@utils/I18n'
import GroupDetailsAdapter from '../adapters/GroupDetailsAdapter'
const t = scopedTranslation('group_edit')
const tShared = scopedTranslation('shared')

type GroupEditProps = {
  group: Pick<
    Group,
    | 'id'
    | 'name'
    | 'description'
    | 'publicPublish'
    | 'publicDescription'
    | 'hasPhysicalAddress'
    | 'venueName'
    | 'streetAddress'
    | 'city'
    | 'postcode'
    | 'state'
    | 'country'
    | 'notifyAllAdmins'
  > & {
    pointPeople: {
      nodes: Array<{
        id: number
        fullName: string
      }>
    }
  }
  primarySiteBaseUrl: string
}

export default function GroupEdit({ group, primarySiteBaseUrl }: GroupEditProps) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const { setErrors: setFormErrors, ...formMethods } = useFormWithErrors({
    defaultValues: {
      group: GroupDetailsAdapter.fromServer(group).toFrontend(),
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })

  const { mutate } = useMutation<typeof UpdateGroupDocument>(updateGroupMutation)

  const onSubmit = async (values: ValidationSchema) => {
    clearAppErrors('editGroup')
    mutate(GroupDetailsAdapter.fromFrontend({ id: group.id, ...values.group }).toServer(), {
      onSuccess: (response) => {
        if (!response.updateGroup) return

        if (response.updateGroup.success && response.updateGroup.group) {
          router.get('/layout_redirects', { path: `/groups/${response.updateGroup.group.id}` })
        } else {
          setFormErrors({
            errorsBasePath: 'group',
            errors: response.updateGroup.errors,
            adapter: serverErrorsToFormErrors,
          })
          setAppErrors({
            errorsPath: 'editGroup',
            errors: response.updateGroup.errors,
          })
        }
      },
      onError: () => {
        setAppErrors({
          errorsPath: 'editGroup',
          errors: [
            {
              message: '',
              code: 'unknown',
            },
          ],
        })
      },
    })
  }

  return (
    <FormFullPageLayout>
      <div className="tw-flex tw-flex-col tw-gap-4 tw-align-center tw-py-16 tw-min-h-screen tw-mx-auto tw-max-w-7xl tw-font-sans">
        <AppErrorsAlert errorsPath="editGroup" />

        <FormProvider {...formMethods}>
          <FormContainer.Root>
            <FormContainer.FormCard dataCompId={'edit-group_form'}>
              <FormContainer.FormCardHeader title={t('group_details.header', { name: group.name })} />
              <FormContainer.Form>
                <GroupFormPage primarySiteBaseUrl={primarySiteBaseUrl} />
              </FormContainer.Form>
            </FormContainer.FormCard>
          </FormContainer.Root>
        </FormProvider>

        <div className="tw-flex tw-justify-center tw-gap-2">
          <BackLink to={`/groups/${group.id}`} rank="secondary" color="danger">
            {tShared('buttons.cancel')}
          </BackLink>
          <Button
            dataCompId={'group-edit_form-submit'}
            type="submit"
            size="md"
            trailingIcon="save-disk"
            onClick={formMethods.handleSubmit(onSubmit)}
          >
            {t('group_details.submit')}
          </Button>
        </div>
      </div>
    </FormFullPageLayout>
  )
}
