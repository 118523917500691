type FrontendCriteria = {
  combinator: string
  rules: Record<
    string,
    {
      enabled: boolean
      predicate: string
      values: Array<{ value: string; label: string }>
    }
  >
}

type ServerInputCriteria = {
  combinator: string
  rules: Record<
    string,
    {
      enabled: boolean
      predicate: string
      values: string[]
    }
  >
}

type ServerOutputDraftGroupCriteria = {
  combinator: string
  rules: Array<{
    property: string
    predicate: string
    values: string[]
  }>
}

type ServerOutputGroupCriteria = {
  combinator: string
  rules: {
    nodes: Array<{
      property: string
      predicate: string
      values: string[]
    }>
  }
}

export default class CriteriaAdapter {
  combinator: string
  rules: FrontendCriteria['rules']

  constructor(combinator: FrontendCriteria['combinator'], rules: FrontendCriteria['rules']) {
    this.combinator = combinator
    this.rules = rules
  }

  static fromServerDraftGroup(criteria: ServerOutputDraftGroupCriteria): CriteriaAdapter {
    const rules = criteria.rules.reduce((acc, rule) => {
      if (rule.values.length === 0) return acc

      return {
        ...acc,
        [rulePropertyAdapter(rule.property, rule.predicate)]: {
          enabled: true,
          predicate: rule.predicate,
          values: rule.values.map((value) => ({ value, label: value })),
        },
      }
    }, {})

    return new CriteriaAdapter(criteria.combinator, rules)
  }

  static fromServerGroup(criteria: ServerOutputGroupCriteria): CriteriaAdapter {
    const rules = criteria.rules.nodes.reduce((acc, rule) => {
      if (rule.values.length === 0) return acc

      return {
        ...acc,
        [rulePropertyAdapter(rule.property, rule.predicate)]: {
          enabled: true,
          predicate: rule.predicate,
          values: rule.values.map((value) => ({ value, label: value })),
        },
      }
    }, {})

    return new CriteriaAdapter(criteria.combinator, rules)
  }

  static fromFrontend(criteria: FrontendCriteria): CriteriaAdapter {
    return new CriteriaAdapter(criteria.combinator, criteria.rules)
  }

  toServer(): ServerInputCriteria {
    return {
      combinator: this.combinator,
      rules: Object.entries(this.rules).reduce((acc, [key, rule]) => {
        return {
          ...acc,
          [key]: {
            enabled: rule.enabled,
            predicate: rule.predicate,
            values: rule.values.map((value) => value.value),
          },
        }
      }, {}),
    }
  }

  toFrontend(): FrontendCriteria {
    return {
      combinator: this.combinator,
      rules: this.rules,
    }
  }
}

function rulePropertyAdapter(property: string, predicate: string) {
  if (property === 'tags' && predicate === 'not_any') {
    return 'exclusionTags'
  }

  return property
}
