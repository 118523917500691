import React from 'react'
import type { ButtonStyleProps } from '@atoms/ButtonStyles'

type NotificationBubblePropsType = Pick<ButtonStyleProps, 'rank' | 'size' | 'color'> & {
  children: string
  'aria-hidden'?: boolean | 'true' | 'false'
}

export default function NotificationBubble({ children, rank, color, size, ...props }: NotificationBubblePropsType) {
  return (
    <span
      className="tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-bg-white tw-text-primary-700 tw-rounded-full"
      {...props}
    >
      {children}
    </span>
  )
}
