import React from 'react'

import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import TagsFieldRow from './TagsFieldRow'
import { type SharedInertiaProps } from '../../pages/SupporterShow'

type TagsFormProps = {
  supporterId: string
  groupId: string
  permissions: SharedInertiaProps['permissions']
}

export default function TagsForm({ groupId, supporterId, permissions }: TagsFormProps) {
  return (
    <>
      <AppErrorsAlert errorsPath="supporterTags" />

      <TagsFieldRow groupId={groupId} supporterId={supporterId} canEdit={permissions.updateTags} />
    </>
  )
}
