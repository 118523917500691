import React from 'react'
import _ from 'lodash'
import Select from './Select'

type LabelledSelectProps<T = any> = {
  name: string
  label: string
  placeholder?: string
  errors: any
  onChange: (value: T) => void
  value: T
  options: Array<{ value: T; label: string }>
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
}

export default function LabelledSelect({ name, label, errors, onChange, value, ...props }: LabelledSelectProps) {
  const errorsById = _.get(errors, name)

  function handleChange(value: any) {
    onChange(value)
  }

  return (
    <div className="tw-flex tw-flex-col tw-justify-center tw-min-w-[0]" data-testid="custom-select">
      <label id={`${name}.label`} className="tw-mb-0 tw-pb-1 tw-text-gray-700 tw-font-medium">
        {label}
      </label>
      <Select name={name} errors={errors} value={value} onChange={handleChange} {...props} />
      {/* role="status" sets aria-live="polite" and aria-atomic="true" */}
      <div role="status" aria-relevant="all" data-testid={`${name}.error`}>
        {errorsById && (
          <p id={`${name}.error`} className="tw-text-red-500 tw-font-light tw-text-sm tw-pt-1">
            {errorsById.message}
          </p>
        )}
      </div>
    </div>
  )
}
