import Icon from '@components/utils/Icon'
import clsx from 'clsx'
import React from 'react'

// We currently have a lot of different components that make up a form, and
// we currently assemble them seperately for each form. For example, the
// FormWithErrors, Validation etc
// I'm going to use this only for the presentational structure
// of a form to start with but I'd like to look into bringing
// everything that a form needs into here so we don't need
// to compose them each time

function FormContainerRoot({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-gap-4 sm:tw-gap-20 tw-justify-center">{children}</div>
}

function FormCardContainer({ children, dataCompId }: { children: React.ReactNode; dataCompId: string }) {
  return (
    <section
      className="tw-bg-white tw-basis-[704px] tw-shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)]"
      {...(dataCompId && { 'data-compid': dataCompId })}
    >
      {children}
    </section>
  )
}

function FormCardHeader({ title }: { title: string }) {
  return (
    <header className="tw-bg-primary-700 tw-py-7 tw-px-6">
      <h2 className="tw-text-xl tw-font-bold tw-text-white">{title}</h2>
    </header>
  )
}

type FormHeaderProps = {
  title: string
  description?: string
  icon?: React.ReactNode
}
function FormHeader({ title, description, icon }: FormHeaderProps) {
  return (
    <div className="tw-grid tw-grid-cols-[40px_1fr] tw-grid-row-2 tw-gap-x-1 tw-pb-4">
      {icon && <div className="tw-col-start-1 tw-row-start-1 tw-flex tw-justify-center tw-items-center">{icon}</div>}
      <div
        className={clsx(
          'tw-row-start-1 tw-flex tw-items-center',
          icon ? 'tw-col-start-2' : 'tw-col-start-1 tw-col-span-2'
        )}
      >
        <h3 className="tw-text-xl tw-font-semibold">{title}</h3>
      </div>
      <div className="tw-col-start-2 tw-row-start-2">
        {description && <p className="tw-text-gray-600">{description}</p>}
      </div>
    </div>
  )
}

function Form({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-flex-col tw-pb-8 tw-p-8 tw-gap-4">{children}</div>
}

function PreviewCardContainer({ children, dataCompId }: { children: React.ReactNode; dataCompId: string }) {
  return (
    <section className="tw-bg-white tw-basis-[550px] tw-grow tw-px-6" {...(dataCompId && { 'data-compid': dataCompId })}>
      {children}
    </section>
  )
}

function PreviewCardHeader({ title }: { title: string }) {
  return (
    <header className="tw-pt-7">
      <h2 className="tw-text-xl tw-font-bold">{title}</h2>
    </header>
  )
}

const Root = FormContainerRoot
const FormCard = FormCardContainer
const PreviewCard = PreviewCardContainer

export { Root, FormCard, FormCardHeader, PreviewCard, PreviewCardHeader, FormHeader, Form }
