import { router } from '@inertiajs/react'

function canUseBrowserBack({ hasRefParam, refParamEqualsReferrerPage }) {
  const hasHistory = window.history.length > 1
  const referrerPageIsOurs = document.referrer.includes(window.location.host)
  return hasHistory && (refParamEqualsReferrerPage || (!hasRefParam && referrerPageIsOurs))
}

export function redirectBackOrTo(location: string) {
  return () => {
    const refParam = new URLSearchParams(window.location.search).get('ref')
    const refIsPreviousPage = refParam && decodeURIComponent(document.referrer).includes(refParam)

    const canUseBack = canUseBrowserBack({
      hasRefParam: Boolean(refParam),
      refParamEqualsReferrerPage: refIsPreviousPage,
    })

    if (canUseBack) {
      window.history.back()
    } else {
      router.visit(refParam ?? location)
    }
  }
}
