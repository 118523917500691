import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { type FieldErrors } from 'react-hook-form'

import { scopedTranslation } from '@utils/I18n'

import Icon, { type IconTypes } from '@components/utils/Icon'

const t = scopedTranslation('components.text_input')

// Supports all props included in React.InputHTMLAttributes<HTMLInputElement>
type TextInputProps = {
  name: string
  displayName: string
  placeholder?: string
  errors: FieldErrors
  required?: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
  value?: string
  defaultValue?: string
  type?: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  min?: string | number
  max?: string | number
  maxLength?: number
  minLength?: number
  pattern?: string
  disabled?: boolean
  hideLabel?: boolean
  leadingIcon?: IconTypes
  dataCompId?: string
}

const LeadingIcon = ({ type }: { type: IconTypes }) => (
  <span className="tw-pr-2">
    <Icon width={20} height={20} type={type} />
  </span>
)

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(function TextInput(props, ref) {
  const {
    name: registeredId,
    displayName,
    required,
    type,
    errors,
    hideLabel,
    leadingIcon,
    dataCompId,
    ...inputProps
  } = props
  const errorsById = _.get(errors, registeredId)

  return (
    <div
      className="tw-flex tw-flex-col tw-justify-center tw-min-w-[0] tw-w-full"
      {...(dataCompId && { 'data-compid': dataCompId })}
    >
      <label
        htmlFor={registeredId}
        className={clsx(
          'tw-mb-0 tw-pb-1 tw-text-gray-700 tw-font-medium',
          hideLabel && 'tw-sr-only',
          errorsById && 'tw-text-red-500'
        )}
      >
        {displayName} {required && t('required')}
      </label>

      <div
        className={clsx(
          'tw-flex tw-items-center',
          'tw-px-3 tw-py-2',
          'tw-bg-white',
          'tw-border tw-rounded tw-border-gray-400',
          'focus-within:tw-border-primary-400 focus-within:tw-ring-4 tw-ring-primary-100',
          'tw-placeholder-gray-500 tw-placeholder-font-normal',
          errorsById && 'tw-border-red-400'
        )}
      >
        {!!leadingIcon && <LeadingIcon type={leadingIcon} />}
        <input
          type={type ?? 'text'}
          id={registeredId}
          name={registeredId}
          required={required}
          aria-describedby={`${registeredId}.error`}
          className={clsx(
            'tw-w-full tw-font-normal tw-text-base',
            'tw-placeholder-gray-500 tw-placeholder-font-normal',
            'focus:tw-outline-none'
          )}
          ref={ref}
          {...inputProps}
        />
      </div>

      {/* role="status" sets aria-live="polite" and aria-atomic="true" */}
      <div role="status" aria-relevant="all">
        {errorsById && (
          <p id={`${registeredId}.error`} className="tw-text-red-500 tw-font-light tw-text-sm tw-pt-1">
            {errorsById.message}
          </p>
        )}
      </div>
    </div>
  )
})

export default TextInput
