import React from 'react';

import { scopedTranslation } from '@utils/I18n'
import { Button, Dialog } from '@atoms';
import Icon from '@components/utils/Icon';

import { useDeleteShiftMutation } from './useDeleteShiftMutation';

const t = scopedTranslation('components.delete_shift')

type DeleteShiftDialogProps = {
  id: string
  onShiftDeleted: () => void
}

export const DeleteShiftDialog = ({ id, onShiftDeleted }: DeleteShiftDialogProps) => {
  const { deleteShift } = useDeleteShiftMutation()

  const handleConfirmDelete = async () => {
    await deleteShift(id, onShiftDeleted)
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild={true}>
        <Button rank="link" color="danger" ariaLabel={t('delete_shift')}>
          <Icon type="trash-01" />
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>{t('delete_shift')}</Dialog.Title>
        </Dialog.Header>
        <p>{t('delete_shift_confirmation')}</p>
        <Dialog.Footer>
          <Dialog.Close>{t('cancel')}</Dialog.Close>
          <Button onClick={handleConfirmDelete} color="danger">
            {t('delete')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
