import * as React from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import clsx from 'clsx'

// import { Button } from '@atoms'
// import Icon from '@components/utils/Icon'

const Sheet: React.FC<DialogPrimitive.DialogProps> = DialogPrimitive.Root

const SheetTrigger: React.ElementType<DialogPrimitive.DialogTriggerProps & React.RefAttributes<HTMLButtonElement>> =
  DialogPrimitive.Trigger

const SheetClose: React.ElementType<DialogPrimitive.DialogTriggerProps & React.RefAttributes<HTMLButtonElement>> =
  DialogPrimitive.Close

const SheetPortal: React.ElementType<
  { container: any } & DialogPrimitive.DialogTriggerProps & React.RefAttributes<HTMLButtonElement>
> = DialogPrimitive.Portal

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={clsx(
      'tw-fixed tw-inset-0 tw-z-50 tw-bg-background/80 tw-backdrop-blur-sm',
      'data-[state=open]:tw-animate-in data-[state=open]:tw-fade-in-0',
      'data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0'
    )}
    {...props}
    ref={ref}
  />
))

SheetOverlay.displayName = DialogPrimitive.Overlay.displayName

type SheetContentProps = { portalContainer?: any } & React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>

const SheetContent = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, SheetContentProps>(
  ({ children, portalContainer, ...props }, ref) => (
    <SheetPortal container={portalContainer}>
      <SheetOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={clsx(
          'tw-fixed tw-z-50 tw-overflow-y-auto',
          'tw-bg-white',
          'tw-inset-y-0 tw-right-0 tw-h-full tw-w-full tw-border-l sm:tw-w-2/3',
          'data-[state=open]:tw-animate-in data-[state=open]:tw-slide-in-from-right data-[state=open]:tw-duration-500',
          'data-[state=closed]:tw-animate-out data-[state=closed]:tw-slide-out-to-right data-[state=closed]:tw-duration-300',
          'tw-gap-4 tw-shadow-lg tw-transition tw-ease-in-out'
        )}
        {...props}
      >
        {/* <DialogPrimitive.Close asChild>
        <div className="tw-flex tw-w-full tw-justify-end tw-py-2">
          <Button rank="secondary" size="sm" leadingIcon="x-close">
            Close
          </Button>
        </div>
      </DialogPrimitive.Close> */}
        <div>{children}</div>
      </DialogPrimitive.Content>
    </SheetPortal>
  )
)
SheetContent.displayName = DialogPrimitive.Content.displayName

const SheetHeader = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx('tw-flex tw-flex-col tw-space-y-2 tw-text-center sm:tw-text-left')} {...props} />
)
SheetHeader.displayName = 'SheetHeader'

const SheetFooter = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={clsx('tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2')} {...props} />
)
SheetFooter.displayName = 'SheetFooter'

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ children, ...props }, ref) => (
  <DialogPrimitive.Title ref={ref} {...props}>
    <header className="tw-border-b tw-border-gray-100 tw-py-7 tw-px-6 tw-bg-gradient-to-b tw-from-primary-700 tw-to-primary-600">
      {/* <div className="tw-bg-gradient-to-b tw-from-primary-700 tw-to-primary-600 tw-h-2"></div> */}
      <div className="tw-h-full tw-flex tw-items-center">
        <div>
          <h2 className="tw-text-xl tw-font-bold tw-text-white">{children}</h2>
        </div>
      </div>
    </header>
  </DialogPrimitive.Title>
))
SheetTitle.displayName = DialogPrimitive.Title.displayName

// const SheetDescription = React.forwardRef<
//   React.ElementRef<typeof DialogPrimitive.Description>,
//   React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
// >(({ ...props }, ref) => (
//   <DialogPrimitive.Description ref={ref} className={clsx('tw-text-sm tw-text-muted-foreground')} {...props} />
// ))
// SheetDescription.displayName = DialogPrimitive.Description.displayName

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  // SheetDescription,
}
