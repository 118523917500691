import clsx from 'clsx'
import { Link as InertiaLink } from '@inertiajs/react'
import React from 'react'

export function generatePaginationLink(pageNumber: number) {
  const pathname = window.location.pathname
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.set('page', String(pageNumber))
  return `${pathname}?${searchParams.toString()}`
}

type PaginationBubbleType = {
  pageNumber: number
  current: boolean
  only: string[]
}

export default function PaginationBubble({ pageNumber, current, only }: PaginationBubbleType) {
  return (
    <li
      className={clsx(
        'tw-w-fit tw-h-fit tw-rounded-full text-white tw-font-bold tw-text-xs',
        '[&>*]:tw-inline-flex [&>*]:tw-items-center [&>*]:tw-justify-center [&>*]:tw-w-7 [&>*]:tw-h-7 [&>*]:tw-rounded-full',
        current ? 'tw-bg-primary-700 [&>*]:tw-pointer-events-none' : 'tw-bg-gray-400'
      )}
    >
      <InertiaLink only={only} href={generatePaginationLink(pageNumber)}>
        {pageNumber}
      </InertiaLink>
    </li>
  )
}
