import { useGraphQLClient } from "@utils/GraphQLClientProvider"
import { useQuery as useReactQueryQuery, type QueryFunction } from "@tanstack/react-query"
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { type Variables } from "graphql-request"

type useQueryProps = {
  key: string;
  query: TypedDocumentNode<unknown, Variables>;
  variables?: Variables;
}

export default function useQuery<Tdata>({key, query, variables = {}}: useQueryProps) {
  const client = useGraphQLClient()
  const queryFn: QueryFunction<Tdata> = async () => {
    const data = await client.request<Tdata>(query, variables)
    return data
  }
  return useReactQueryQuery({
    queryKey: [key],
    queryFn
  })
}