import { useForm } from "react-hook-form"
import { type FieldValues, type UseFormProps, type UseFormReturn } from 'react-hook-form/dist/types'
import { type FieldPath } from 'react-hook-form/dist/types/path'
import { type ErrorOption } from 'react-hook-form/dist/types/errors'

type AdapterFunction<T> = ({errorsBasePath, errors}: {errorsBasePath: string, errors: T}) => Record<string, ErrorOption>

type UseFormCustomReturn<
  T,
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined
> = UseFormReturn<TFieldValues, TContext, TTransformedValues> & {
  setErrors: (options: {errorsBasePath: string, errors: T, adapter: AdapterFunction<T> }) => void
}

export default function useFormWithErrors<
  T,
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined
>(props: UseFormProps<TFieldValues, TContext>): UseFormCustomReturn<T, TFieldValues, TContext, TTransformedValues> {
  const methods = useForm(props)

  // For errors to correctly update react-hook-form requires that it be access and destructured.
  //  doing this means the errors will be updated when they are returned from the hook.
  //  see: https://github.com/react-hook-form/react-hook-form/issues/6845
  const { formState: { errors } } = methods

  function setErrors({errorsBasePath, errors, adapter}: {errorsBasePath: string, errors: T, adapter: AdapterFunction<T>}) {
    const formErrors = adapter({errors, errorsBasePath})
    for(const [key, value] of Object.entries(formErrors)) {
      methods.setError(key as FieldPath<TFieldValues>, value)
    }
  }

  return {
    ...methods,
    setErrors
  }
}