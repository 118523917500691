import useMutation from '@hooks/useGQLMutation';
import { useErrorsStore, type ErrorType } from '@stores/errorsStore';
import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter';

import { editShiftMutation } from './mutations';

export const useEditShiftMutation = () => {
  const { setErrors, clearErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }));

  const { mutate } = useMutation(editShiftMutation);

  const editShift = async (
    id: string,
    startTime: string,
    endTime: string,
    roleName: string,
    onSuccess: () => void,
    setFormErrors: (errors: any) => void
  ) => {
    clearErrors('editShift')
    mutate(
      {
        input: {
          id,
          shift: {
            startTime,
            endTime,
            roleName,
            draft: true,
          },
        },
      },
      {
        onSuccess: (response) => {
          if (response?.editShift?.shift) {
            onSuccess()
          } else {
            handleErrors(
              (response?.editShift?.errors as ErrorType[]) || [
                { message: 'An unknown error occurred', code: 'unknown' },
              ],
              setFormErrors
            )
          }
        },
        onError: () => {
          handleErrors([{ message: 'An unknown error occurred', code: 'unknown' }], setFormErrors)
        },
      }
    )
  }

  const handleErrors = (errors: ErrorType[], setFormErrors: (errors: any) => void) => {
    setErrors({ errorsPath: 'editShift', errors });
    setFormErrors({
      errorsBasePath: 'shifts',
      errors,
      adapter: serverErrorsToFormErrors,
    });
  };

  return { editShift };
};
