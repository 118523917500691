import React from 'react'
import Select, { components } from 'react-select'
import type { IndicatorsContainerProps, MenuListProps } from 'react-select'
import _ from 'lodash'
import { clsx } from 'clsx'
import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('components.select')

type ExtraProps = {
  selectProps: {
    isReadOnly: boolean
    rank?: 'primary' | 'secondary'
  }
}

const MenuList = ({ children, ...props }: MenuListProps<any> & ExtraProps) => (
  <div data-testid="select-menu-list">
    <components.MenuList {...props}>{children}</components.MenuList>
  </div>
)

type SelectProps<T = any> = {
  name: string
  placeholder?: string
  errors: any
  onChange: (value: T) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  value: T
  options: Array<{ value: T; label: string }>
  disabled?: boolean
  rank?: 'primary' | 'secondary'
}

export default function CustomSelect({
  name,
  errors,
  onChange,
  value,
  disabled,
  onBlur: propsOnBlur,
  rank = 'primary',
  ...props
}: SelectProps) {
  const errorsById = _.get(errors, name)

  const newProps = { ...props, isDisabled: disabled, rank }

  function handleChange(option, _action) {
    onChange(option.value)
  }

  // This is a workaround for a bug caused by an interaction between react select and radix UI dialog
  // This causes focus to not be able to leave the select component when it's in a dialog
  // See: https://github.com/JedWatson/react-select/issues/5732
  const onBlurWorkaround = (event: React.FocusEvent<HTMLInputElement>) => {
    const element = event.relatedTarget
    if (element && (element.tagName === 'A' || element.tagName === 'BUTTON' || element.tagName === 'INPUT')) {
      ;(element as HTMLElement).focus()
    }
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    propsOnBlur(event)
    onBlurWorkaround(event)
  }

  return (
    <Select
      onBlur={onBlur}
      components={{ MenuList }}
      aria-errormessage={`${name}.error`}
      aria-invalid={errorsById ? 'true' : 'false'}
      onChange={handleChange}
      aria-labelledby={`${name}.label`}
      value={props.options.find((option) => option.value === value)}
      placeholder={t('placeholder')}
      classNames={{
        control: () =>
          'tw-border tw-rounded tw-border-gray-400 focus-within:tw-border-primary-400 focus-within:tw-ring-4 tw-ring-primary-100',
        input: () => 'tw-m-0 tw-p-0',
        placeholder: () => 'tw-whitespace-nowrap',
        singleValue: () => 'tw-m-0',
        valueContainer: () => 'tw-px-3 tw-py-2',
        indicatorsContainer: (props: IndicatorsContainerProps<any> & ExtraProps) =>
          clsx(props.selectProps.isReadOnly && 'tw-hidden'),
      }}
      {...newProps}
    />
  )
}
