import React from 'react'
import { useController, useWatch, type Control, type UseFieldArrayUpdate } from 'react-hook-form'

import { scopedTranslation } from '@utils/I18n'

import { Select } from '@atoms'

import { type FilterFormValues } from './FilterDialog'
import { type AvailableFilters } from './stores/filterStoreSlice'

const t = scopedTranslation('components.filter_dialog')

export type FieldProps = {
  index: number
  filteringOptions: AvailableFilters
  control: Control<FilterFormValues>
  update: UseFieldArrayUpdate<FilterFormValues, 'rules'>
}

const PropertyField = ({ control, index, filteringOptions, update }: FieldProps) => {
  const filter = useWatch({ control, name: `rules.${index}` })
  const fieldName = `rules.${index}` as const
  const labelName = `${fieldName}.label`

  const {
    field: { ref: _ref, value: _value, ...field },
    formState: { errors: _errors },
  } = useController({
    name: fieldName,
    control,
    defaultValue: filter,
  })

  return (
    <>
      <label className="tw-sr-only" id={labelName}>
        {t('labels.property')}
      </label>
      <Select
        options={Object.keys(filteringOptions).map((value) => ({
          value,
          label: filteringOptions[value].label,
        }))}
        errors={[]}
        {...field}
        onChange={(property: string) => {
          const predicates = filteringOptions[property].predicates
          update(index, { property, predicate: predicates[0].value, values: predicates[0].defaultValue ?? null })
        }}
        value={filter.property}
      />
    </>
  )
}

export default PropertyField
