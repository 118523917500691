import React from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import AsyncSelect from '@components/molecules/AsyncSelect';
import { scopedTranslation } from '@utils/I18n';

import { useShiftsStore } from '@frontend/contexts/events_management/pages/ShiftsIndex'
import useSupporterSearchQuery from './useSupporterSearchQuery'

export type SupporterOption = {
  id: string
  value: string
  label: string
}

type SupporterSelectProps = {
  field: ControllerRenderProps<any, 'supporterOption'>
}

const t = scopedTranslation('components.assign_shift')

export const SupporterSelect: React.FC<SupporterSelectProps> = ({ field }) => {
  const { querySupporter } = useSupporterSearchQuery()
  const groupId = useShiftsStore((state) => state.groupId)

  const loadOptions = async (inputValue: string): Promise<SupporterOption[]> => {
    const results = await querySupporter({ nameInput: inputValue, groupId: groupId })
    return results
  }

  return (
    <AsyncSelect
      displayName={t('search_label')}
      placeholder="Start typing to search for a supporter"
      field={field}
      isCreatable={false}
      isMulti={false}
      loadOptions={loadOptions}
      defaultOptions={true}
    />
  )
}

export default SupporterSelect;