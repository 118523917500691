import React, { type PropsWithChildren } from 'react'
import useSlots from '@hooks/useSlots'
import { clsx } from 'clsx'

function Actions({ children, dataCompId }: { children: React.ReactNode; dataCompId?: string }) {
  const slots = useSlots(children)
  return (
    <div
      className="tw-flex tw-justify-between tw-py-2 tw-flex-wrap tw-gap-3"
      {...(dataCompId && { 'data-compid': `${dataCompId}-actions` })}
    >
      <span className="tw-inline-flex tw-gap-3">{slots.primary}</span>
      <span className="tw-inline-flex tw-gap-3">{slots.secondary}</span>
    </div>
  )
}

type InfoCardProps = {
  listItem?: boolean
  dataCompId?: string
  'data-testid'?: string
}

export default function InfoCard({ children, listItem, dataCompId, ...props }: PropsWithChildren<InfoCardProps>) {
  const slots = useSlots(children)

  return (
    <div
      {...props}
      className={clsx(
        'tw-flex tw-flex-col tw-p-6 tw-pb-4 tw-gap-4 tw-bg-white tw-w-full',
        !listItem && 'tw-border tw-border-gray-200 tw-shadow-sm tw-rounded-xl tw-overflow-hidden'
      )}
      {...(dataCompId && { 'data-compid': dataCompId })}
    >
      <div className="tw-min-h-[8rem] tw-gap-4 tw-flex tw-flex-col">
        <div className="tw-flex tw-gap-4 tw-justify-between">
          <div className="tw-flex tw-flex-col tw-gap-1">{slots.heading}</div>

          {slots.stats && (
            <div
              className={clsx(
                'tw-text-gray-900 tw-font-normal tw-text-sm tw-rounded-md tw-border tw-border-gray-300 tw-shadow-sm tw-flex tw-min-w-[20rem] tw-h-fit',
                '[&>*]:tw-border-r [&>*]:tw-border-gray-300 last:[&>*]:tw-border-0 [&>*]:tw-px-4 [&>*]:tw-py-2 [&>*]:tw-h-fit'
              )}
              {...(dataCompId && { 'data-compid': `${dataCompId}-stats` })}
            >
              {slots.stats}
            </div>
          )}
        </div>
        <div className="tw-py-2">{slots.main}</div>
      </div>

      {slots.actions && (
        <>
          <div className="tw-border-t tw-border-gray-200 tw-w-full"></div>

          <Actions dataCompId={dataCompId}>{slots.actions}</Actions>
        </>
      )}
    </div>
  )
}
