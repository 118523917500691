import React, { type ReactNode } from 'react'

type SlotProps = {
  children?: any
  name: string
}

export function Slot({ children, name }: SlotProps) {
  return children
}

function elementIsSlot(element: React.ReactElement): element is React.ReactElement<SlotProps> {
  return element.type === Slot
}

function calculateSlots(children) {
  type SlotsType = Record<string, ReactNode>
  const slots: SlotsType = {}
  const content: React.ReactNode[] = []

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) return

    if (elementIsSlot(child)) {
      const name = child.props.name
      slots[name] = child.props.children
    } else {
      content.push(child)
    }
  })

  const results: SlotsType & { content: React.ReactNode[] } = {
    ...slots,
    content,
  }

  return results
}

export default function useSlots(children) {
  return calculateSlots(children)
}
