import React from 'react'
import { useController } from 'react-hook-form'

import { Button } from '@atoms'

import { scopedTranslation } from '@utils/I18n'
import AsyncSelect from '@molecules/AsyncSelect'
import type { SelectInstance } from 'react-select'
const t = scopedTranslation('shared')

type EditableTagFiltersFieldProps = {
  name: string
  label: string
  tagFilters: Array<{
    id: string
    name: string
  }>
  onSubmit: (data: any) => any
  onCancel: () => void
  control: any
}

const EditableTagFiltersField = React.forwardRef<SelectInstance, EditableTagFiltersFieldProps>(
  function EditableTagFiltersField({ name, label, tagFilters, onCancel, onSubmit, control }, ref) {
    const { field } = useController({ name, control })

    function cancel() {
      onCancel()
    }

    return (
      <form onSubmit={onSubmit}>
        <div className="tw-flex tw-gap-2 tw-items-center">
          <div className="tw-flex-1">
            <AsyncSelect
              dataTestId={'tags-select'}
              isSearchable={false}
              displayName={'Tag Filters'}
              hideLabel={true}
              defaultOptions={tagFilters.map((tagFilter) => ({ value: tagFilter.id, label: tagFilter.name }))}
              field={field}
              noOptionsMessage={'No more tag filters available'}
              ref={ref}
            />
          </div>
          <span className="tw-flex tw-gap-2">
            <Button
              size="sm"
              rank="primary"
              type="submit"
              onClick={onSubmit}
              ariaLabel={t('aria_labels.save_object', { name: label })}
            >
              {t('buttons.save', { name: label })}
            </Button>
            <Button
              size="sm"
              rank="primary"
              color="danger"
              onClick={cancel}
              ariaLabel={t('aria_labels.cancel_editing', { name: label })}
            >
              {t('buttons.cancel', { name: label })}
            </Button>
          </span>
        </div>
      </form>
    )
  }
)
export default EditableTagFiltersField
