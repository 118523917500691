import gql from 'graphql-tag';

export const deleteShiftMutation = gql`
  mutation DeleteShift($input: DeleteShiftInput!) {
    deleteShift(input: $input) {
      success
      errors
    }
  }
`;
