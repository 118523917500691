import useMutation from '@hooks/useGQLMutation';
import { useErrorsStore, type ErrorType } from '@stores/errorsStore';

import { deleteShiftMutation } from './mutations';

export const useDeleteShiftMutation = () => {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }));

  const { mutate } = useMutation(deleteShiftMutation);

  const deleteShift = async (id: string, onSuccess: () => void) => {
    clearAppErrors('deleteShift')
    mutate(
      {
        input: { id },
      },
      {
        onSuccess: (response) => {
          if (response?.deleteShift?.success) {
            onSuccess()
          } else {
            const errors = (response?.deleteShift?.errors as ErrorType[]) || [
              { message: 'An unknown error occurred', code: 'unknown' },
            ]
            setAppErrors({ errorsPath: 'deleteShift', errors })
          }
        },
        onError: () => {
          const errors = [{ message: 'An unknown error occurred', code: 'unknown' }]
          setAppErrors({ errorsPath: 'deleteShift', errors })
        },
      }
    )
  }

  return { deleteShift };
};
