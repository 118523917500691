import React from 'react'

import Tag from '@atoms/Tag'

import type { GroupsShowType } from '../../pages/GroupShow'

import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('components')
const tShared = scopedTranslation('shared')

type CriteriaType = GroupsShowType['group']['criteria']
type RulesType = CriteriaType['rules']['nodes']
type RuleComponentProps = { rule: RulesType[0]; tagSize?: 'xs' | 'sm' }
type GroupCriteriaProps = { criteria: CriteriaType }

const TagRule = ({ rule, tagSize = 'sm' }: RuleComponentProps) => {
  return (
    <p className="tw-grid tw-grid-cols-[max-content_1fr]">
      <span className="tw-pr-1 tw-inline-flex tw-items-center">
        <strong className="tw-font-bold">{t(`group_criteria.properties.${rule.property}`)}:&nbsp;</strong>
        <span>{t(`group_criteria.predicates.${rule.predicate}`)}&nbsp;</span>
      </span>
      <span className="tw-inline-flex tw-items-center tw-flex-wrap tw-gap-2">
        {rule.values.map((tag) => (
          <Tag key={tag} size={tagSize}>
            {tag}
          </Tag>
        ))}
      </span>
    </p>
  )
}

const GenericRule = ({ rule }: RuleComponentProps) => {
  return (
    <p className="tw-grid tw-grid-cols-[max-content_1fr]">
      <span>
        <span>
          <strong className="tw-font-bold">{t(`group_criteria.properties.${rule.property}`)}: </strong>
          <span>{t(`group_criteria.predicates.${rule.predicate}`)}&nbsp;</span>
        </span>
        {rule.values.join(', ')}
      </span>
    </p>
  )
}

export const RuleComponent = ({ rule, tagSize }: RuleComponentProps) => {
  switch (rule.property) {
    case 'tags':
      return <TagRule rule={rule} tagSize={tagSize} />
    default:
      return <GenericRule rule={rule} />
  }
}

const GroupCriteria = ({ criteria }: GroupCriteriaProps) => {
  const {
    combinator,
    rules: { nodes: rules },
  } = criteria

  return (
    <div className="tw-flex tw-flex-col tw-gap-1 tw-text-sm">
      <p className="tw-text-gray-blue-500 tw-pb-1">
        <strong className="tw-text-xs tw-font-bold tw-uppercase">{t('group_criteria.criteria')} </strong>
        <span>({t(`group_criteria.combinator_explanation.${combinator.toLowerCase()}`)})</span>
      </p>
      {rules.length === 0 ? (
        <p className="tw-mb-1 tw-text-gray-900">
          <strong className="tw-font-bold tw-font">{tShared('labels.none')} </strong>
          <span>({t('group_criteria.all_supporters_included')})</span>
        </p>
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-3">
          {rules.map((rule) => (
            <RuleComponent key={rule.id} rule={rule} />
          ))}
        </div>
      )}
    </div>
  )
}

export default GroupCriteria
