import React from 'react'
import { useController, useWatch, type UseFormRegister, type ControllerRenderProps, type FieldValues } from 'react-hook-form'

import { scopedTranslation } from '@utils/I18n'

import { TextInput } from '@atoms'
import AsyncSelect from '@molecules/AsyncSelect'
import TagsSelect from '@molecules/TagsSelect'

import { type FieldProps } from './PropertyField'
import { type FilterFormValues } from './FilterDialog'
import { DatePicker } from '@components/molecules/DatePicker'

const t = scopedTranslation('components.filter_dialog')

export type ValueFieldProps = Omit<FieldProps, 'update'> & {
  register: UseFormRegister<FilterFormValues>
}

const ValueField = ({ register, control, index, filteringOptions }: ValueFieldProps) => {
  const filter = useWatch({ control, name: `rules.${index}` })
  const { property, predicate } = filter
  const fieldName = `rules.${index}.values` as const

  const { field } = useController({
    control,
    name: fieldName,
  })

  if (!property || !predicate) return null

  const filterType = filteringOptions[property].type
  const predicateInfo = filteringOptions[property].predicates.find((filteringOptionsPredicate) => filteringOptionsPredicate.value === predicate)
  const predicateIsArray = predicateInfo?.array

  switch (filterType) {
    case 'string':
      if (predicate === 'present' || predicate === 'blank' || predicate === 'true') {
        return <></>
      } else if (predicateIsArray) {
        if (property === 'tags') {
          return <TagsSelect field={field} displayName={t('labels.values')} hideLabel={true} />
        }

        // Stub the search endpoint by returning an empty array of existing postcodes
        const loadOptions = async () => {
          return []
        }
        return (
          <AsyncSelect
            field={field}
            loadOptions={loadOptions}
            displayName={t('labels.values')}
            hideLabel={true}
            createOptionPrefix=""
          ></AsyncSelect>
        )
      } else {
        return (
          <TextInput
            type="text"
            displayName={t('labels.search_value')}
            hideLabel={true}
            errors={[] as any}
            {...register(fieldName)}
          />
        )
      }
    case 'date':
      return (
        <DatePicker field={field as ControllerRenderProps<FieldValues, string>} />
      )
  }
  return <></>
}

export default ValueField
