import { type User } from '../../../../../graphql/types.generated'
import { create } from 'zustand'
import { shallow } from 'zustand/shallow'
import { useEffect } from 'react'

export type UserType = Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | '__typename'>

type StoreState = {
  captains: UserType[]
  removedCaptains: UserType[]
}

type StoreActions = {
  removeCaptain: (captain: UserType) => void
  addCaptains: (...captains: UserType[]) => void
  setState: (state: Partial<StoreState>) => void
}

export type CurrentCaptainsStoreType = StoreState & StoreActions

const useStore = create<StoreState & StoreActions>((set) => ({
  captains: [],
  removedCaptains: [],
  removeCaptain: (captain: UserType) => {
    set(({ captains, removedCaptains }) => ({
      captains: captains.filter((c) => c.email !== captain.email),
      removedCaptains: [...removedCaptains, captain],
    }))
  },
  addCaptains: (...newCaptains: UserType[]) => {
    const captainsEmails = newCaptains.map((c) => c.email)
    set(({ captains, removedCaptains }) => ({
      captains: [...captains, ...newCaptains],
      removedCaptains: removedCaptains.filter((c) => !captainsEmails.includes(c.email)),
    }))
  },
  setState: (initialState) => {
    set(() => initialState)
  },
}))

export default function useCurrentCaptainsStore(state: Partial<StoreState>) {
  const { captains, removedCaptains, addCaptains, removeCaptain, setState } = useStore(
    (state) => ({ ...state }),
    shallow
  )

  useEffect(() => {
    setState(state || {})
  }, [])

  return {
    captains,
    removedCaptains,
    addCaptains,
    removeCaptain,
    setState,
  }
}
