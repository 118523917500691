import gql from 'graphql-tag'

export const createSupporterNoteMutation = gql`
  mutation createSupporterNote($content: String!, $personId: ID!) {
    createSupporterNote(input: { content: $content, personId: $personId }) {
      supporterNote {
        author
        content
      }
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`
