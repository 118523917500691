import { type Control, useController } from 'react-hook-form'
import type { IndicatorSeparatorProps } from 'react-select'

import { ToggleableField } from '@molecules/ToggleableField'
import TagsSelect from '@molecules/TagsSelect'
import AsyncSelect from '@molecules/AsyncSelect'
import { scopedTranslation } from '@utils/I18n'
import type { SelectOption } from '@utils/types'

const tShared = scopedTranslation('shared')

type CriteriaRule = Record<
  string,
  {
    values: Array<{
      value: string
      label: string
    }>
    enabled: boolean
    predicate: string
  }
>

export type Criteria = {
  criteria: {
    combinator: string
    rules: CriteriaRule
  }
}

type CriteriaRuleFieldProps = {
  name: string
  predicate: string
  label: string
  tooltip: string
  control: Control<Record<'group', Criteria>>
}

// NOTE: This heavily duplicates the TagsField but doesn't include
// the predicate dropdown with the "has any" and "has all" options
// (and includes the tooltip prop now removed from the TagsField)
function NotAnyTagsField({ name, predicate, label, tooltip, control }: CriteriaRuleFieldProps) {
  const fieldName: `group.criteria.rules.${string}` = `group.criteria.rules.${name}`

  useController({
    name: fieldName,
    defaultValue: {
      values: [],
      enabled: false,
      predicate,
    },
    control,
  })

  const { field: enabledField } = useController({
    name: `${fieldName}.enabled`,
    control,
  })

  const { field: tagsField } = useController({
    name: `${fieldName}.values`,
    control,
  })

  // NOTE: This is a custom display name for the TagsSelect component.
  // The current possible values of `name` are "tag" or "exclusionTag"
  const resourceName = tShared(`resource_names.${name}`)

  return (
    <ToggleableField
      label={label}
      name={fieldName}
      tooltip={tooltip}
      isEnabled={enabledField.value}
      setEnabled={(value: boolean) => {
        enabledField.onChange(value)
      }}
    >
      <TagsSelect field={tagsField} displayName={resourceName} hideLabel={true} />
    </ToggleableField>
  )
}

function TagsField({ name, predicate, label, control }: Omit<CriteriaRuleFieldProps, 'tooltip'>) {
  const fieldName: `group.criteria.rules.${string}` = `group.criteria.rules.${name}`

  useController({
    name: fieldName,
    defaultValue: {
      values: [],
      enabled: false,
      predicate,
    },
    control,
  })

  const { field: predicateField } = useController({
    name: `${fieldName}.predicate`,
    control,
  })

  const { field: enabledField } = useController({
    name: `${fieldName}.enabled`,
    control,
  })

  const { field: tagsField } = useController({
    name: `${fieldName}.values`,
    control,
  })

  const predicateOptions = [
    { value: 'has_any', label: 'Any' },
    { value: 'has_all', label: 'All' },
  ]

  const tooltip =
    predicateField.value === 'has_any'
      ? tShared('group_criteria.tooltips.tagged_with_any')
      : tShared('group_criteria.tooltips.tagged_with_all')

  const IndicatorSeparator = (_props: IndicatorSeparatorProps<any>) => {
    return <></>
  }

  // NOTE: This is a custom display name for the TagsSelect component.
  // The current possible values of `name` are "tag" or "exclusionTag"
  const resourceName = tShared(`resource_names.${name}`)

  return (
    <ToggleableField
      label={label}
      name={fieldName}
      tooltip={tooltip}
      isEnabled={enabledField.value}
      setEnabled={(value: boolean) => {
        enabledField.onChange(value)
      }}
    >
      {name === 'tags' && (
        <AsyncSelect
          isMulti={false}
          isSearchable={false}
          isCreatable={false}
          hideLabel={true}
          placeholder={''}
          displayName={tShared('group_criteria.labels.tag_filtering_method')}
          field={predicateField}
          defaultOptions={predicateOptions}
          className={'!tw-h-auto !tw-w-32'}
          onChange={(value) => {
            predicateField.onChange((value as SelectOption).value)
          }}
          value={predicateOptions.find((option) => option.value === predicateField.value)}
          components={{ IndicatorSeparator }}
        ></AsyncSelect>
      )}
      <TagsSelect field={tagsField} displayName={resourceName} hideLabel={true} />
    </ToggleableField>
  )
}

// NOTE: This heavily duplicates the TagsField but doesn't include
// the predicate dropdown with the "has any" and "has all" options
// (and some other minor customisations)
function PostcodesField({ name, predicate, label, tooltip, control }: CriteriaRuleFieldProps) {
  const fieldName: `group.criteria.rules.${string}` = `group.criteria.rules.${name}`

  // Stub the search endpoint by returning an empty array of existing postcodes
  const loadOptions = async () => {
    return []
  }

  useController({
    name: fieldName,
    defaultValue: {
      values: [],
      enabled: false,
      predicate,
    },
    control,
  })

  const { field: enabledField } = useController({
    name: `${fieldName}.enabled`,
    control,
  })

  const { field: postcodesField } = useController({
    name: `${fieldName}.values`,
    control,
  })

  const resourceName = tShared('resource_names.postcodes')

  return (
    <ToggleableField
      label={label}
      name={fieldName}
      tooltip={tooltip}
      isEnabled={enabledField.value}
      setEnabled={(value: boolean) => {
        enabledField.onChange(value)
      }}
    >
      <AsyncSelect
        hideLabel={true}
        // displayName={label}
        displayName={resourceName}
        field={postcodesField}
        loadOptions={loadOptions}
        noOptionsMessage={tShared('placeholders.placeholder_add', { name: resourceName.toLowerCase() })}
        createOptionPrefix={tShared('buttons.add')}
      />
    </ToggleableField>
  )
}

type CriteriaEditProps = {
  control: Control<Record<'group', Criteria>>
}

export default function CriteriaEdit({ control }: CriteriaEditProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <TagsField
        name="tags"
        predicate="has_any"
        label={tShared('group_criteria.labels.tagged_with')}
        control={control}
      />
      <NotAnyTagsField
        name="exclusionTags"
        predicate="not_any"
        label={tShared('group_criteria.labels.not_tagged_with')}
        tooltip={tShared('group_criteria.tooltips.not_tagged_with')}
        control={control}
      />
      <PostcodesField
        name="zip"
        predicate="in"
        label={tShared('group_criteria.labels.postcodes')}
        tooltip={tShared('group_criteria.tooltips.postcodes')}
        control={control}
      />{' '}
    </div>
  )
}
