import React from 'react'
import Alert401 from './Alert401'
import Alert422 from './Alert422'
import Alert500 from './Alert500'

import { useErrorsStore } from '@stores/errorsStore'
type AlertSwitcherProps = {
  errorsPath: string
}

export default function AppErrorsAlert({ errorsPath }: AlertSwitcherProps) {
  const { serverErrors, clearErrors } = useErrorsStore(({ serverErrors, clearErrors }) => ({
    serverErrors,
    clearErrors,
  }))
  const errors = serverErrors[errorsPath] ?? []

  function onClose() {
    clearErrors(errorsPath)
  }

  if (errors.length === 0) {
    return null
  }

  if (errors.length === 1) {
    switch (errors[0].code) {
      case 'unauthorized':
        return <Alert401 onClose={onClose} />
      case 'unknown':
        return <Alert500 onClose={onClose} />
    }
  }

  return <Alert422 onClose={onClose} errors={errors} />
}
