import Icon, { type IconTypes } from '../../utils/Icon'
import React from 'react'

type IconButtonProps = {
  icon: IconTypes
  onClick: (any) => any
  disabled: boolean
  active: boolean
  ariaLabel?: string
}

export default function IconButton({ icon, onClick, disabled, active, ariaLabel }: IconButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`tw-p-1 tw-h-fit rounded-sm ${active ? 'tw-bg-gray-300' : ''} ${disabled ? 'tw-text-gray-300' : ''}`}
      type="button"
      aria-label={ariaLabel}
    >
      <Icon dataTestId="trailing-icon" type={icon} className="tw-h-4 tw-w-4 tw-mt-0.5" />
    </button>
  )
}
