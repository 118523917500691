import { UserError } from "../../frontend/graphql/types.generated"
import { ErrorOption } from "react-hook-form/dist/types/errors"

export function serverErrorsToFormErrors({errors, errorsBasePath}: {errors: UserError[], errorsBasePath: string}): Record<string, ErrorOption> {
  const errorsBasePathWithSeperator = errorsBasePath ? errorsBasePath + '.' : ''

  return errors.reduce((acc, error) => {
    let messageWithSolution = error.message
    if(error.solution) messageWithSolution += `. ${error.solution}`

    const errorName = error.field || error.code
    const errorPath = `${errorsBasePathWithSeperator}${errorName}`
    acc[errorPath] = {
      type: error.code,
      message: messageWithSolution
    }

    return acc
  }, {})
}
