import React from 'react'
import _ from 'lodash'
import clsx from 'clsx'
import { type Control, type FieldErrors, useController, useForm } from 'react-hook-form'
import * as RCheckbox from '@radix-ui/react-checkbox'
import Icon from '@components/utils/Icon'

type CheckboxProps = {
  control?: Control<any>
  name: string
  errors?: FieldErrors
  required?: boolean
  // disabled?: boolean
  defaultChecked?: boolean
  // onClick is handled natively by radix Checkbox
  // but when it's rendered as the trigger for another
  // radix component (like Collapsible), it will pass it down
  // to take control of it (no need to do pass it manually if using radix)
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: React.ReactNode
}

const Checkbox = ({
  control,
  name,
  onClick,
  children: labelContent,
  defaultChecked,
  errors,
  required = false,
}: CheckboxProps) => {
  const { control: fakeControl } = useForm()
  const { field } = useController({ name, control: control ?? fakeControl })
  const errorsById = _.get(errors, name)

  return (
    <>
      <RCheckbox.Root
        {...field}
        onClick={onClick}
        checked={defaultChecked}
        onCheckedChange={field.onChange}
        id={name}
        required={required}
        aria-describedby={`${name}.error`}
        className={clsx(
          'tw-flex tw-content-center tw-items-center tw-justify-center',
          'tw-w-4 tw-h-4 tw-p-2 tw-bg-white',
          'tw-border tw-rounded tw-border-gray-300',
          'data-[state=checked]:tw-border-primary-500',
          'focus:tw-border-primary-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-primary-200',
          errorsById && 'tw-border-red-400'
        )}
      >
        <RCheckbox.Indicator>
          <Icon
            type="check"
            className="tw-text-primary-500 tw-stroke-2 tw-self-center tw-h-3 tw-w-3"
            data-testid="checked-icon"
          />
        </RCheckbox.Indicator>
      </RCheckbox.Root>
      <label htmlFor={name} className="tw-m-0 tw-text-gray-700 tw-font-medium">
        {labelContent}
      </label>

      {/* This element helps force any errors onto their own line */}
      {errorsById && <div className="tw-basis-full tw-h-0"></div>}

      {/* Show aria-live element on page load so content changes are reported */}
      {/* role="status" sets aria-live="polite" and aria-atomic="true" */}
      <div role="status" aria-relevant="all">
        {errorsById && (
          <p id={`${name}.error`} className="tw-text-red-500 tw-font-light tw-text-sm tw-pt-3">
            {errorsById.message}
          </p>
        )}
      </div>
    </>
  )
}

const forwardRefWrapper = React.forwardRef<typeof Checkbox, CheckboxProps>((props, _ref) => {
  return <Checkbox {...props} />
})

export default forwardRefWrapper
