import React from 'react'
import clsx from 'clsx'

type TagProps = {
  size?: keyof typeof sizeClasses
  color?: keyof typeof colorClasses
  children: React.ReactNode
}

const sizeClasses = {
  xs: 'tw-text-xs tw-py-0.5 tw-px-2',
  sm: 'tw-text-sm tw-py-0.5 tw-px-2',
  md: 'tw-text-base tw-py-0.5 tw-px-2',
}

const colorClasses = {
  grayscale: 'tw-bg-gray-100 tw-border-gray-300',
  error: 'tw-bg-error-100 tw-border-error-200',
  success: 'tw-bg-success-100 tw-border-success-200',
}

export default function Tag({ children, size = 'md', color = 'grayscale' }: TagProps) {
  return (
    <span
      className={clsx(
        'tw-inline-flex tw-rounded-md tw-w-fit',
        'tw-border',
        'tw-font-normal tw-normal-case tw-text-gray-900 tw-whitespace-nowrap',
        sizeClasses[size],
        colorClasses[color]
      )}
    >
      {children}{' '}
    </span>
  )
}
