import React from 'react'

import Icon from '@components/utils/Icon'

function displayAddress({ streetAddress, city, state, postcode }: GroupAddressProps): string | null {
  if (!streetAddress && !city && !postcode && !state) {
    return null
  }

  const stateAndPostcode = [city, state, postcode].filter(Boolean).join(' ')
  const address = [streetAddress, stateAndPostcode].filter(Boolean).join(', ')

  return address
}

type GroupAddressProps = {
  streetAddress?: string | null
  city?: string | null
  postcode?: string | null
  state?: string | null
}
const GroupAddress = ({ streetAddress, city, state, postcode }: GroupAddressProps) => {
  const address = displayAddress({ streetAddress, city, postcode, state })

  return (
    <p className="tw-mt-1 tw-text-gray-500 tw-text-sm tw-flex tw-items-center">
      {address && (
        <>
          <Icon width={19} height={17} type="location" />
          <span className="tw-ml-1">{address}</span>
        </>
      )}
    </p>
  )
}

export default GroupAddress
