import React from 'react'
import { type Editor } from '@tiptap/react'
import IconButton from './IconButton'

type MenuBarProps = {
  editor: Editor
}

export default function MenuBar({ editor }: MenuBarProps) {
  if (!editor) {
    return <></>
  }

  return (
    <div className={'tw-flex tw-gap-1 tw-flex-wrap tw-bg-gray-100 p-1 rounded-sm'}>
      <IconButton
        icon="bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        active={editor.isActive('bold')}
        ariaLabel={'toggle bold'}
      />
      <IconButton
        icon="italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        active={editor.isActive('italic')}
        ariaLabel={'toggle italic'}
      />
      <IconButton
        icon="underline"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().chain().focus().toggleUnderline().run()}
        active={editor.isActive('underline')}
        ariaLabel={'toggle underline'}
      />
      <IconButton
        icon="align-left"
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        disabled={!editor.can().chain().focus().setTextAlign('left').run()}
        active={editor.isActive({ textAlign: 'left' })}
        ariaLabel={'align left'}
      />
      <IconButton
        icon="align-center"
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        disabled={!editor.can().chain().focus().setTextAlign('center').run()}
        active={editor.isActive({ textAlign: 'center' })}
        ariaLabel={'align center'}
      />
      <IconButton
        icon="heading"
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        disabled={!editor.can().chain().focus().toggleHeading({ level: 3 }).run()}
        active={editor.isActive('heading', { level: 3 })}
        ariaLabel={'set as heading'}
      />
      <IconButton
        icon="dot-points"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
        active={editor.isActive('bulletList')}
        ariaLabel={'toggle bullet list'}
      />
      <IconButton
        icon="ordered-list"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        disabled={!editor.can().chain().focus().toggleOrderedList().run()}
        active={editor.isActive('orderedList')}
        ariaLabel={'toggle numbered list'}
      />
      <IconButton
        icon="horizontal-rule"
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        disabled={false}
        active={false}
        ariaLabel={'add horizontal rule'}
      />
      <IconButton
        icon="flip-backwards"
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        active={false}
        ariaLabel={'undo'}
      />
      <IconButton
        icon="flip-forwards"
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        active={false}
        ariaLabel={'redo'}
      />
    </div>
  )
}
