import { Button } from '@atoms'
import React from 'react'
import type { DraftUser } from '@frontend/graphql/types.generated'

import { scopedTranslation } from '@utils/I18n'
const t = scopedTranslation('group_create.manage_captains_form.table')

type PropsType = {
  groupId?: string
  captains: DraftUser[]
  removeCaptain: (captain: DraftUser) => void
}

export default function DisplayCaptains({ captains, removeCaptain }: PropsType) {
  const removeCaptainRequest = (email: string) => {
    const captain = captains.find((captain) => captain.email === email)
    if (!captain) {
      // TODO: handle error
      return
    }
    removeCaptain(captain)
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full ">
      <table
        className="tw-w-full tw-border tw-border-gray-200 tw-text-xs tw-rounded tw-shadow-sm"
        data-testid="captains-invited-preview"
      >
        <thead className="tw-bg-gray-100 tw-font-medium tw-text-gray-500">
          <tr>
            <td className="tw-py-3 tw-px-6">{t('email')}</td>
            <td className="tw-py-3 tw-px-4"></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {captains.length === 0 && (
            <tr className="tw-border-t tw-border-gray-200">
              <td className="tw-py-4 tw-px-6"></td>
            </tr>
          )}
          {captains.map((captain) => (
            <tr
              className="tw-border-t tw-border-gray-200 tw-text-gray-600 tw-font-medium tw-text-sm"
              key={captain.email}
            >
              <td className="tw-py-4 tw-px-6">{captain.email}</td>
              <td></td>
              <td className="tw-py-4 tw-px-6 tw-flex tw-gap-2 tw-items-center tw-justify-end tw-h-full">
                <Button
                  rank="link"
                  size="sm"
                  color="danger"
                  onClick={() => {
                    removeCaptainRequest(captain.email)
                  }}
                  ariaLabel={t('remove_captain', { email: captain.email })}
                >
                  {t('remove_invite')}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
