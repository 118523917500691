export type ColorsType = {
  [key in NonNullable<ButtonStyleProps['color']>]: string
}

export interface ButtonStyleProps {
  rank?: 'primary' | 'secondary' | 'tertiary' | 'link'
  size?: 'sm' | 'md' | 'xl' | '2xl'
  color?: 'primary' | 'danger' | 'success'
  disabled?: boolean
}

const primaryVariant: ColorsType = {
  primary:
    'tw-text-white tw-bg-primary-500 hover:tw-bg-primary-700 aria-disabled:tw-bg-primary-200 aria-disabled:hover:tw-bg-primary-200',
  danger: 'tw-text-white tw-bg-red-500 hover:tw-bg-red-700',
  success: 'tw-text-success-600 tw-bg-success-50 tw-border tw-border-success-600 hover:tw-bg-success-200',
}

const secondaryVariant: ColorsType = {
  primary:
    'tw-text-primary-700 tw-bg-primary-50 hover:tw-bg-primary-100 aria-disabled:tw-text-primary-300 aria-disabled:tw-bg-primary-50 aria-disabled:hover:tw-bg-primary-50',
  danger: 'tw-text-red-700 tw-bg-red-50 hover:tw-bg-red-100',
  success: 'tw-text-green-700 tw-bg-green-50 hover:tw-bg-green-100',
}

const tertiaryVariant: ColorsType = {
  primary: 'tw-text-primary-700 tw-bg-primary-100 hover:tw-bg-primary-200',
  danger: 'tw-text-red-700 tw-bg-red-100 hover:tw-bg-red-200',
  success: 'tw-text-green-700 tw-bg-green-100 hover:tw-bg-green-200',
}

const linkVariant: ColorsType = {
  primary: 'tw-text-primary-700 hover:tw-bg-primary-50',
  danger: 'tw-text-red-700 hover:tw-bg-red-50',
  success: 'tw-text-green-700 hover:tw-bg-green-50',
}

export function rankColorClasses(
  variant: NonNullable<ButtonStyleProps['rank']>,
  color: NonNullable<ButtonStyleProps['color']>
): string {
  const buttonVariants = {
    primary: primaryVariant[color],
    secondary: secondaryVariant[color],
    tertiary: tertiaryVariant[color],
    link: `${linkVariant[color]} tw-underline tw-underline-offset-4
           aria-disabled:tw-text-gray-300 aria-disabled:hover:tw-bg-inherit`,
  }

  return buttonVariants[variant] + ' aria-disabled:tw-cursor-not-allowed'
}

export function sizeClasses(size: NonNullable<ButtonStyleProps['size']>) {
  return {
    sm: 'tw-h-fit tw-text-sm tw-font-medium tw-py-2 tw-px-3',
    md: 'tw-h-fit tw-text-sm tw-font-medium tw-py-3 tw-px-4',
    xl: 'tw-h-fit tw-text-base tw-font-medium tw-py-3 tw-px-5',
    '2xl': 'tw-h-fit tw-text-base tw-font-medium tw-py-4 tw-px-7',
  }[size]
}
