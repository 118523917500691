import { Control, ControllerRenderProps, FieldPath, FieldValues, useController } from 'react-hook-form'
import React, { useState } from 'react'
import AsyncSelect from './AsyncSelect'
import { GraphQLClient } from 'graphql-request'
import { gql } from 'graphql-tag'
import { useGraphQLClient } from '@utils/GraphQLClientProvider'

type FieldType<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> =
  | {
      field: ControllerRenderProps<TFieldValues, TName>
    }
  | {
      control: Control<TFieldValues>
      name: TName
    }

type AdminsSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  disabled?: boolean
  searchPlaceholder?: string
  createOptionPrefix?: string
  label: string
} & FieldType<TFieldValues, TName>

const loadAdminOptions = async (
  inputValue: string,
  setTotalCount: React.Dispatch<React.SetStateAction<number>>,
  client: GraphQLClient
) => {
  const ADMINS_QUERY = gql`
    query Admins($nameICont: String, $first: Int) {
      roles(nameEq: "admin") {
        nodes {
          users(firstNameICont: $nameICont, first: $first) {
            totalCount
            nodes {
              id
              fullName
            }
          }
        }
      }
    }
  `

  type UserNode = {
    id: string
    fullName: string
  }
  interface AdminsData {
    roles: {
      nodes: {
        users: {
          totalCount: number
          nodes: UserNode[]
        }
      }[]
    }
  }

  const { roles } = await client.request<AdminsData>(ADMINS_QUERY, {
    nameICont: inputValue,
    first: 7,
  })

  const role = roles.nodes[0]

  setTotalCount(role.users.totalCount)

  return role.users.nodes.map((user) => ({
    value: user.id,
    label: user.fullName,
  }))
}

export default function AdminsSelect({ ...props }: AdminsSelectProps<any, any>) {
  let field: ControllerRenderProps<any, any>
  if ('field' in props) {
    field = props.field
  } else {
    field = useController({ name: props.name, control: props.control }).field
  }

  const [totalCount, setTotalCount] = useState<number>(0)
  const client = useGraphQLClient()

  const loadOptions = async (inputValue: string) => {
    return await loadAdminOptions(inputValue, setTotalCount, client)
  }

  return (
    <AsyncSelect
      disabled={props.disabled}
      dataTestId={'admins-select'}
      totalCount={totalCount}
      displayName={props.label}
      hideLabel={true}
      loadOptions={loadOptions}
      isCreatable={false}
      field={field}
    />
  )
}
