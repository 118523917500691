import React from 'react'
import { router } from '@inertiajs/react'

import CriteriaAdapter from '../adapters/CriteriaAdapter'

import { useErrorsStore } from '@stores/errorsStore'

import useFormWithErrors from '@hooks/useFormWithErrors'
import useMutation from '@hooks/useGQLMutation'

import { scopedTranslation } from '@utils/I18n'

import Button from '@atoms/Button'

import BackLink from '@molecules/BackLink'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'
import * as FormContainer from '@molecules/FormContainer'

import { FormFullPageLayout } from '@layouts'

import type { Group, GroupCriteria, GroupCriteriaRule } from '@frontend/graphql/types.generated'

import CriteriaPreview from '../features/edit-group-criteria/CriteriaPreview'
import CriteriaEdit from '../features/edit-group-criteria/CriteriaEdit'
import { updateGroupCriteria } from '../features/edit-group-criteria/mutations'
import { type UpdateGroupCriteriaDocument } from '../features/edit-group-criteria/mutations.generated'

const tGroupEdit = scopedTranslation('group_edit')
const tShared = scopedTranslation('shared')

type GroupCriteriaEditProps = {
  group: Pick<Group, 'id' | 'name'> & {
    criteria: Pick<GroupCriteria, 'id' | 'combinator'> & {
      rules: {
        nodes: Array<Pick<GroupCriteriaRule, 'property' | 'predicate' | 'values'>>
      }
    }
  }
}

export default function GroupCriteriaEdit({ group }: GroupCriteriaEditProps) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const { control, handleSubmit } = useFormWithErrors({
    mode: 'onTouched',
    defaultValues: {
      group: {
        criteria: CriteriaAdapter.fromServerGroup(group.criteria).toFrontend(),
      },
    },
  })

  const { mutate } = useMutation<typeof UpdateGroupCriteriaDocument>(updateGroupCriteria)

  const onSubmit = async (values: any) => {
    clearAppErrors('updateGroupCriteria')
    mutate(
      {
        id: group.criteria.id,
        ...CriteriaAdapter.fromFrontend(values.group.criteria).toServer(),
      },
      {
        onSuccess: (response) => {
          if (response.updateGroupCriteria?.success) {
            router.get('/layout_redirects', { path: `/groups/${group.id}` })
          } else {
            setAppErrors({
              errorsPath: 'updateGroupCriteria',
              errors: response.updateGroupCriteria?.errors ?? [],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'updateGroupCriteria',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <FormFullPageLayout>
      <div className="tw-flex tw-flex-col tw-align-center tw-py-16 tw-min-h-screen tw-mx-auto tw-max-w-7xl tw-gap-8">
        <AppErrorsAlert errorsPath="updateGroupCriteria" />
        <div className="tw-flex tw-flex-col tw-gap-4">
          <FormContainer.Root>
            <FormContainer.FormCard dataCompId={'add_supporters_form'}>
              <FormContainer.FormCardHeader title={tGroupEdit('group_criteria.title')} />
              <FormContainer.Form>
                <FormContainer.FormHeader title={tGroupEdit('group_criteria.form_title')} />
                <CriteriaEdit control={control} />
              </FormContainer.Form>
            </FormContainer.FormCard>
            <FormContainer.PreviewCard dataCompId={'add-supporters_preview'}>
              <FormContainer.PreviewCardHeader
                title={tShared('group_criteria.preview_title', {
                  group_name: group.name,
                })}
              />
              <CriteriaPreview control={control} groupId={group.id} />
            </FormContainer.PreviewCard>
          </FormContainer.Root>

          <div className="tw-flex tw-justify-end tw-gap-4">
            <BackLink to={`/groups/${group.id}`} rank="secondary" color="danger">
              {tGroupEdit('group_criteria.buttons.cancel')}
            </BackLink>
            <Button type="submit" size="md" trailingIcon="arrow-circle-broken-right" onClick={handleSubmit(onSubmit)}>
              {tGroupEdit('group_criteria.buttons.submit')}
            </Button>
          </div>
        </div>
      </div>
    </FormFullPageLayout>
  )
}
