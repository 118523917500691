import React from 'react'
import { useController } from 'react-hook-form'
import Select from './Select'
import countries from 'i18n-iso-countries'
import enLocale from 'i18n-iso-countries/langs/en.json'
import itLocale from 'i18n-iso-countries/langs/it.json'
import LabelledSelect from '@atoms/form/LabelledSelect'

import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('components.country_select')

countries.registerLocale(enLocale)
countries.registerLocale(itLocale)

type CountrySelectProps = {
  name: string
  label?: string
  control: any
  defaultValue?: string
  disabled?: boolean
  rank?: 'primary' | 'secondary'
}

export default function CountrySelect({ name, control, defaultValue, label, ...props }: CountrySelectProps) {
  const {
    field: { ref: _ref, ...register },
    formState: { errors },
  } = useController({
    defaultValue,
    name,
    control,
  })

  const fullLocale = navigator.languages ? navigator.languages[0] : navigator.language
  const locale = fullLocale.split('-')[0]
  let countryObj = countries.getNames(locale, { select: 'official' })
  if (Object.keys(countryObj).length === 0) {
    countryObj = countries.getNames('en', { select: 'official' })
  }
  const countryList = Object.entries(countryObj).map(([key, value]) => ({
    value: key,
    label: value,
  }))

  if (label) {
    return (
      <LabelledSelect
        placeholder={t('placeholder')}
        options={countryList}
        label={label}
        {...register}
        errors={errors}
        {...props}
      />
    )
  } else {
    return <Select placeholder={t('placeholder')} options={countryList} {...register} errors={errors} {...props} />
  }
}
