import React from 'react'
import Alert from '@atoms/Alert'
import { scopedTranslation } from '../../../utils/I18n'
const t = scopedTranslation('shared.errors.500')

type Alert500Props = {
  onClose: () => void
}

export default function Alert500({ onClose }: Alert500Props) {
  return (
    <Alert data-testid="alert-500" onClose={onClose} type="error" title={t('title')}>
      {t('description')}
    </Alert>
  )
}
