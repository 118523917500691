import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { GraphQLClientProvider } from '@utils/GraphQLClientProvider'

function BaseLayout({ children }) {
  const queryClient = new QueryClient()

  return (
    <GraphQLClientProvider>
      <QueryClientProvider client={queryClient}>
        <main className="tailwind tw-bg-white tw-font-sans">{children}</main>
      </QueryClientProvider>
    </GraphQLClientProvider>
  )
}

export default BaseLayout
