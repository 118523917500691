import React from 'react'
import { scopedTranslation } from '@utils/I18n'

const t = scopedTranslation('components')
const tShared = scopedTranslation('shared')

type PointPeopleProps = {
  groupName: string
  notifyAllAdmins: boolean
  pointPersonEmails: string[]
}

const PointPeople = ({ groupName, notifyAllAdmins, pointPersonEmails }: PointPeopleProps) => {
  const pointPeopleText = (emails) => (emails.length === 0 ? tShared('labels.none') : emails.join(', '))
  const ariaLabel = t('group_card.aria_labels.point_people', { name: groupName })

  return (
    <p className="tw-text-sm">
      <strong className="tw-text-xs tw-text-gray-blue-500 tw-uppercase tw-font-bold" aria-label={ariaLabel}>
        {t('group_card.labels.point_people')}:{' '}
      </strong>
      <span className="tw-text-gray-900">
        {notifyAllAdmins ? t('group_card.labels.all_admins') : pointPeopleText(pointPersonEmails)}
      </span>
    </p>
  )
}

export default PointPeople
