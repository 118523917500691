import React, { useEffect } from 'react'
import { router } from '@inertiajs/react'

import { scopedTranslation } from '@utils/I18n'

import { Link } from '@atoms'
import PaginationBubble, { generatePaginationLink } from '@atoms/PaginationBubble'

const t = scopedTranslation('components.pagination')

function arrayFromRange(min: number, max: number) {
  if (max < min) {
    ;[max, min] = [min, max]
  }
  return Array.from({ length: max - min + 1 }, (_, i) => min + i)
}

type PaginationProps = {
  totalPages: number
  only: string[]
}

type PagesProps = {
  currentPage: number
  currentIsInFirstPages: boolean
  currentIsInLastPages: boolean
  showAllPages: boolean
} & PaginationProps

const showAllIfLessThan = 11
const amountToShowAroundCurrent = 1
const amountToShowAtEdges = amountToShowAroundCurrent * 2 + 1

// This is a little calculation that determines how far away the current page number must be from the edges
//  for it to be considered to close to the start or end of the pagination range
//  e.g. if amountToShowAtEdges = 3 and amountToShowAroundCurrent = 1
//  then the current page must be at least 5 away from the edges or there is no gap between the ranges
const showExtraWhenOffByLessThan = amountToShowAtEdges + amountToShowAroundCurrent + 2

function PaginationEllipsis() {
  return (
    <li className="tw-flex tw-justify-center tw-items-center tw-gap-2">
      <span className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-gray-400"></span>
      <span className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-gray-400"></span>
      <span className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-gray-400"></span>
    </li>
  )
}

type RenderPaginationRangeProps = {
  startValue: number
  endValue: number
} & Pick<PagesProps, 'currentPage' | 'only'>
function RenderPaginationRange({ startValue, endValue, currentPage, only }: RenderPaginationRangeProps) {
  return (
    <>
      {arrayFromRange(startValue, endValue).map((pageNum) => (
        <PaginationBubble key={pageNum} current={pageNum === currentPage} only={only} pageNumber={pageNum} />
      ))}
    </>
  )
}

function FirstPages({ currentPage, only }: Pick<PagesProps, 'currentPage' | 'totalPages' | 'only'>) {
  const startValue = 1
  const endValue = startValue + amountToShowAtEdges - 1

  return (
    <>
      <RenderPaginationRange {...{ startValue, endValue, currentPage, only }} />
      <PaginationEllipsis />
    </>
  )
}

function CurrentPages({
  currentPage,
  totalPages,
  showAllPages,
  currentIsInFirstPages,
  currentIsInLastPages,
  only,
}: PagesProps) {
  let startValue = currentPage - amountToShowAroundCurrent
  let endValue = currentPage + amountToShowAroundCurrent

  if (showAllPages) {
    startValue = 1
    endValue = totalPages

    // If the current page is close enough to the start, we want to show from 1 -> current page + 1
  } else if (currentIsInFirstPages) {
    startValue = 1

    // If the current page is close enough to the end, we want to show just the final pages
  } else if (currentIsInLastPages) {
    endValue = totalPages
  }

  return <RenderPaginationRange {...{ startValue, endValue, currentPage, only }} />
}

function LastPages({ currentPage, totalPages, only }: Pick<PagesProps, 'currentPage' | 'totalPages' | 'only'>) {
  const endValue = totalPages
  const startValue = endValue - amountToShowAtEdges + 1

  return (
    <>
      <PaginationEllipsis />
      <RenderPaginationRange {...{ startValue, endValue, currentPage, only }} />
    </>
  )
}

export default function Pagination({ totalPages, only }: PaginationProps) {
  const [currentPage, setCurrentPage] = React.useState(
    parseInt(new URLSearchParams(window.location.search).get('page') ?? '1')
  )

  useEffect(() => {
    return router.on('finish', (event) => {
      if (event.detail.visit.url.pathname !== window.location.pathname) return

      setCurrentPage(parseInt(event.detail.visit.url.searchParams.get('page') ?? '1'))
    })
  }, [])

  totalPages = Math.max(totalPages, 1)
  const showAllPages = totalPages < showAllIfLessThan
  const currentIsInFirstPages = currentPage <= showExtraWhenOffByLessThan
  const currentIsInLastPages = currentPage >= totalPages - showExtraWhenOffByLessThan

  const showFirstPages = !showAllPages && !currentIsInFirstPages
  const showLastPages = !showAllPages && !currentIsInLastPages

  return (
    <div data-testid="pagination" className="tw-flex tw-items-center tw-justify-center tw-gap-4">
      <Link
        disabled={currentPage === 1}
        href={currentPage === 1 ? '' : generatePaginationLink(currentPage - 1)}
        rank="link"
        size="sm"
        leadingIcon="arrow-narrow-left"
        inertiaLink={true}
        only={only}
      >
        {t('previous')}
      </Link>
      <ul className="tw-flex tw-items-center tw-justify-center tw-gap-4">
        {showFirstPages && <FirstPages {...{ currentPage, totalPages, only }} />}
        <CurrentPages
          {...{ currentPage, totalPages, showAllPages, currentIsInFirstPages, currentIsInLastPages, only }}
        />
        {showLastPages && <LastPages {...{ currentPage, totalPages, only }} />}
      </ul>
      <Link
        disabled={currentPage === totalPages}
        href={currentPage === totalPages ? '' : generatePaginationLink(currentPage + 1)}
        rank="link"
        size="sm"
        trailingIcon="arrow-narrow-right"
        inertiaLink={true}
        only={only}
      >
        {t('next')}
      </Link>
    </div>
  )
}
