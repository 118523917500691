import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { Button, Dialog, TextInput } from '@atoms'

import { i18nValidation, scopedTranslation } from '@utils/I18n'

import useFormWithErrors from '@hooks/useFormWithErrors'
import useMutation from '@hooks/useGQLMutation'

import { useErrorsStore } from '@stores/errorsStore'

import { serverErrorsToFormErrors } from '@adapters/serverErrorsAdapter'

import { UpdateGroupDocument } from '../edit-group-details/mutations.generated'
import { updateGroupMutation } from '../edit-group-details/mutations'
import AppErrorsAlert from '../../../../components/molecules/Alerts/AppErrorsAlert'

const t = scopedTranslation('group_show.mailer_sender_modal')
const tAttributes = scopedTranslation('attributes')
const tShared = scopedTranslation('shared')

export const validationSchema = yup.object({
  group: yup.object({
    mailerSender: yup.object({
      localPart: yup
        .string()
        .label(tAttributes('group.mailer_sender'))
        .required(i18nValidation('required'))
        .min(3, i18nValidation('too_short', 3)),
    }),
  }),
})

type extractFieldValues<Type> = Type extends yup.ObjectSchema<infer T> ? T : never
type ValidationSchema = extractFieldValues<typeof validationSchema>

type MailerSenderDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  groupId: string
  mailerSender: string
}

export default function MailerSenderDialog({ open, onOpenChange, groupId, mailerSender }: MailerSenderDialogProps) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const [localPart, domain] = mailerSender.split('@')

  const {
    setErrors: setFormErrors,
    handleSubmit,
    register,
    formState: { errors },
  } = useFormWithErrors({
    defaultValues: { group: { mailerSender: { localPart: localPart } } },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })

  const { mutate } = useMutation<typeof UpdateGroupDocument>(updateGroupMutation)

  function onSubmit(values: ValidationSchema) {
    const {
      group: { mailerSender },
    } = values

    mutate(
      {
        id: groupId,
        mailerSender,
      },
      {
        onSuccess: (response) => {
          clearAppErrors('mailerSenderErrors')
          if (!response.updateGroup) return

          if (response.updateGroup.success) {
            onOpenChange(false)
          } else {
            setAppErrors({ errorsPath: 'mailerSenderErrors', errors: response.updateGroup.errors })
            setFormErrors({
              errorsBasePath: 'group.mailerSender.localPart',
              errors: response.updateGroup?.errors,
              adapter: serverErrorsToFormErrors,
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'mailerSenderErrors',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content size="xl">
        <Dialog.Header>
          <Dialog.Title>{t('title')}</Dialog.Title>
        </Dialog.Header>

        <form className="tw-flex tw-flex-col tw-gap-2">
          <AppErrorsAlert errorsPath="mailerSenderErrors" />

          <p className="tw-text-sm tw-text-gray-700 tw-font-medium">{t('description')}</p>
          <div className="tw-flex tw-gap-3 tw-items-baseline">
            <TextInput
              hideLabel={true}
              displayName={tAttributes('group.mailer_sender')}
              errors={errors}
              {...register('group.mailerSender.localPart')}
            />
            <span>@</span>
            <span className="tw-w-full">{domain}</span>
          </div>
          <p className="tw-text-sm tw-text-gray-500">{t('help_text')}</p>
        </form>

        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button type="button" size="xl" rank="secondary">
              {tShared('buttons.close_without_applying')}
            </Button>
          </Dialog.Close>
          <Button type="button" size="xl" color="primary" onClick={handleSubmit(onSubmit)}>
            {tShared('buttons.apply')}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  )
}
