import { useGraphQLClient } from "@utils/GraphQLClientProvider"
import { useMutation } from "@tanstack/react-query"
import { type TypedDocumentNode } from '@graphql-typed-document-node/core'
import { type DocumentNode } from 'graphql'

export default function useGQLMutation<DocumentNodeType extends TypedDocumentNode>(query: string | DocumentNode) {
  type TVariables = DocumentNodeType extends TypedDocumentNode<any, infer TVars> ? TVars : never
  type TResult = DocumentNodeType extends TypedDocumentNode<infer TResult> ? TResult : never

  const client = useGraphQLClient()
  return useMutation<TResult, unknown, TVariables, unknown>({
    mutationFn: async (formValues: TVariables) => {
      return await client.request(query, formValues as any)
    }
  })
}