import React from 'react'
import { router } from '@inertiajs/react'
import { FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'

import type { DraftGroup } from '@frontend/graphql/types.generated'

import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import useMutation from '@hooks/useGQLMutation'
import useFormWithErrors from '@hooks/useFormWithErrors'

import { useErrorsStore } from '@stores/errorsStore'

import StepLayout from '@layouts/StepLayout'
import FormFullPageLayout from '@layouts/FormFullPageLayout'

import { Button, Link } from '@atoms'
import * as FormContainer from '@molecules/FormContainer'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import type { UpdateDraftGroupDocument } from '../features/create-new-group/group-details/mutations.generated'
import { updateDraftGroupMutation } from '../features/create-new-group/group-details/mutations'
import GroupFormPage, { type ValidationSchema, validationSchema } from '../features/edit-group-details/GroupFormPage'

import { scopedTranslation } from '@utils/I18n'
import GroupDetailsAdapter from '../adapters/GroupDetailsAdapter'

const t = scopedTranslation('group_create')
const tShared = scopedTranslation('shared')

function StepNumber() {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded-full tw-text-base tw-font-semibold tw-w-8 tw-h-8">
      1
    </div>
  )
}

type DraftGroupEditPropsType = {
  draftGroup: Pick<
    DraftGroup,
    | 'id'
    | 'name'
    | 'description'
    | 'publicPublish'
    | 'publicDescription'
    | 'hasPhysicalAddress'
    | 'venueName'
    | 'streetAddress'
    | 'city'
    | 'postcode'
    | 'state'
    | 'country'
    | 'notifyAllAdmins'
  > & {
    pointPeople: {
      nodes: Array<{
        id: number
      }>
    }
  }
  primarySiteBaseUrl: string
}

function DraftGroupEdit({ draftGroup, primarySiteBaseUrl }: DraftGroupEditPropsType) {
  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))
  const {
    setErrors: setFormErrors,
    watch,
    ...formMethods
  } = useFormWithErrors({
    defaultValues: {
      group: GroupDetailsAdapter.fromServer(draftGroup).toFrontend(),
    },
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  })

  const { mutate } = useMutation<typeof UpdateDraftGroupDocument>(updateDraftGroupMutation)

  const onSubmit = async (values: ValidationSchema) => {
    clearAppErrors('updateDraftGroup')
    mutate(GroupDetailsAdapter.fromFrontend({ id: draftGroup.id, ...values.group }).toServer(), {
      onSuccess: (response) => {
        if (!response.updateDraftGroup) return

        if (response.updateDraftGroup.success && response.updateDraftGroup.draftGroup) {
          router.get(`/draft_groups/${response.updateDraftGroup.draftGroup.id}/add_supporters`)
        } else {
          setFormErrors({
            errorsBasePath: 'group',
            errors: response.updateDraftGroup.errors,
            adapter: serverErrorsToFormErrors,
          })
          setAppErrors({
            errorsPath: 'updateDraftGroup',
            errors: response.updateDraftGroup.errors,
          })
        }
      },
      onError: () => {
        setAppErrors({
          errorsPath: 'updateDraftGroup',
          errors: [
            {
              message: '',
              code: 'unknown',
            },
          ],
        })
      },
    })
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-8">
      <AppErrorsAlert errorsPath="createDraftGroup" />
      <div className="tw-flex tw-flex-col tw-gap-4">
        <FormProvider {...formMethods} watch={watch}>
          <FormContainer.Root>
            <FormContainer.FormCard dataCompId={'create-group_form'}>
              <FormContainer.FormCardHeader title={t('group_details_form.title')} />
              <FormContainer.Form>
                <FormContainer.FormHeader title={t('group_details_form.labels.legend')} icon={<StepNumber />} />
                <GroupFormPage primarySiteBaseUrl={primarySiteBaseUrl} />
              </FormContainer.Form>
            </FormContainer.FormCard>

            <FormContainer.PreviewCard dataCompId={'create-group_preview'}>
              <FormContainer.PreviewCardHeader
                title={t('group_details_form.preview_title', {
                  group_name: isEmpty(watch('group.name')) ? 'group' : watch('group.name'),
                })}
              />
            </FormContainer.PreviewCard>
          </FormContainer.Root>
        </FormProvider>

        <div className="tw-flex tw-justify-end tw-gap-2">
          <Link rank="link" size="xl" href="/groups" color="danger">
            {tShared('buttons.cancel')}
          </Link>
          <Button
            type="submit"
            size="md"
            trailingIcon="arrow-circle-broken-right"
            onClick={formMethods.handleSubmit(onSubmit)}
          >
            {t('group_details_form.labels.submit')}
          </Button>
        </div>
      </div>
    </div>
  )
}

DraftGroupEdit.layout = (page: JSX.Element) => (
  <FormFullPageLayout>
    <StepLayout
      steps={[t('form_steps.group_details'), t('form_steps.add_supporters'), t('form_steps.invite_captains')]}
      currentStep={0}
    >
      {page}
    </StepLayout>
  </FormFullPageLayout>
)

export default DraftGroupEdit
