import React from 'react'
import type {
  TagFilter,
  EngagementLevel,
  Contact,
  Group,
  GroupMembership,
  Supporter,
  Tag,
  SupporterNote,
} from '@frontend/graphql/types.generated'

import { t } from '@utils/I18n'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@atoms/Tabs'

import BackLink from '@molecules/BackLink'
import AppErrorsAlert from '@molecules/Alerts/AppErrorsAlert'

import Icon from '@components/utils/Icon'

import SupporterCard from '../features/edit-supporter/SupporterShowCard'
import ContactDetailsForm from '../features/edit-supporter/ContactDetailsForm'
import AddressForm from '../features/edit-supporter/AddressForm'
import TagsForm from '../features/edit-supporter/TagsForm'
import EngagementLevelForm from '../features/edit-supporter/EngagementLevelForm'
import ContactsLog from '../features/show-supporter/ContactsLog'
import TagFiltersForm from '../features/edit-supporter/TagFiltersForm'
import {
  createSupporterDetailsStore,
  SupporterDetailsProvider,
} from '../features/edit-supporter/stores/useSupporterDetailsStore'
import NotesLog from '@frontend/contexts/supporters-management/features/show-supporter/NotesLog'

export type SupporterEdgeType = {
  membership: Pick<GroupMembership, 'createdAt' | 'emailable' | 'textable' | 'callable'>
  tagFilters: Array<Pick<TagFilter, 'id' | 'name'>>
  node: Pick<
    Supporter,
    | 'id'
    | 'email'
    | 'primaryMobile'
    | 'primaryPhone'
    | 'fullName'
    | 'firstName'
    | 'lastName'
    | 'createdAt'
    | 'engagementLevel'
    | 'externalCreatedAt'
    | 'lastContactedAt'
    | 'address1'
    | 'address2'
    | 'city'
    | 'zip'
    | 'state'
    | 'countryCode'
    | 'country'
  > & {
    tags: {
      nodes: Array<Pick<Tag, 'name'>>
    }
  }
}

export type EngagementLevelType = Pick<EngagementLevel, 'id' | 'name'>
export type TagFilterType = Pick<TagFilter, 'id' | 'name'>

export type SupporterShowType = {
  contacts: {
    nodes: Array<
      Pick<Contact, 'id' | 'createdAt' | 'method' | 'status' | 'note' | 'viaActionCentre'> & {
        user?: {
          fullName: string
        }
      }
    >
    totalPages: number
    totalCount: number
  }
  engagementLevels: {
    nodes: EngagementLevelType[]
  }
  tagFilters: {
    nodes: TagFilterType[]
  }
  group: {
    id: Group['id']
    name: Group['name']
    supporters: {
      edges: SupporterEdgeType[]
    }
  }
  supporterNotes: {
    nodes: Array<Pick<SupporterNote, 'id' | 'createdAt' | 'content' | 'author'>>
  }
}

export type SharedInertiaProps = {
  permissions: {
    updateDetails: boolean
    updateAddress: boolean
    updateEmail: boolean
    updateMobile: boolean
    updatePhone: boolean
    readTags: boolean
    updateTags: boolean
  }
}

type ExtendedSupporterShowType = SupporterShowType & SharedInertiaProps

function SupporterShowLayout({
  children,
  group,
  group: { supporters },
}: React.PropsWithChildren<ExtendedSupporterShowType>) {
  const { membership, node: supporter, tagFilters } = supporters.edges[0]
  const supporterDetailsStore = createSupporterDetailsStore({
    firstName: supporter.firstName,
    lastName: supporter.lastName,
    fullName: supporter.fullName,
    tags: supporter.tags.nodes.map((tag) => ({ value: tag.name, label: tag.name })),
    tagFilters: tagFilters.map((tagFilter) => ({
      value: tagFilter.id,
      label: tagFilter.name,
    })),
  })

  return (
    <SupporterDetailsProvider store={supporterDetailsStore}>
      <div className="tw-flex tw-flex-col tw-gap-6 tw-px-4 tw-py-4 tw-min-h-screen">
        <AppErrorsAlert errorsPath={'supporterAction'} />
        <div className="tw-flex tw-flex-col tw-gap-6">
          <div>
            <BackLink to={`/groups/${group.id}`} leadingIcon="arrow-narrow-left">
              {t('supporter_show.back_to_group')}
            </BackLink>
          </div>
          <SupporterCard group={group} supporter={supporter} membership={membership} />

          <Tabs defaultValue="supporterDetails">
            <div className="tw-flex tw-flex-col tw-gap-4">{children}</div>
          </Tabs>
        </div>
      </div>
    </SupporterDetailsProvider>
  )
}

function SupporterShow({
  permissions,
  group: { supporters, id: groupId },
  contacts,
  supporterNotes,
  engagementLevels,
  tagFilters,
}: ExtendedSupporterShowType) {
  const { node: supporter } = supporters.edges[0]

  return (
    <>
      <TabsList className="tw-w-fit">
        <TabsTrigger value="supporterDetails">
          <Icon type="user-square" className="tw-mr-2" width={20} height={20} />
          {t('supporter_show.supporter_details')}
        </TabsTrigger>
        <TabsTrigger value="contactsLog">
          <Icon type="mail-01" className="tw-mr-2" width={20} height={20} />
          {t('supporter_show.contacts_log')}
        </TabsTrigger>
        <TabsTrigger value="notesLog">
          <Icon type="book-open-01" className="tw-mr-2" width={20} height={20} />
          {t('supporter_show.notes')}
        </TabsTrigger>
      </TabsList>

      <TabsContent value="supporterDetails">
        <div className="tw-flex tw-flex-col tw-gap-6">
          <EngagementLevelForm
            supporterId={supporter.id}
            engagementLevel={supporter.engagementLevel}
            engagementLevels={engagementLevels.nodes}
          />
          {permissions.readTags && (
            <TagsForm groupId={groupId} supporterId={supporter.id} permissions={permissions} />
          )}
          <TagFiltersForm
            groupId={groupId}
            supporterId={supporter.id}
            tagFilters={tagFilters.nodes}
            canEdit={permissions.updateTags}
          />
          <ContactDetailsForm
            supporterId={supporter.id}
            email={supporter.email ?? ''}
            mobile={supporter.primaryMobile ?? ''}
            callPhone={supporter.primaryPhone ?? ''}
            permissions={permissions}
          />
          <AddressForm
            supporterId={supporter.id}
            address1={supporter.address1 ?? ''}
            address2={supporter.address2 ?? ''}
            city={supporter.city ?? ''}
            zip={supporter.zip ?? ''}
            state={supporter.state ?? ''}
            countryCode={supporter.countryCode ?? ''}
            country={supporter.country ?? ''}
            permissions={permissions}
          />
        </div>
      </TabsContent>

      <TabsContent value="contactsLog">
        <ContactsLog contacts={contacts.nodes} totalPages={contacts.totalPages} />
      </TabsContent>

      <TabsContent value="notesLog">
        <NotesLog notes={supporterNotes.nodes} supporterId={supporter.id} />
      </TabsContent>
    </>
  )
}

SupporterShow.layout = (page) => <SupporterShowLayout {...page.props}>{page}</SupporterShowLayout>

export default SupporterShow
