import React from 'react'
import clsx from 'clsx'
import Icon from '@components/utils/Icon'
import { type IconTypes } from '../utils/Icon'
import { type ButtonStyleProps, rankColorClasses, sizeClasses } from './ButtonStyles'
import { type InertiaLinkProps, Link as InertiaLink } from '@inertiajs/react'

export type LinkProps = Omit<InertiaLinkProps, keyof ButtonStyleProps> &
  ButtonStyleProps & {
    children: React.ReactNode
    trailingIcon?: IconTypes
    leadingIcon?: IconTypes
    href: string
    ariaLabel?: string
    inertiaLink?: boolean
    dataCompId?: string
  }

const Link = React.forwardRef<HTMLLinkElement, LinkProps>(
  (
    {
      size = 'md',
      rank = 'primary',
      color = 'primary',
      trailingIcon,
      leadingIcon,
      children,
      href,
      ariaLabel,
      inertiaLink,
      disabled,
      dataCompId,
      ...props
    },
    ref
  ) => {
    const Element = inertiaLink ? InertiaLink : 'a'

    // https://a11y-guidelines.orange.com/en/articles/disable-elements/
    const disabledLinkProps = { role: 'link', 'aria-disabled': 'true' }
    const linkProps = disabled ?? !href ? disabledLinkProps : { href }

    return (
      <Element
        ref={ref}
        aria-label={ariaLabel}
        {...linkProps}
        {...(props as any)}
        className={clsx(
          'tw-rounded-lg tw-transition',
          'tw-inline-flex tw-items-center tw-gap-2',
          'focus:tw-border-primary-500 focus:tw-ring-4 focus:tw-ring-primary-300',
          sizeClasses(size),
          rankColorClasses(rank, color)
        )}
        {...(dataCompId && { 'data-compid': `${dataCompId}-link` })}
      >
        {leadingIcon && <Icon dataTestId="leading-icon" type={leadingIcon} className="tw-h-5 tw-w-5 tw-mt-0.5" />}
        <span className="tw-gap-2 tw-whitespace-nowrap tw-flex tw-flex-nowrap tw-items-center">{children}</span>
        {trailingIcon && <Icon dataTestId="trailing-icon" type={trailingIcon} className="tw-h-5 tw-w-5 tw-mt-0.5" />}
      </Element>
    )
  }
)
Link.displayName = 'Link'

export default Link
