import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'

import cn from '@components/utils/cn'

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root> & { urlKey?: string }
>(({ urlKey = 'tab', ...props }, ref) => {
  const onValueChange = (value: string) => {
    props.onValueChange && props.onValueChange(value)

    // add value to URL without reloading page
    const newUrlParams = new URLSearchParams(document.location.search)
    newUrlParams.delete(urlKey)
    newUrlParams.set(urlKey, value)

    const newUrl = window.location.origin + window.location.pathname + '?' + newUrlParams.toString()
    history.replaceState(history.state, '', newUrl)
  }

  const defaultValue = new URLSearchParams(document.location.search).get(urlKey) ?? props.defaultValue

  return <TabsPrimitive.Root ref={ref} {...props} onValueChange={onValueChange} defaultValue={defaultValue} />
})
Tabs.displayName = TabsPrimitive.Root.displayName

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      'tw-inline-flex tw-items-center tw-justify-center tw-rounded-lg tw-border-gray-300 tw-text-gray-700',
      className
    )}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      'tw-relative tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-px-5 tw-py-3 tw-text-sm tw-font-medium tw-transition-all',
      'last-of-type:tw-rounded-r-lg first-of-type:tw-rounded-l-lg',
      'tw-border-gray-300 tw-border last-of-type:tw-mr-0 tw-mr-[-1px]',
      'focus-visible:tw-outline-none focus-visible:tw-ring-4 focus-visible:tw-ring-primary-300 focus-visible:tw-z-10',
      'disabled:tw-pointer-events-none disabled:tw-opacity-50',
      'data-[state=active]:tw-bg-primary-500 data-[state=active]:tw-text-white',
      className
    )}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn('focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-300', className)}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
