import React, { useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import type { Maybe } from '@frontend/graphql/types.generated'

import { useErrorsStore } from '@stores/errorsStore'
import useMutation from '@hooks/useGQLMutation'
import useFormWithErrors from '@hooks/useFormWithErrors'
import { serverErrorsToFormErrors } from '@frontend/adapters/serverErrorsAdapter'

import { Button } from '@atoms'
import { SelectDropdown } from '@atoms/form'

import { scopedTranslation } from '@utils/I18n'

import type { UpdateSupporterEngagementLevelDocument } from './mutations.generated'
import { updateSupporterEngagementLevelMutation } from './mutations'
import type { EngagementLevelType } from '../../pages/SupporterShow'

const tSupporterShow = scopedTranslation('supporter_show')
const tShared = scopedTranslation('shared')

type EngagementLevelFieldProps = {
  supporterId: string
  currentEngagementLevel: EngagementLevelType
  engagementLevelOptions: EngagementLevelType[]
  onCancel: () => void
  onSuccess: (engagementLevelName: string) => void
}

function EngagementLevelField({
  supporterId,
  currentEngagementLevel,
  engagementLevelOptions,
  onCancel,
  onSuccess,
}: EngagementLevelFieldProps) {
  const {
    setErrors: setFormErrors,
    handleSubmit,
    control,
  } = useFormWithErrors({
    mode: 'onTouched',
    defaultValues: {
      supporter: { engagementLevelId: currentEngagementLevel.id },
    },
  })

  const { setErrors: setAppErrors, clearErrors: clearAppErrors } = useErrorsStore(({ setErrors, clearErrors }) => ({
    setErrors,
    clearErrors,
  }))

  const { field } = useController({
    name: 'supporter.engagementLevelId',
    defaultValue: currentEngagementLevel.id,
    control,
  })

  const { mutate } = useMutation<typeof UpdateSupporterEngagementLevelDocument>(updateSupporterEngagementLevelMutation)

  const onSubmit = async (values) => {
    clearAppErrors('engagementLevelId')

    mutate(
      { id: supporterId, engagementLevelId: values.supporter.engagementLevelId },
      {
        onSuccess: (response) => {
          if (response.updateSupporterEngagementLevel?.success) {
            onSuccess(response.updateSupporterEngagementLevel?.supporter?.engagementLevel ?? '')
            clearAppErrors('engagementLevelId')
          } else if (response.updateSupporterEngagementLevel) {
            setFormErrors({
              errorsBasePath: 'engagementLevelId',
              errors: response.updateSupporterEngagementLevel.errors,
              adapter: serverErrorsToFormErrors,
            })
            setAppErrors({
              errorsPath: 'engagementLevelId',
              errors: response.updateSupporterEngagementLevel.errors,
            })
          } else {
            setAppErrors({
              errorsPath: 'engagementLevelId',
              errors: [
                {
                  message: '',
                  code: 'unknown',
                },
              ],
            })
          }
        },
        onError: () => {
          setAppErrors({
            errorsPath: 'engagementLevelId',
            errors: [
              {
                message: '',
                code: 'unknown',
              },
            ],
          })
        },
      }
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="tw-flex tw-gap-2 tw-items-center">
        <div className="tw-flex-1">
          <SelectDropdown.Root
            name="engagement_level"
            defaultValue={currentEngagementLevel.id}
            onValueChange={(value) => {
              field.onChange(value)
            }}
          >
            <SelectDropdown.Trigger aria-label={tSupporterShow('engagement_level')}>
              <SelectDropdown.Value placeholder={tSupporterShow('no_engagement_level')} />
            </SelectDropdown.Trigger>
            <SelectDropdown.Content>
              {engagementLevelOptions.map((level) => (
                <SelectDropdown.Item key={level.id} value={level.id}>
                  {level.name}
                </SelectDropdown.Item>
              ))}
            </SelectDropdown.Content>
          </SelectDropdown.Root>
        </div>
        <span className="tw-flex tw-gap-2">
          <Button
            size="sm"
            rank="primary"
            type="submit"
            ariaLabel={tShared('aria_labels.save_object', { name: tSupporterShow('engagement_level').toLowerCase() })}
          >
            {tShared('buttons.save', { name: tSupporterShow('engagement_level').toLowerCase() })}
          </Button>
          <Button
            size="sm"
            rank="primary"
            color="danger"
            onClick={onCancel}
            ariaLabel={tShared('aria_labels.cancel_editing', {
              name: tSupporterShow('engagement_level').toLowerCase(),
            })}
          >
            {tShared('buttons.cancel', { name: tSupporterShow('engagement_level').toLowerCase() })}
          </Button>
        </span>
      </div>
    </form>
  )
}

type EngagementLevelFormProps = {
  supporterId: string
  engagementLevel: Maybe<string> | undefined
  engagementLevels: EngagementLevelType[]
}

function EngagementLevelForm({ supporterId, engagementLevel, engagementLevels }: EngagementLevelFormProps) {
  const canEdit = engagementLevels.length > 0
  const noEngagementLevelOption: EngagementLevelType = {
    id: 'no_engagement_level',
    name: tSupporterShow('no_engagement_level'),
  }
  const engagementLevelOptions = [...engagementLevels, noEngagementLevelOption]
  const getEngagementLevel = (name: string) => {
    return engagementLevelOptions.find((level) => level.name === name) ?? noEngagementLevelOption
  }
  const [open, setOpen] = useState(false)
  const [currentEngagementLevel, setCurrentEngagementLevel] = useState(getEngagementLevel(engagementLevel ?? ''))
  const editButtonRef = useRef<HTMLButtonElement | null>(null)

  const onSuccess = (engagementLevelName: string) => {
    setOpen(false)
    setCurrentEngagementLevel(getEngagementLevel(engagementLevelName))
    editButtonRef.current?.focus()
  }

  const onCancel = () => {
    setOpen(false)
    setTimeout(() => {
      editButtonRef.current?.focus()
    })
  }

  const onEdit = () => {
    setOpen(true)
  }

  return (
    <div
      data-testid="engagement-level-form"
      className="tw-grid tw-grid-cols-5 tw-grid-rows-1 tw-gap-4 tw-items-center tw-px-8 tw-py-5 tw-shadow-sm tw-rounded-xl tw-border tw-border-gray-200"
    >
      <div>
        <h3 className="tw-font-medium">{tSupporterShow('engagement_level')}</h3>
      </div>
      {open ? (
        <div className="tw-col-span-2">
          <EngagementLevelField
            supporterId={supporterId}
            currentEngagementLevel={currentEngagementLevel}
            engagementLevelOptions={engagementLevelOptions}
            onCancel={onCancel}
            onSuccess={onSuccess}
          />
        </div>
      ) : (
        <div className="tw-col-start-2 tw-col-span-3">{currentEngagementLevel.name}</div>
      )}

      <div className="tw-col-start-5 tw-flex tw-justify-end">
        <Button
          rank="secondary"
          leadingIcon="pencil-02"
          onClick={onEdit}
          ref={editButtonRef}
          disabled={canEdit ? open : true}
          ariaLabel={tShared('aria_labels.edit_object', { name: tSupporterShow('engagement_level').toLowerCase() })}
          dataCompId="supporter-show_mobile-field-row_edit"
        >
          {tShared('buttons.edit')}
        </Button>
      </div>
    </div>
  )
}

export default EngagementLevelForm
