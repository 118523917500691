import React from 'react'

import { type SupporterShowType } from '@frontend/contexts/supporters-management/pages/SupporterShow'
import Icon from '@components/utils/Icon'
import { scopedTranslation } from '@utils/I18n'

import NoteLogCard from './NoteLogCard'
import NewNoteForm from './NewNoteForm'

const t = scopedTranslation('components.notes_log')

type NotesLogProps = {
  notes: SupporterShowType['supporterNotes']['nodes']
  supporterId: string
}

const NotesEmptyState = () => (
  <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col tw-gap-4 tw-py-6">
    <div className="tw-flex tw-items-center tw-justify-center tw-bg-primary-50 tw-w-[5.5rem] tw-h-[5.5rem] tw-rounded-full">
      <Icon type="book-open-01" className="tw-text-primary-600" width={38} height={38} />
    </div>
    <p className="tw-text-lg tw-font-bold tw-text-gray-900">{t('empty_state.title')}</p>
  </div>
)

export default function NotesLog({ notes, supporterId }: NotesLogProps) {
  const hasNotes = notes.length > 0

  return (
    <div className="tw-flex tw-flex-col tw-gap-2" data-testid="notes-log">
      <div className="tw-shadow-md tw-rounded-xl tw-border tw-border-gray-200 [&>*]:tw-border-b [&>*]:tw-border-gray-200 [&>*:last-child]:tw-border-b-0">
        <div className="tw-flex tw-py-5 tw-px-8 tw-gap-8 tw-text-sm tw-text-gray-700">
          <NewNoteForm supporterId={supporterId} />
        </div>
        {hasNotes ? notes.map((note) => <NoteLogCard key={note.id} {...note} />) : <NotesEmptyState />}
      </div>
    </div>
  )
}
