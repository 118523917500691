import React from 'react'
import Steps from '@molecules/Steps'

type StepLayoutProps = {
  children: React.ReactNode
  steps: string[]
  currentStep: number
}

export default function StepLayout({ steps, currentStep, children }: StepLayoutProps) {
  return (
    <div className="tw-flex tw-flex-col tw-align-center tw-min-h-screen tw-mx-auto tw-max-w-7xl tw-px-4 tw-font-sans tw-py-16">
      <div className="tw-py-4 tw-px-16 tw-pb-8">
        <Steps steps={steps} currentStep={currentStep} />
      </div>
      {children}
    </div>
  )
}
