import React, { useEffect } from 'react'
import SearchField from '@molecules/SearchField'
import { scopedTranslation } from '@utils/I18n'
import { useForm } from "react-hook-form"

import useSupporterFilteringStore from '../filter-supporters/stores/useSupporterFilteringStore'

const tGroupShow = scopedTranslation('group_show')

function SupporterSearchField() {
  const name = useSupporterFilteringStore((state) => state.supporterName)
  const setName = useSupporterFilteringStore((state) => state.updateName)

  const { register, handleSubmit, setValue } = useForm({ 
    defaultValues: { supporter_name: name }
  })

  // TODO: Gabriel wrote this it's terrible but wait for the default value changes and then refactor
  useEffect(() => {
    setValue('supporter_name', name)
  }, [name])

  return (
    <form onSubmit={handleSubmit(() => {})} className='tw-w-full'> 
      <SearchField
        {...register('supporter_name', {
          onChange: (e) => {
            setName(e.target.value)
          }
        })}
        fieldName="supporter_name"
        placeholder={tGroupShow('search_supporters')}
        displayName={tGroupShow('search_supporters_label')}
        dataCompId="group-detail-page_search-field"
      />
    </form>
  )
}

export default SupporterSearchField
