import gql from "graphql-tag";

export const archiveGroupMutation = gql`
  mutation archiveGroup($groupId: ID!) {
    archiveGroup(input: { groupId: $groupId }) {
      success
      errors {
        code
        message
        field
        solution
      }
    }
  }
`